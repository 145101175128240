import {
  GET_ALL_GROUPS
} from '../actions/actionTypes';

const initialState = {
  data: [],
  totalSize: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_GROUPS:
      return {...state, loading: false, data: action.payload.data, totalSize: action.payload.totalSize};
    default:
      return initialState;
  }
};