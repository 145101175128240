import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';

class Content extends Component {
  render() {
    return (
      <Fragment>
        Coming soon
      </Fragment>
    );
  }
}

export default connect(({settings}) => ({ settings }))(Content);
