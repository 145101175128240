import React from 'react';
import { DropzoneComponent as Dropzone } from 'react-dropzone-component';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import AsyncSelect from 'react-select/async';
import { connect } from 'react-redux';
import FancyBox from 'react-fancybox';
import { Modal, Input, InputGroupAddon, InputGroupText, InputGroup, Spinner } from 'reactstrap';
import axios from 'axios';
import Hashids from 'hashids';
import Breadcrumb from '../../components/common/breadcrumb.component';
import {
  getAllBrands,
  searchBrands,
} from '../../redux/actions/brand.actions';
import MediaCropper from '../../components/cropper';
import { Constants } from '../../constants';
import './style.scss';
import Button from '../../components/overrides/button';

import SweetAlert from 'react-bootstrap-sweetalert';
import NoDataIndicator from '../../components/no-data-indicator';
import TableLoader from '../../components/table-loader';
import DebounceSearchBar from '../../components/DebounceSearchBar';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withRouter } from 'react-router-dom';
import Icon from '../../components/icon';
import RowMoreOptions from '../../components/table/row-more-options';
import { v4 as uuidv4 } from 'uuid';
import { uploadToS3 } from '../../utils/imageUtils';

const hashids = new Hashids('', 12);

const ReactDOMServer = require('react-dom/server');

const env = process.env.NODE_ENV || 'development';
const config = require('../../config/config.json')[env];

const defaultSorted = [{
  dataField: 'name',
  order: 'asc',
}];

const cellEditProps = {
  mode: 'dbclick',
};

// TODO Remote Search https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Remote&selectedStory=Remote%20Search&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel

const defaults = {
  selectedId: null,
  selectedName: null,
  selectedWebsite: null,
  selectedBlog: null,
  selectedPodcast: null,
  selectedDescription: null,
  selectedFacebookId: null,
  selectedTwitterId: null,
  selectedInstagramId: null,
  selectedImageFile: null,
  brandLogo: undefined,
  brandCoverImage: undefined,
  mergeBrand: '',
  merging: false,
  cigarBrand: true,
};

const djsPreviewTemplate = ReactDOMServer.renderToStaticMarkup(
  <div className="dz-preview dz-file-preview">
    <div className="dz-details">
      {/* <div className="dz-filename"><span data-dz-name="true"></span></div> */}
      <img
        style={{
          maxWidth: 120,
          maxHeight: 120,
        }}
        data-dz-thumbnail="true"
      />
    </div>
    {/* <div className="dz-progress"><span className="dz-upload" data-dz-uploadprogress="true"></span></div> */}
    {/* <div className="dz-success-mark"><span>✔</span></div> */}
    {/* <div className="dz-error-mark"><span>✘</span></div> */}
    {/* <div className="dz-error-message"><span data-dz-errormessage="true"></span></div> */}
  </div>,
);

class BrandTable extends React.Component {
  prevSearch = this.props.history.location.search;

  constructor(props) {
    super(props);
    this.state = {
      brands: [],
      page: 1,
      sizePerPage: 10,
      // data: data.data,
      // totalSize: data.recordsFiltered,
      showBrandModal: false,
      showMergeModal: false,
      message: {
        type: 'info',
        show: false,
        title: '',
        text: '',
      },
      ...defaults,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { data } = nextProps.BrandManager;
    this.setState({ brands: data });
  }

  componentDidUpdate(prevProps) {
    const { search } = this.props.history.location;
    if (search !== this.prevSearch) {
      this.prevSearch = search;
      window.location.reload(); // TODO Just call this.props.getAllBrands(1)
    }
  }

  handleInputChange = (key) => (event) => {
    console.log(key);
    console.log(event.target.value);
    this.setState({ [key]: event.target.value });
  };

  loadBrands = (inputValue, callback) => {
    this.props.searchBrands(inputValue);
    setTimeout(() => { // FIXME How do we wait or listen for the change instead?
      callback(this.props.BrandManager.brandSearch.map((brand) => ({
        value: brand.id,
        label: brand.name,
      })));
    }, 500);
  };

  loadUsers = (inputValue, callback) => {
    // TODO Allow searching by email
    // this.props.searchUsers(inputValue);
    // setTimeout(() => { // FIXME How do we wait or listen for the change instead?
    //   callback(this.props.UserManager.userSearch.map((user) => ({
    //     value: user.id,
    //     label: user.full_name, // TODO Include their email address
    //   })));
    // }, 500);
  };

  keyForType = (type) => {
    switch (type) {
      case 'brand':
        return 'showBrandModal';
      case 'merge':
        return 'showMergeModal';
      case 'claim':
        return 'showClaimModal';
      default:
        return '';
    }
  };

  onOpenModal = (type, row) => {
    if (type === 'brand') {
      if (row) {
        console.log('Brand details:');
        console.log(JSON.stringify(row));
        this.setState({
          selectedId: row.id || null,
          selectedBrand: row,
          selectedName: row.name || '',
          selectedWebsite: row.website || null,
          selectedBlog: row.blog_url || null,
          selectedPodcast: row.podcast_url || null,
          selectedDescription: row.description || null,
          selectedFacebookId: row.facebook_id || null,
          selectedInstagramId: row.instagram_id || null,
          selectedTwitterId: row.twitter_id || null,
          brandLogo: row.logo_image_url || undefined,
          brandCoverImage: row.cover_image_url || undefined,
          cigarBrand: row.cigar_brand || undefined,
        });
      }
    } else if (type === 'merge') {
      if (row && row.id) {
        this.setState({ selectedId: row.id });
      }
    }
    this.setState({ [this.keyForType(type)]: true });
  };

  onCloseModal = (type) => {
    this.setState({
      [this.keyForType(type)]: false,
      ...defaults,
    });
  };

  onSaveModal = (type) => {
    if (type === 'brand') {
      const {
        selectedId,
        selectedName,
        selectedWebsite,
        selectedBlog,
        selectedPodcast,
        selectedDescription,
        brandLogo,
        brandCoverImage,
        selectedTwitterId,
        selectedInstagramId,
        selectedFacebookId,
        cigarBrand,
      } = this.state;
      console.log('Submitting!');
      if (brandLogo) {
        console.log(brandLogo);
      }
      const brand = {
        active: true,
        name: selectedName,
        description: selectedDescription,
        website: selectedWebsite,
        blog_url: selectedBlog,
        podcast_url: selectedPodcast,
        cigar_brand: cigarBrand,
        logo_image: brandLogo || undefined,
        cover_image: brandCoverImage || undefined,
        facebook_id: selectedFacebookId,
        instagram_id: selectedInstagramId && selectedInstagramId.replace('@', ''),
        twitter_id: selectedTwitterId && selectedTwitterId.replace('@', ''),
      };
      console.log(brand);
      if (selectedId) {
        axios.put(`${Constants.apiPath}/brands/${selectedId}`, brand).then((result) => {
          this.handleRowChange(result.data);
        }).catch((err) => {
          console.log(err);
          // ErrorLogger.captureException(err);
        });
      } else {
        axios.post(`${Constants.apiPath}/brands`, brand).then(() => {
          const {
            page,
            sizePerPage,
            searchText,
            tableChangeType,
          } = this.state;
          this.handleTableChange(tableChangeType, { page, sizePerPage, searchText });
        }).catch((err) => {
          console.log(err);
          // ErrorLogger.captureException(err);
        });
      }
      this.setState({
        [this.keyForType(type)]: false,
        ...defaults,
      });
    } else if (type === 'merge') {
      const { selectedId } = this.state;
      const mergeDetails = this.state.mergeBrand;
      console.debug(selectedId);
      console.debug(mergeDetails);
      if (mergeDetails) {
        let mergeBrand;
        for (const b of this.props.BrandManager.brandSearch) {
          if (b.id === mergeDetails.value) {
            mergeBrand = b;
            break;
          }
        }
        console.debug(mergeBrand);
        this.setState({ merging: true });
        axios.post(`${Constants.apiPath}/brands/${selectedId}/merge`, mergeBrand).then((response) => {
          this.handleRowDelete({ id: selectedId });
          this.handleRowChange(response.data);
          this.onCloseModal('merge');
          this.setState({ merging: false });
        }).catch((err) => {
          console.debug(err);
          this.setState({
            merging: false,
            message: {
              show: true,
              type: 'error',
              title: 'Uh oh!',
              text: 'Unable to merge the brand details. Please contact an admin.',
            },
          });
        });
      } else {
        this.setState({
          message: {
            show: true,
            type: 'error',
            title: 'Please select a brand to merge into',
            text: 'You must choose a brand from the dropdown to merge the currently selected brand into.',
          },
        });
      }
    }
  };

  dropzoneEventHandlers(holder) {
    const self = this;
    console.log(holder);
    return {
      addRemoveLinks: true,
      accept(file, done) {
        console.log('File:');
        console.log(file);
        console.log('uploaded');
        done();
      },
      init: (dropzone) => {
        // Dropzone.autoDiscover = false;
        console.log('Initialized dropzone');
        console.log(dropzone);
        console.log(this.state);
        const image = this.state[holder];
        if (image) {
          const imageFile = {
            name: image,
            size: 0,
          };
          dropzone.emit('addedfile', imageFile);
          dropzone.emit('thumbnail', imageFile, image);
          dropzone.emit('complete', imageFile);
          dropzone.files.push(imageFile);

          // FIXME This doesn't always hide
          // Hide the message
          setTimeout(() => {
            console.log(dropzone.element);
            const messageDiv = dropzone.element.closest('.dz-message');
            if (messageDiv) {
              messageDiv.style.display = 'none';
            } else {
              dropzone.element.children[0].style.display = 'none';
            }
          }, 500);
        }
      },
      drop: (event) => {
        console.log('Dropped event:');
        console.log(event);
        console.log(event.target);
        event.target.style.display = 'none';
        const messageDiv = this[holder].dropzone.element.closest('.dz-message');
        if (messageDiv) {
          messageDiv.style.display = 'none';
        } else {
          this[holder].dropzone.element.children[0].style.display = 'none';
        }
        this.setState({
          activeDropzone: this[holder],
          activeDropzoneId: holder,
        });
      },
      addedfile(file) {
        console.log('Dropzone files:');
        console.log(this.files);

        console.log('Added file');
        console.log(file);

        if (this.files.length > 0) {
          if (this.files[0]) {
            const { status } = JSON.parse(JSON.stringify(this.files[0]));
            console.log('Status: ', status);
            if (status !== 'queued' && status !== 'added') {
              console.log('Deleting file...');
              this.removeFile(this.files[0]);
            }
          }
        }

        if (file && this.files.length === 1) {
          console.log(file);
          console.log('Loading file...');
          const fileReader = new FileReader();
          fileReader.onload = (event) => {
            console.log('Loaded file.');
            const dataUrl = event.target.result;
            console.log(dataUrl);
            self.setState({
              selectedImageFile: dataUrl,
              showMediaCropper: true,
            });
          };
          fileReader.readAsDataURL(file);
        }
      },
      success: (file, response, error) => {
        console.log(response);
      },
    };
  }

  imageFormatter = (cell, row) => {
    if (cell) {
      return (
        <div className="circle-thumbnail">
          <FancyBox
            thumbnail={cell}
            image={cell}
          />
        </div>
      );
    }
    return null;
  };

  checkBoxFormatter = (cell, row) => (
    <div className="checkbox checkbox-secondary">
      <input name="checkbox" checked={cell} type="checkbox" />
      <label></label>
    </div>
  );

  handleChange = (key) => (value) => {
    this.setState({ [key]: value });
  };

  actionDropdown = (index, brand) => (
    <RowMoreOptions
      row={index}
      items={[{
        type: 'MenuItem',
        onClick: () => this.onOpenModal('social', brand),
        title: 'Create social media post',
      }, {
        type: 'MenuItem',
        onClick: () => this.onOpenModal('claim', brand),
        title: 'Claim profile',
      }, {
        type: 'Divider',
      }, {
        type: 'MenuItem',
        onClick: () => this.onOpenModal('merge', brand),
        title: 'Merge',
      }, {
        type: 'MenuItem',
        onClick: () => this.deleteVenue(),
        style: { color: 'red' },
        title: 'Delete',
      }]}
      onShow={() => {
        // Hide the row buttons
        document.querySelector(`#dropdown-actions-${index}`).classList.add('d-none');
      }}
    />
  );

  editBtnFormatter = (cell, row, index) => (
    <div style={{ width: 68 }}>
      <div id={`dropdown-actions-${index}`} className="d-none">
        <Icon
          name="edit2"
          className="ml-1 mr-3"
          style={{
            cursor: 'pointer',
            display: 'inline',
            height: 18,
            width: 18,
          }}
          onClick={() => this.onOpenModal('brand', row)}
        />
        {this.actionDropdown(index, row)}
      </div>
    </div>
  );

  handleRowChange = (updatedBrand) => {
    const { brands } = this.state;
    console.log(`Updating brand for ${updatedBrand.id}...`);
    console.log(updatedBrand);
    const updatedBrands = brands.map((brand) => {
      if (brand.id === updatedBrand.id) {
        return { ...brand, ...updatedBrand };
      }
      return brand;
    });
    this.setState({ brands: updatedBrands });
  };

  handleRowDelete = (deletedBrand) => {
    const { brands } = this.state;
    console.log(`Removing brand for ${deletedBrand.id}...`);
    console.log(deletedBrand);
    let i = brands.length;
    while (i--) {
      const brand = brands[i];
      if (brand.id === deletedBrand.id) {
        brands.splice(i, 1);
      }
    }
    this.setState({ brands });
  };

  handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit, searchText }) => {
    // const currentIndex = (page - 1) * sizePerPage;
    this.props.getAllBrands(page, sizePerPage, searchText && searchText.length > 0 ? searchText : null);

    this.setState({
      tableChangeType: type,
      page,
      sizePerPage,
      searchText,
    });
  };

  uploadMedia = (blob, extension, type) => {
    const uuid = uuidv4();
    const brandHash = hashids.encode(this.state.selectedId ? this.state.selectedId : new Date().getTime());
    const filename = `${brandHash}-${type}.${extension}`;
    uploadToS3(blob, `brands/${type}`, filename, uuid)
      .then((res) => {
        console.log('Uploaded to media server!');
        console.log(res.data);
        this.setState({
          uploadingMedia: false,
          [type]: res.data.media_url,
        });
      })
      .catch((err) => {
        this.setState({ uploadingMedia: false });
        // ErrorLogger.captureException(err);
        console.error(err);
      });
  };

  renderMediaCropper = () => {
    const {
      selectedImageFile,
      showMediaCropper,
      activeDropzone,
      activeDropzoneId,
    } = this.state;
    return (
      <MediaCropper
        circle={activeDropzoneId === 'brandLogo'}
        aspectRatio={activeDropzoneId === 'brandLogo' ? 1 : 1000 / 300}
        src={selectedImageFile}
        open={showMediaCropper}
        toggle={() => this.setState({ showMediaCropper: !showMediaCropper })}
        onClose={() => this.setState({ showMediaCropper: false })}
        onSave={(croppedCanvas) => {
          const base64 = croppedCanvas.toDataURL();
          this.setState({
            showMediaCropper: false,
            selectedImage: base64, // FIXME Need to know which image this is for?
          });
          croppedCanvas.toBlob((blob) => {
            this.forceUpdate();

            console.log('Canvas blob');
            console.log(blob);
            console.log(activeDropzone);
            console.log(activeDropzoneId);
            const fileReader = new FileReader();
            fileReader.onload = (event) => {
              const dataUrl = event.target.result;
              console.log(dataUrl);
              const { dropzone } = activeDropzone;
              dropzone.emit('addedfile', blob);
              dropzone.emit('thumbnail', blob, dataUrl);
              dropzone.emit('complete', blob);
              dropzone.files.push(blob);
            };
            fileReader.readAsDataURL(blob);

            this.uploadMedia(blob, base64.split(';')[0].split('/')[1], activeDropzoneId);
          }/* , 'image/png' */);
        }}
      />
    );
  };

  render() {
    const { sizePerPage, page, brands } = this.state;
    const { totalSize, loading } = this.props.BrandManager;

    const columns = [{
      dataField: 'logo_image_url',
      text: 'Image',
      formatter: this.imageFormatter,
      sort: false,
    }, {
      dataField: 'name',
      text: 'Name',
      sort: true,
    }, {
      dataField: 'description',
      text: 'Description',
      sort: false,
    }, {
      dataField: 'cigar_brand',
      text: 'Is Cigar Brand?',
      formatter: this.checkBoxFormatter,
      sort: true,
    }, {
      dataField: 'edit',
      text: '',
      formatter: this.editBtnFormatter,
      sort: false,
    }];

    const rowEvents = {
      onMouseEnter: (e, row, index) => {
        // console.log("Mouse entered: " + index);
        document.querySelector(`#dropdown-actions-${index}`).classList.remove('d-none');
      },
      onMouseLeave: (e, row, index) => {
        document.querySelector(`#dropdown-actions-${index}`).classList.add('d-none');
      },
      onDoubleClick: (e, row, index) => {
        // INFO If we don't include this event, the double click to edit doesn't work
        // console.log(e);
      },
    };

    const pageButtonRenderer = ({ page, active, onPageChange }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      let classname = 'btn btn-outline-secondary';
      if (active) {
        classname = 'btn btn-secondary';
      }
      return (
        <li className="page-item pl-1" key={page}>
          <a href="#" onClick={handleClick} className={classname}>{page}</a>
        </li>
      );
    };

    // TODO https://www.npmjs.com/package/react-device-detect change for mobile
    return (
      <div>
        <SweetAlert
          show={this.state.message.show}
          type={this.state.message.type}
          title={this.state.message.title}
          onConfirm={this.closeAlert}
        >
          {this.state.message.text}
        </SweetAlert>

        <Breadcrumb title="Brands" label="Cigars" parent="Cigars" />

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body datatable-react">
                  <ToolkitProvider
                    keyField="id"
                    data={brands}
                    columns={columns}
                    search
                  >
                    {
                      (toolkitprops) => (
                        <div>
                          <DebounceSearchBar
                            {...toolkitprops.searchProps}
                            placeholder="Search brands..."
                          />
                          {/* <ClearSearchButton { ...props.searchProps } /> */}
                          <button
                            className="btn btn-primary"
                            onClick={() => this.onOpenModal('brand')}
                            style={{ float: 'right' }}
                          >
                            {'Add Brand'}
                          </button>
                          <BootstrapTable
                            remote
                            keyField="id"
                            data={brands}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            wrapperClasses="table-responsive"
                            rowEvents={rowEvents}
                            pagination={paginationFactory({
                              pageButtonRenderer,
                              page,
                              sizePerPage,
                              totalSize,
                              showTotal: true,
                            })}
                            cellEdit={cellEditFactory(cellEditProps)}
                            onTableChange={this.handleTableChange}
                            noDataIndication={() => (!loading
                              ? <NoDataIndicator message="No brands found matching search query." />
                              : <TableLoader />)}
                            {...toolkitprops.baseProps}
                          />

                          {this.brandModal()}
                          {this.mergeModal()}
                          {this.claimModal()}
                        </div>
                      )
                    }
                  </ToolkitProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderMediaCropper()}
      </div>
    );
  }

  brandModal = () => (
    <Modal isOpen={this.state.showBrandModal} onClosed={() => this.onCloseModal('brand')}>
      <div className="modal-header">
        <h5 className="modal-title">{this.state.selectedId ? 'Edit Brand' : 'Add New Brand'}</h5>
      </div>
      <div className="modal-body">
        <div
          className="row"
          style={{
            marginBottom: 10,
            marginLeft: -20,
            marginRight: 0,
          }}
        >
          <div className="col-md-12">
            <Dropzone
              id="brandCoverImage"
              ref={(ref) => this.brandCoverImage = ref}
              config={{
                postUrl: 'no-url',
                iconFiletypes: ['.jpg', '.png'],
              }}
              djsConfig={{
                dictDefaultMessage: 'Drop cover photo here',
                previewTemplate: djsPreviewTemplate,
              }}
              eventHandlers={this.dropzoneEventHandlers('brandCoverImage')}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="dropzone">
                  <div style={{ height: '100%' }} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Drop cover photo here</p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        </div>
        <div
          className="row"
          style={{
            marginBottom: 10,
            marginLeft: -20,
            marginRight: 0,
          }}
        >
          <div className="col-md-3">
            <Dropzone
              id="brandLogo"
              ref={(ref) => this.brandLogo = ref}
              config={{
                postUrl: 'no-url',
                iconFiletypes: ['.jpg', '.png'],
              }}
              djsConfig={{
                dictDefaultMessage: 'Drop logo here',
                previewTemplate: djsPreviewTemplate,
              }}
              eventHandlers={this.dropzoneEventHandlers('brandLogo')}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="dropzone">
                  <div style={{ height: '100%' }} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Drop brand logo here</p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className="col-md-9">
            <div style={{ marginLeft: 20 }}>
              <span style={{ fontWeight: 700 }}>Name</span>
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <input
                  className="form-control"
                  type="text"
                  name="brand-name"
                  value={this.state.selectedName}
                  style={{ textTransform: 'capitalize' }}
                  onChange={this.handleInputChange('selectedName')}
                  id="select-name"
                />
              </div>
            </div>
            <div style={{ marginLeft: 20 }}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={this.state.cigarBrand}
                    onChange={(e) => {
                      console.log('Changed brand type state:');
                      console.log(e.target);
                      console.log(e.target.checked ? 'Switch is on' : 'Switch is off');
                      this.setState({ cigarBrand: e.target.checked });
                    }}
                    color="secondary"
                  />
                )}
                label="Is Cigar Brand?"
              />
            </div>
          </div>
        </div>
        <span style={{ fontWeight: 700 }}>Description</span>
        <div
          style={{
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <textarea
            className="form-control"
            name="brand-description"
            value={this.state.selectedDescription}
            onChange={this.handleInputChange('selectedDescription')}
            id="select-description"
          />
        </div>
        <div className="row" style={{ marginTop: 10 }}>
          <div className="col-md-12">
            Website
            <Input
              className="form-control"
              type="text"
              name="website"
              value={this.state.selectedWebsite}
              onChange={this.handleInputChange('selectedWebsite')}
            />
          </div>
        </div>
        <div className="row" style={{ marginTop: 10 }}>
          <div className="col-md-12">
            Blog RSS Feed
            <Input
              className="form-control"
              type="text"
              name="blogrss"
              placeholder="Ex.: https://example.com/wp-json/wp/v2/posts"
              value={this.state.selectedBlog}
              onChange={this.handleInputChange('selectedBlog')}
            />
          </div>
        </div>
        <div className="row" style={{ marginTop: 10 }}>
          <div className="col-md-12">
            Podcast RSS Feed
            <Input
              className="form-control"
              type="text"
              name="podcastrss"
              placeholder="Ex.: https://mypodcast.libsynpro.com/rss"
              value={this.state.selectedPodcast}
              onChange={this.handleInputChange('selectedPodcast')}
            />
          </div>
        </div>
        <div className="row" style={{ marginTop: 10 }}>
          <div className="col-md-4">
            Twitter
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>@</InputGroupText>
              </InputGroupAddon>
              <Input
                className="form-control"
                type="text"
                name="twitterId"
                value={this.state.selectedTwitterId}
                placeholder="<username>"
                onChange={this.handleInputChange('selectedTwitterId')}
              />
            </InputGroup>
          </div>
          <div className="col-md-4">
            Facebook
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>fb.com/</InputGroupText>
              </InputGroupAddon>
              <Input
                className="form-control"
                type="text"
                name="facebookId"
                value={this.state.selectedFacebookId}
                placeholder="<username>"
                onChange={this.handleInputChange('selectedFacebookId')}
              />
            </InputGroup>
          </div>
          <div className="col-md-4">
            Instagram
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>@</InputGroupText>
              </InputGroupAddon>
              <Input
                className="form-control"
                type="text"
                name="instagramId"
                value={this.state.selectedInstagramId}
                placeholder="<username>"
                onChange={this.handleInputChange('selectedInstagramId')}
              />
            </InputGroup>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        {this.state.selectedId && (
          <button
            id="merge"
            style={{
              margin: 5,
              position: 'absolute',
              left: 20,
            }}
            type="button"
            className="btn btn-raised btn-default btn-sm"
            onClick={() => this.onOpenModal('merge')}
          >
            {'Merge Into Another Brand'}
          </button>
        )}
        <Button onClick={() => this.onCloseModal('brand')}>Cancel</Button>
        <Button onClick={() => this.onSaveModal('brand')} variant="contained" color="secondary">Save</Button>
      </div>
    </Modal>
  );

  mergeModal = () => (
    <Modal isOpen={this.state.showMergeModal} onClosed={() => this.onCloseModal('merge')}>
      <div className="modal-header">
        <h5 className="modal-title">Merge Brand</h5>
      </div>
      <div className="modal-body">
        {'Select brand to merge:'}
        {/* TODO Just AsyncSelect - no create */}
        <AsyncSelect
          value={this.state.mergeBrand}
          onChange={this.handleChange('mergeBrand')}
          getOptionLabel={(option) => `${option.label}`}
          getOptionValue={(option) => `${option}`}
          isOptionSelected={(option) => this.state.mergeBrand.value === option.value}
          loadOptions={this.loadBrands}
        />
        {/* TODO Force switch? */}
      </div>
      <div className="modal-footer">
        <Button onClick={() => this.onCloseModal('merge')}>Cancel</Button>
        <Button
          onClick={() => this.onSaveModal('merge')}
          variant="contained"
          color="secondary"
          disabled={this.state.merging}
        >
          {this.state.merging && <Spinner color="light" style={{ marginRight: 8 }} />}
          {this.state.merging ? 'Merging...' : 'Merge'}
        </Button>
      </div>
    </Modal>
  );

  claimModal = () => (
    <Modal isOpen={this.state.showClaimModal} onClosed={() => this.onCloseModal('claim')}>
      <div className="modal-header">
        <h5 className="modal-title">Claim Brand</h5>
      </div>
      <div className="modal-body">
        <p>User</p>
        <AsyncSelect
          value={this.state.claimUser}
          onChange={this.handleChange('claimUser')}
          getOptionLabel={(option) => `${option.label}`}
          getOptionValue={(option) => `${option}`}
          // isOptionSelected={(option) => this.state.claimUser.value === option.value}
          loadOptions={this.loadUsers}
        />
      </div>
      <div className="modal-footer">
        <Button onClick={() => this.onCloseModal('claim')}>Cancel</Button>
        <Button
          onClick={() => this.onSaveModal('claim')}
          variant="contained"
          color="secondary"
          disabled={this.state.merging}
        >
          {this.state.merging && <Spinner color="light" style={{ marginRight: 8 }} />}
          {this.state.merging ? 'Claiming...' : 'Claim'}
        </Button>
      </div>
    </Modal>
  );
}

const mapStateToProps = ({ BrandManager }) => ({ BrandManager });

export default withRouter(connect(
  mapStateToProps, {
    getAllBrands,
    searchBrands,
  },
)(BrandTable));
