import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserStore } from '../../stores';

function PrivateRoute({ component: Component, ...rest }) {
  const authenticated = UserStore.useState((s) => s.authenticated);
  return (
    <Route
      {...rest}
      render={(props) => (authenticated === true
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />)}
    />
  );
}

export default PrivateRoute;
