import React, { Component } from 'react';
import axios from 'axios';
import Breadcrumb from '../../components/common/breadcrumb.component';
import { Constants } from '../../constants';

// INFO We can pull dashboards from Amplitude to simplify things - https://developers.amplitude.com/docs/dashboard-rest-api
class Default extends Component {
  constructor(props) {
    super(props);

    axios.get(`${Constants.apiPath}/cigars/dashboard`).then((response) => {
      const { data } = response;
      console.log(data);
      this.setState({
        topRatedCigars: data.top_rated_cigars,
        mostSmokedCigars: data.most_smoked_cigars,
        mostSearchedCigars: data.most_searched_cigars,
        mostMatchedCigars: data.most_matched_cigars,
        mostViewedCigars: data.most_viewed_cigars,
      });
    });

    this.state = {
      showSocialModal: false,
      topRatedCigars: [],
      mostSmokedCigars: [],
      mostSearchedCigars: [],
      mostMatchedCigars: [],
      mostViewedCigars: [],
    };
  }

  keyForType = (type) => {
    switch (type) {
      case 'social':
        return 'showSocialModal';
      default:
        return '';
    }
  };

  onOpenModal = (type) => {
    console.log('Showing modal');
    let newType = '';
    if (type === 'facebook' || type === 'instagram' || type === 'twitter') {
      newType = 'social';
    }
    this.setState({ [this.keyForType(newType)]: true });
  };

  onCloseModal = (type) => {
    this.setState({
      [this.keyForType(type)]: false,
      // ...defaults
    });
  };

  onSaveModal = (type) => {
    this.setState({
      [this.keyForType(type)]: false,
      // ...defaults
    });
  };

  render() {
    const { topRatedCigars, mostSmokedCigars, mostSearchedCigars, mostMatchedCigars, mostViewedCigars } = this.state;

    // const data = {
    //     totalOrders: totalOrders.map((order) => order.count),
    //     abandonedCarts: abandonedCarts.map((cart) => cart.count),
    //     totalVisitors: totalVisitors.map((user) => user.count),
    //     totalSales: totalSales.map((order) => order.sum),
    // };

    return (
      <div>
        {/* Container-fluid starts */}
        <Breadcrumb title="Cigars" parent="Dashboard" />
        {/* Container-fluid Ends */}

        {/* Container-fluid starts */}
        <div className="container-fluid">
          {/* May want to visualize the funnels - https://github.com/greghub/funnel-graph-js */}
          {/*<BoxRowOne />*/}
          <div className="card height-equal equal-height-lg">
            <div className="card-header">
              <h5>Usage By Feature</h5>
              {/* TODO Include toggle or dropdown to allow data to be displayed for user's first launch vs all time (possibly just both in one, stacked graph) */}
            </div>
            <div className="card-body" style={{ padding: '12px 0px' }}>
              <div style={{ padding: 12 }}>
                {/* TODO Bar graph showing actions taken by feature (add to humidor, smoke now, view venue / checkin, etc) */}
                {/* TODO May also want a pie graph to the right of the bar graph */}
                <div style={{ textAlign: 'center' }}>Coming soon. Displays the number of times the main features are used (add to humidor, smoke now, view venue / checkin, etc - first launch vs all time)</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="card height-equal equal-height-lg">
                <div className="card-header">
                  <h5>Top Rated</h5>
                </div>
                <div className="card-body" style={{ padding: '12px 0px' }}>
                  <div style={{ height: 486, overflow: 'auto' }}>
                    <table className="table table-bordernone">
                      <thead>
                        <tr>
                          <th scope="col" className="pt-0" style={{ borderTop: 'none' }}>Name</th>
                          <th scope="col" className="pt-0" style={{ borderTop: 'none' }}>Rating</th>
                        </tr>
                      </thead>
                      <tbody>
                        {topRatedCigars.map((cigar) => (
                          <tr>
                            <td>{cigar.full_name}</td>
                            <td className="digits">{cigar.avg_rating}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {topRatedCigars.length === 0 && <div style={{ textAlign: 'center', height: 450, paddingTop: 200 }}>No data available</div>}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="card height-equal equal-height-lg">
                <div className="card-header">
                  <h5>Most Smoked</h5>
                </div>
                <div className="card-body" style={{ padding: '12px 0px' }}>
                  <div style={{ height: 486, overflow: 'auto' }}>
                    <table className="table table-bordernone">
                      <thead>
                        <tr>
                          <th scope="col" className="pt-0" style={{ borderTop: 'none' }}>Name</th>
                          <th scope="col" className="pt-0" style={{ borderTop: 'none' }}>Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mostSmokedCigars.map((cigar) => (
                          <tr>
                            <td>{cigar.full_name}</td>
                            <td className="digits">{cigar.count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {mostSmokedCigars.length === 0 && <div style={{ textAlign: 'center', height: 450, paddingTop: 200 }}>No data available</div>}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="card height-equal equal-height-lg">
                <div className="card-header">
                  <h5>Top Searches</h5>
                </div>
                <div className="card-body" style={{ padding: '12px 0px' }}>
                  <div style={{ height: 486, overflow: 'auto' }}>
                    <table className="table table-bordernone">
                      <thead>
                        <tr>
                          <th scope="col" className="pt-0" style={{ borderTop: 'none' }}>Name</th>
                          <th scope="col" className="pt-0" style={{ borderTop: 'none' }}>Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mostSearchedCigars.map((cigar) => (
                          <tr>
                            <td>{cigar.full_name}</td>
                            <td className="digits">{cigar.count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {mostSearchedCigars.length === 0 && <div style={{ textAlign: 'center', height: 450, paddingTop: 200 }}>No data available</div>}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="card height-equal equal-height-lg">
                <div className="card-header">
                  <h5>Most Matched (Band Scans)</h5>
                </div>
                <div className="card-body" style={{ padding: '12px 0px' }}>
                  <div style={{ height: 486, overflow: 'auto' }}>
                    <table className="table table-bordernone">
                      <thead>
                        <tr>
                          <th scope="col" className="pt-0" style={{ borderTop: 'none' }}>Name</th>
                          <th scope="col" className="pt-0" style={{ borderTop: 'none' }}>Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mostMatchedCigars.map((cigar) => (
                          <tr>
                            <td>{cigar.full_name}</td>
                            <td className="digits">{cigar.count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {mostMatchedCigars.length === 0 && <div style={{ textAlign: 'center', height: 450, paddingTop: 200 }}>No data available</div>}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="card height-equal equal-height-lg">
                <div className="card-header">
                  <h5>Most Viewed</h5>
                </div>
                <div className="card-body" style={{ padding: '12px 0px' }}>
                  <div style={{ height: 486, overflow: 'auto' }}>
                    <table className="table table-bordernone">
                      <thead>
                        <tr>
                          <th scope="col" className="pt-0" style={{ borderTop: 'none' }}>Name</th>
                          <th scope="col" className="pt-0" style={{ borderTop: 'none' }}>Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mostViewedCigars.map((cigar) => (
                          <tr>
                            <td>{cigar.full_name}</td>
                            <td className="digits">{cigar.count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {mostViewedCigars.length === 0 && <div style={{ textAlign: 'center', height: 450, paddingTop: 200 }}>No data available</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Container-fluid Ends */}
      </div>
    );
  }
}

export default Default;
