import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
// import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import { Collapse, UncontrolledTooltip } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import AsyncSelect from 'react-select/async-creatable';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

import '../../assets/css/linked-cigars.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import Icon from '../../components/icon';
import RowMoreOptions from '../../components/table/row-more-options';
import {
  getAllAbandonedCarts,
} from '../../redux/actions/shop.actions';
import Breadcrumb from '../../components/common/breadcrumb.component';
import NoDataIndicator from '../../components/no-data-indicator';
import TableLoader from '../../components/table-loader';

const { SearchBar } = Search;

const defaultSorted = [{
  dataField: 'name',
  order: 'asc',
}];

const cellEditProps = {
  mode: 'dbclick',
};

const defaults = {
  editorState: EditorState.createEmpty(),
  selectedId: null,
  selectedUrl: null,
  selectedProduct: {},
  selectedCategories: null,
  selectedPackageType: null,
  selectedPackageQuantity: 1,
  message: {
    type: 'info',
    show: false,
    title: '',
    text: '',
  },
};

const NoDataIndication = () => (
  <div className="table-loader">
    <div className="loader">
      <div className="line bg-primary"></div>
      <div className="line bg-primary"></div>
      <div className="line bg-primary"></div>
      <div className="line bg-primary"></div>
      <div className="line bg-primary"></div>
    </div>
  </div>
);

class ShopTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      // data: data.data,
      // totalSize: data.recordsFiltered,
      sizePerPage: 10,
      showProductModal: false,
      showImportModal: false,
      showLinkCigarModal: false,
      showFilterDrawer: false,
      selectedStatusFilter: {},
      showDateFilter: true,
      linkedCigars: [],
      vitolaOptions: [],
      showAvailability: 'all',
      showMissingLinkedCigars: false, // These 3 options are set to ONLY show the missing options. Setting to false shows all
      showMissingImages: false,
      showMissingPrice: false,
      selectedShop: null,
      ...defaults,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  componentDidMount() {
    this.handleTableChange(null, { page: 1, sizePerPage: 30 });
  }

  toggleTooltip(key) {
    return () => {
      this.setState({
        [key]: !this.state[key],
      });
    };
  }

  dropzoneEventHandlers(holder) {
    const self = this;

    return {
      init(dropzone) {
        // Dropzone.autoDiscover = false;
        console.log('Initialized dropzone');
        console.log(dropzone);
        console.log(self.state);
        const image = self.state[holder];
        if (image) {
          const imageFile = { name: image, size: 0 };
          dropzone.emit('addedfile', imageFile);
          dropzone.emit('thumbnail', imageFile, image);
          dropzone.emit('complete', imageFile);
          dropzone.files.push(imageFile);

          // Hide the message
          dropzone.element.children[0].style.display = 'none';
        }
      },
      drop() {

      },
      // removedfile: self.handleRemoveUpload,
      // error: self.handleErrorUpload,
      // success: self.handleSuccessUpload
    };
  }

    imageFormatter = (cell, row, index) => {
      // console.log("Image:");
      // console.log(cell);
      if (cell) {
        if (cell.indexOf('http') === -1) {
          cell = `https://${cell}`;
        }
        return (
          <img
            style={{ maxWidth: 75 }}
            src={cell}
            onClick={() => {
            // console.log(cigar.images);
            // this.setState({photoImages: cigar.images, photoIndex: 0, openLightbox: true}) // TODO How to get correct image position? See above
            }}
          />
        );
      }
      return (<div />);
    };

    handleChange = (key) => (value) => {
      this.setState({ [key]: value });
    };

    handleProductChange = (key) => (event) => {
      const product = this.state.selectedProduct;
      product[key] = event.target.value;
      this.setState({ selectedProduct: product });
    };

    handleEditorStateChange = (editorState) => {
      this.setState({
        editorState,
      });
    };

    handleInputChange = (key) => (event) => {
      console.log(key);
      console.log(event.target.value);
      this.setState({ [key]: event.target.value });
    };

    handleSelectChange = (key) => (value) => {
      this.setState({ [key]: value });
    };

    handleCheckboxChange = (key) => {
      console.log('Check change');
      return (event) => {
        this.setState({ [key]: event.target.checked });
      };
    };

    handleRadioChange = (key) => (event) => {
      this.setState({ [key]: event.target.value });
    };

    handleLinkedCigarChange = (index, type) => {
      const { linkedCigars } = this.state;
      console.log(linkedCigars);
      const linkedCigar = linkedCigars[index] || {};
      return (value) => {
        linkedCigar[type] = value;
        linkedCigars[index] = linkedCigar;
        this.setState({ linkedCigars }, () => {
          console.log('State updated!');
          if (type === 'cigar') {
            console.log('Running this.loadVitolas(index)...');
            this.loadVitolas(index);
          }
        });
      };
    };

    keyForType = (type) => {
      switch (type) {
        case 'product':
          return 'showProductModal';
        case 'import':
          return 'showImportModal';
        case 'cigar':
          return 'showLinkCigarModal';
        case 'filter':
          return 'showFilterDrawer';
        default:
          return '';
      }
    };

    onCloseModal = (type) => {
      this.setState({
        [this.keyForType(type)]: false,
      });
    };

    onOpenModal = (type, row) => {
      this.setState({ [this.keyForType(type)]: true });
    };

    itemFormatter = (items, row) => {
      if (items) {
        return (
          <label>
            {items.map((item) => <p>{`${item.quantity}x ${item.product ? item.product.name : 'Unknown Product'}`}</p>)}
          </label>
        );
      }
      return null;
    };

    itemQuantityFormatter = (items, row) => {
      if (items) {
        return (
          <label>{items.length}</label>
        );
      }
      return null;
    };

    priceFormatter = (cell, row) => {
      if (cell) {
        let price = cell;
        if (typeof price === 'number' || (typeof price === 'string' && price.indexOf('$') === -1)) {
          // FIXME This should be fixed on the server, but since the dev db and prod db aren't configured the same, we need this. Once configured correctly, remove this
          price = `$${parseFloat(cell).toFixed(2)}`;
        }
        return (
          <label>{price}</label>
        );
      }
      if (row.items && row.items.length) {
        let price = 0;
        for (let i = 0; i < row.items.length; i++) {
          console.log(row.items[i].price);
          price += parseFloat(row.items[i].price);
        }
        console.log(price);
        if (price) {
          price = `$${price.toFixed(2)}`;
        }
        return <label>{price}</label>;
      }
      return null;
    };

    checkBoxFormatter = (cell, row) => (
      <div className="checkbox checkbox-secondary">
        <input name="checkbox" checked={cell} type="checkbox" />
        <label></label>
      </div>
    );

    statusFormatter = (cell, row) => {
      console.log(cell);
      if (cell && cell === 'ok') {
        return (
          <div style={{ marginLeft: 15 }}>
            <i className="fa fa-circle text-success" />
          </div>
        );
      } if (cell && cell === 'warn') {
        return (
          <div style={{ marginLeft: 15 }}>
            <i className="fa fa-exclamation-circle text-warning" id={`warning-${row.id}`} />
            <UncontrolledTooltip placement="top" target={`warning-${row.id}`}>
              <ul>
                { row.status_message.map((message) => (
                  <li>
-
                    {message}
                  </li>
                )) }
              </ul>
            </UncontrolledTooltip>
          </div>
        );
      } if (cell && cell === 'unlinked') {
        return (
          <div style={{ marginLeft: 15 }}>
            <i className="fa fa-unlink text-danger" id={`unlinked-${row.id}`} />
            <UncontrolledTooltip placement="top" target={`unlinked-${row.id}`}>
              <ul>
                { row.status_message.map((message) => (
                  <li>
-
                    {message}
                  </li>
                )) }
              </ul>
            </UncontrolledTooltip>
          </div>
        );
      } if (cell && cell === 'unlinked-warn') {
        return (
          <div style={{ marginLeft: 15 }}>
            <i className="fa fa-unlink text-warning" id={`unlinked-${row.id}`} />
            <UncontrolledTooltip placement="top" target={`unlinked-${row.id}`}>
              <ul>
                { row.status_message.map((message) => (
                  <li>
-
                    {message}
                  </li>
                )) }
              </ul>
            </UncontrolledTooltip>
          </div>
        );
      } if (cell && cell === 'missing-attribute') {
        return (
          <div style={{ marginLeft: 15 }}>
            <i className="fa fa-cart-plus text-danger" id={`missing-attribute-${row.id}`} />
            <UncontrolledTooltip placement="top" target={`missing-attribute-${row.id}`}>
              <ul>
                {
                  row.status_message.map((message) => (
                    <li>
-
                      {message}
                    </li>
                  ))
                }
              </ul>
            </UncontrolledTooltip>
          </div>
        );
      }
      return (
        <div style={{ marginLeft: 15 }}>
          <i className="fa fa-exclamation-triangle text-danger" id={`error-${row.id}`} />
          <UncontrolledTooltip placement="top" target={`error-${row.id}`}>
            <ul>
              { row.status_message.map((message) => (
                <li>
-
                  {message}
                </li>
              )) }
            </ul>
          </UncontrolledTooltip>
        </div>
      );
    };

    actionDropdown = (index, product) => (
      <RowMoreOptions
        style={{ float: 'right' }}
        row={index}
        items={[{
          type: 'MenuItem',
          onClick: () => {
            alert('Coming soon. Currently, abandoned cart emails are sent automatically.');
          },
          title: 'Send email to customer',
        }]}
        onShow={() => {
        // Hide the row buttons
          document.querySelector(`#dropdown-actions-${index}`).classList.add('d-none');
        }}
      />
    );

    editBtnFormatter = (cell, row, index) => {
      console.log(`Index rendered: ${index}`);
      return (
        <div style={{ width: 50 }}>
          <div id={`dropdown-actions-${index}`} className="d-none">
            {/* <i className='icon-pencil ml-1 mr-3' style={{cursor: 'pointer'}} onClick={() => {}}/> */}
            {this.actionDropdown(index, row)}
          </div>
        </div>
      );
    };

    handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit, searchText }) => {
      console.log('Updating table...');
      this.props.getAllAbandonedCarts(page, sizePerPage, {
        // TODO Filter options
      });

      this.setState({
        page,
        sizePerPage,
      });
    };

    closeAlert = () => {
      this.setState({
        message: {
          show: false,
          title: '',
          text: '',
        },
      });
    };

    renderFilterOptions = () => (
      <SwipeableDrawer
        anchor="right"
        open={this.state.showFilterDrawer}
        // onClick={() => this.setState({ showFilterDrawer: false })}
        onKeyDown={() => this.setState({ showFilterDrawer: false })}
        onClose={() => this.setState({ showFilterDrawer: false })}
      >
        {/* TODO Add app bar on mobile with back arrow */}
        <div style={{ width: isMobile ? 'calc(100vw - 40px)' : 300, marginLeft: 20, marginRight: 20, marginBottom: 30 }}>
          {/* TODO Status? Actually abandoned vs other statuses? */}
          <div
            style={{ display: 'flex', marginTop: 35, cursor: 'pointer', borderBottom: '1px solid #efefef' }}
            onClick={() => this.setState({ showDateFilter: !this.state.showDateFilter })}
          >
            <span style={{ flex: 1 }}>Date</span>
            <Icon name="chevron-down" />
          </div>
          <Collapse isOpen={this.state.showDateFilter}>
            <RadioGroup aria-label="status" name="status" value={this.state.selectedDateFilter} onChange={(event) => this.setState({ selectedDateFilter: event.target.value })}>
              <FormControlLabel value="Processing" control={<Radio />} label="Today" />
              <FormControlLabel value="Processed" control={<Radio />} label="Yesterday" />
              <FormControlLabel value="Shipped" control={<Radio />} label="7 Days" />
              <FormControlLabel value="Delivered" control={<Radio />} label="30 Days" />
              <FormControlLabel value="Cancelled" control={<Radio />} label="90 Days" />
            </RadioGroup>
          </Collapse>

          <div
            style={{
              display: 'flex',
              position: 'fixed',
              bottom: 0,
              backgroundColor: 'white',
              width: 300,
              paddingTop: 10,
              paddingBottom: 10,
              margin: 'auto',
            }}
          >
            <Button
              onClick={() => {
                this.setState({
                  showFilterDrawer: false,
                });
                // this.resetFilters();
              }}
              style={{ display: 'block', flex: 1, marginRight: 10, textAlign: 'center' }}
            >
              {'Reset'}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              style={{ display: 'block', flex: 1, textAlign: 'center' }}
              onClick={() => {
                this.setState({
                  showFilterDrawer: false,
                });
                // this.filterProducts(1);
              }}
            >
              {'Apply'}
            </Button>
          </div>
        </div>
      </SwipeableDrawer>
    );

    render() {
      const { sizePerPage, page } = this.state;
      const { carts, totalCartSize, loadingCarts } = this.props.ShopManager;

      console.log('Abandoned carts:');
      console.log(carts);

      const columns = [{
        dataField: 'user.email',
        text: 'Email',
        sort: true,
        editable: false,
      }, {
        dataField: 'update_timestamp',
        text: 'Date',
        sort: false,
        editable: false,
      // }, {
      //   dataField: 'status',
      //   text: 'Status',
      //   sort: true,
      //   // filter: textFilter()
      }, {
        dataField: 'items',
        text: 'Total Items',
        formatter: this.itemQuantityFormatter,
        sort: true,
        // filter: textFilter()
      }, {
        dataField: 'items',
        text: 'Products',
        formatter: this.itemFormatter,
        sort: true,
        // filter: textFilter()
      }, {
        dataField: 'total_price',
        text: 'Total Price',
        formatter: this.priceFormatter,
        sort: true,
      }, {
        dataField: 'edit',
        text: '',
        formatter: this.editBtnFormatter,
        sort: true,
      }];

      const rowEvents = {
        onMouseEnter: (e, row, index) => {
          // console.log("Mouse entered: " + index);
          document.querySelector(`#dropdown-actions-${index}`).classList.remove('d-none');
        },
        onMouseLeave: (e, row, index) => {
          document.querySelector(`#dropdown-actions-${index}`).classList.add('d-none');
        },
        onDoubleClick: (e, row, index) => {
          // INFO If we don't include this event, the double click to edit doesn't work
          // console.log(e);
        },
      };

      const pageButtonRenderer = ({ page, active, onPageChange }) => {
        const handleClick = (e) => {
          e.preventDefault();
          onPageChange(page);
        };
        let classname = 'btn btn-outline-secondary';
        if (active) {
          classname = 'btn btn-secondary';
        }
        return (
          <li className="page-item pl-1" key={page}>
            <a href="#" onClick={handleClick} className={classname}>{ page }</a>
          </li>
        );
      };

      // TODO https://www.npmjs.com/package/react-device-detect use a different layout for mobile
      return (
        <div>
          <SweetAlert
            show={this.state.message.show}
            type={this.state.message.type}
            title={this.state.message.title}
            onConfirm={this.closeAlert}
          >
            {this.state.message.text}
          </SweetAlert>

          <Breadcrumb title="Abandoned Carts" label="Abandoned Carts" parent="Shop" />

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card mb-0">
                  <div className="card-body datatable-react">
                    <ToolkitProvider
                      keyField="id"
                      data={carts}
                      columns={columns}
                      search
                    >
                      {
                        (toolkitprops) => (
                          <div>
                            {/* Known issue https://github.com/react-bootstrap-table/react-bootstrap-table2/issues/787 */}
                            <SearchBar {...toolkitprops.searchProps} delay={800} />
                            {/* <ClearSearchButton { ...props.searchProps } /> */}
                            <button className="btn btn-outline-primary mr-2" onClick={() => this.onOpenModal('filter')} style={isMobile ? { padding: '6px 14px', float: 'right' } : { float: 'right' }}>
                              {isMobile ? (<i className="icon icon-filter" />) : 'Filter'}
                            </button>
                            <BootstrapTable
                              remote
                              keyField="id"
                              data={carts}
                              columns={columns}
                              rowEvents={rowEvents}
                              defaultSorted={defaultSorted}
                              wrapperClasses="table-responsive"
                              pagination={paginationFactory({ pageButtonRenderer, page, sizePerPage, totalCartSize })}
                              cellEdit={cellEditFactory(cellEditProps)}
                              // filter={ filterFactory() }
                              onTableChange={this.handleTableChange}
                              noDataIndication={() => (!loadingCarts ? <NoDataIndicator message={'Either you don\'t have any abandoned carts yet or none of them match the selected filters.'} /> : <TableLoader />)}
                              {...toolkitprops.baseProps}
                            />

                            { this.renderFilterOptions() }
                          </div>
                        )
                      }
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
}

const mapStateToProps = ({ ShopManager }) => ({ ShopManager });

export default connect(
  mapStateToProps, {
    getAllAbandonedCarts,
  }
)(ShopTable);
