import React from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import '@material/react-chips/dist/chips.css';
import Button from '../../overrides/button';
import './style.scss';
import { Modal } from 'reactstrap';

class EventTicketEditor extends React.Component {
  state = {
    alias: '',
    shape: null,
    length: '',
    gauge: '',
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Modal isOpen={this.props.open} onClosed={this.props.onClose}>
        <div className="modal-header">
          <h5 className="modal-title">Add Ticket / Admission Option</h5>
        </div>
        <div className="modal-body md">
          <div>
            <div style={{ paddingTop: 10 }}>
              <span>Ticket Name</span>
              <input
                name="alias"
                className="form-control"
                type="text"
                value={this.state.alias}
                style={{ textTransform: 'capitalize' }}
                placeholder="Example: General Admission"
              />
            </div>
            <div style={{ paddingTop: 10 }}>
              <span>Price</span>
              <AsyncCreatableSelect
                value={this.state.shape}
                placeholder="Search to select"
                onChange={(value) => this.setState({ shape: value })}
                getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option}`}
                isOptionSelected={(option) => this.state.shape && this.state.shape.value === option.value}
                loadOptions={this.loadShapes}
                // defaultOptions={defaultVitolas}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button onClick={this.props.onClose}>Cancel</Button>
          <Button
            onClick={() => {
              // if (!this.state.shape) {
              //   alert('Shape is required');
              //   return;
              // }
              // if (this.state.gauge.length === 0 || this.state.length.length === 0) {
              //   alert('Size is required');
              //   return;
              // }
              // if (typeof this.props.onSave === 'function') {
              //   const vitola = {
              //     alias: this.state.alias,
              //     shape: this.state.shape.value,
              //     size: `${parseFloat(this.state.length).toFixed(1)}"x${this.state.gauge}`,
              //   };
              //   this.props.onSave({
              //     ...vitola,
              //     formatted_name: formattedVitolaName(vitola),
              //     select_shape: this.state.shape,
              //   });
              //   this.setState({
              //     alias: '',
              //     shape: null,
              //     length: '',
              //     gauge: '',
              //   });
              // }
            }}
            variant="contained"
            color="secondary"
          >
            {'Set'}
          </Button>
        </div>
      </Modal>
    );
  }
}

export default EventTicketEditor;
