// FIXME This can probably go in /config

let apiPath = 'https://dev-admin-api.boxpressd.io';
let clientPath = 'https://dev.boxpressd.io';
let shopPath = 'https://dev-shop-api.boxpressd.io';

if (window.location.hostname === 'admin.sigaro.io' || window.location.hostname === 'admin.boxpressd.io') {
  apiPath = 'https://admin-api.boxpressd.io';
  clientPath = 'https://api.boxpressd.io';
  shopPath = 'https://shop-api.boxpressd.io';
}

if (window.location.hostname === 'staging-admin.boxpressd.io') {
  apiPath = 'https://staging-admin-api.boxpressd.io';
  clientPath = 'https://api.boxpressd.io';
  // shopPath = 'https://staging-shop-api.boxpressd.io';
  shopPath = 'https://shop-api.boxpressd.io';
}

export const Constants = {
  apiPath,
  clientPath,
  shopPath,
  googleMapsKey: 'AIzaSyBMloRcukeyA4eNwgwqbZxGCcwPNlC1qvc',
};
