import React from 'react';
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';

const cardNumberStyles = {
  fieldWrapper: {
    base: { width: '100%', marginBottom: 10 },
    // errored: css | Object
  },
  inputWrapper: {
    base: {
      minHeight: 36,
      padding: '2em .6em',
      backgroundColor: '#fbfcfc',
      borderColor: '#e6ecf0',
      boxShadow: 'none',
    },
    // errored: css | Object,
    // focused: css | Object
  },
  input: {
    base: {
      backgroundColor: '#fbfcfc',
    },
    //   errored: css | Object,
    //   cardNumber: css | Object,
    //   expiryDate: css | Object,
    //   cvc: css | Object
  },
  // errorText: {
  //   base: css | Object
  // }
};

export default function PaymentInputs(props) {
  const { meta, wrapperProps, getCardImageProps, getCardNumberProps, getExpiryDateProps, getCVCProps } = usePaymentInputs();
  // { cardNumber, expiry, cvc}
  const handleChange = (values) => {
    if (typeof props.onChange === 'function') {
      props.onChange(values);
    }
  };
  return (
    <PaymentInputsWrapper {...wrapperProps} styles={cardNumberStyles}>
      <svg {...getCardImageProps({ images })} />
      <input {...getCardNumberProps({ onChange: handleChange })} value={props.cardNumber} />
      <input {...getExpiryDateProps({ onChange: handleChange })} value={props.expiryDate} />
      <input {...getCVCProps({ onChange: handleChange })} value={props.cvc} />
      {/*{meta.isTouched && meta.error && <span>Error: {meta.error}</span>}*/}
    </PaymentInputsWrapper>
  );
}
