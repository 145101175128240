import firebase from 'firebase';

const dev = !(window.location.hostname === 'admin.sigaro.io' || window.location.hostname === 'admin.boxpressd.io' || window.location.hostname === 'staging-admin.boxpressd.io');

let config = {
  apiKey: 'AIzaSyBpB1qWK6sRU__QUXg6b-04nSrCZTH_8JY',
  authDomain: 'sigaro-devmin.firebaseapp.com',
  databaseURL: 'https://sigaro-devmin.firebaseio.com',
  projectId: 'sigaro-devmin',
  storageBucket: '',
  messagingSenderId: '605944655435',
  appId: '1:605944655435:web:88514e110d243bc6',
};

if (dev) {
  console.log('Using dev settings');
} else {
  console.log('Using prod settings');
  config = {
    apiKey: 'AIzaSyADNzIv2IxYHe11qew3WzPw7e1egE3Ihd4',
    authDomain: 'sigaro-admin.firebaseapp.com',
    databaseURL: 'https://sigaro-admin.firebaseio.com',
    projectId: 'sigaro-admin',
    storageBucket: 'sigaro-admin.appspot.com',
    messagingSenderId: '915435073658',
    appId: '1:915435073658:web:bb2f24b6ee6a7531',
  };
}

const firebaseApp = firebase.initializeApp(config);

export default firebaseApp;
