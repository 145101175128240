import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GoogleApiWrapper } from 'google-maps-react';

import GoogleMap from './google-map';
import { Constants } from '../../../constants';

class ClusterMap extends Component {
  render() {
    // alert(`Adding ${this.props.locations.length} locations`);
    return (
      <GoogleMap
        google={this.props.google}
        {...this.props}
      />
    );
  }
}

ClusterMap.propTypes = {
  google: PropTypes.any,
  zoom: PropTypes.number,
  center: PropTypes.object,
  locations: PropTypes.array,
  loading: PropTypes.any,
};
ClusterMap.defaultProps = {
  google: null,
  zoom: 10,
  center: { lat: 39.8097343, lng: -98.5556199 },
  locations: [],
  loading: {},
};

export default GoogleApiWrapper((props) => ({
  apiKey: Constants.googleMapsKey,
}
))(ClusterMap);
