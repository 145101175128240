import './styles.scss';

import React, { useEffect, useState } from 'react';
import { Card, CardHeader, Modal, Table } from 'reactstrap';
import axios from 'axios';
import { DropzoneComponent as Dropzone } from 'react-dropzone-component';
import Lightbox from 'react-image-lightbox';
import Breadcrumb from '../../components/common/breadcrumb.component';
import { Constants } from '../../constants';
import Icon from '../../components/icon';
import Button from '../../components/overrides/button';
import ModalDialog from '../../components/ModalDialog';
import Pagination from '../../components/Pagination/pagination';
import TouchSpin from '../../components/touch-spin';

let nextMarker = null;

const PAGE_SIZE = 30;

const markers = localStorage.getItem('rekognition-s3-markers') ? JSON.parse(localStorage.getItem('rekognition-s3-markers')) : {};

function CigarBandRecognition() {
  const [cigars, setCigars] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedType, setSelectedType] = useState('training');
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedCigarId, setSelectedCigarId] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [imageCount, setImageCount] = useState(0);

  const [pageToSet, setPageToSet] = useState(null);
  const [showPagePickerModal, setShowPagePickerModal] = useState(false);
  const [urlToImport, setUrlToImport] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showLightbox, setShowLightbox] = useState(false);

  const getRecords = () => {
    axios.get(`${Constants.apiPath}/actions/cigars/images/objects`, {
      params: {
        next_marker: markers[currentPage],
      },
    }).then((res) => {
      console.log('Got updated cigar data!');
      console.log(res.data);
      if (res.data && res.data.folders && res.data.folders) {
        console.log('Re-rendering cigar data');
        setCigars(res.data.folders.map((folder, index) => ({
          id: folder,
          targets: {
            training: res.data.training_data[index] || [],
            tests: res.data.test_data[index] || [],
          },
        })));
        if (res.data.marker) {
          nextMarker = res.data.marker;
          markers[currentPage + 1] = nextMarker;
          localStorage.setItem('rekognition-s3-markers', JSON.stringify(markers));
        }
      }
    });
  };

  const deleteImage = (url) => {
    const parts = url.split('/');
    axios.delete(`${Constants.apiPath}/actions/cigars/${selectedCigarId}/images`, {
      params: {
        key: parts[parts.length - 1].split('?')[0],
        type: selectedType,
      },
    }).then(() => {
      const updatedImages = selectedImages.filter((item) => item !== url);
      setSelectedImages(updatedImages);
      setCigars(cigars.map((cigar) => {
        if (cigar.id === selectedCigarId) {
          cigar.targets[selectedType] = updatedImages;
        }
        return cigar;
      }));
      ModalDialog.close();
    }).catch((err) => {
      console.error(err);
    });
  };

  useEffect(() => {
    axios.get(`${Constants.apiPath}/actions/cigars/images/stats`, {
      params: {
        next_marker: markers[currentPage],
      },
    }).then((res) => {
      setTotalCount(res.data.label_count);
      setImageCount(res.data.image_count);
    }).catch((err) => {
      console.error(err);
    });
  }, []);

  useEffect(() => {
    getRecords();
  }, [currentPage]);

  // TODO Also need the cigar ID here
  const renderImageGrid = (cigarId, images, type) => {
    console.log('Rerendering images:');
    console.log(images);
    return (
      <span
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setSelectedType(type);
          setSelectedImages(images);
          setShowModal(true);
          setSelectedCigarId(cigarId);
        }}
      >
        {/* FIXME SageMaker requires at least 5 images per label - show an error icon if < 5 */}
        {images?.slice(0, 5).map((image, index) => (
          <span key={index}>
            <img
              src={image}
              alt="Cigar"
              style={{
                height: 90,
                width: 90,
                objectFit: 'contain',
                border: '1px solid #eaeaea',
                borderRadius: 6,
                margin: 3,
              }}
            />
          </span>
        ))}
        {images?.length > 0 && <Icon name="chevron-right" />}
        {images?.length === 0 && <Icon name="plus" />}
      </span>
    );
  };

  const searchGoogle = (cigarId) => {
    axios.get(`${Constants.clientPath}/cigars/${cigarId}`).then((res) => {
      if (res.data && res.data.full_name) {
        window.open(`https://www.google.com/search?tbm=isch&q=${res.data.full_name}`, '_blank');
      }
    }).catch((e) => {
      console.log(e.message);
      alert('Unable to get cigar details');
    });
  };

  // FIXME Allow editing of targets here - should be able to both add and delete and it should reflect changes in S3
  const renderModal = () => (
    <Modal
      isOpen={showModal}
      onClosed={() => setShowModal(false)}
      toggle={() => setShowModal(false)}
      className="modal-dialog-lg"
      style={{ maxWidth: 878 }}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="cigar-modal-title">{selectedType === 'tests' ? 'Edit Test Targets' : 'Edit Training Targets'}</h5>
      </div>
      <div className="modal-body" style={{ textAlign: 'center' }}>
        {/* FIXME Show all option if more than 20 linked? */}
        {selectedImages?.slice(0, 20).map((image, index) => (
          <span
            key={index}
            style={{
              position: 'relative',
              display: 'inline-flex',
              height: 200,
              width: 200,
              cursor: 'pointer',
              margin: 5,
            }}
          >
            <div className="image-overlay">
              {/* TODO Icon to move to tests/training - would be nice to easily just switch it over to the other column (left/right arrows) */}
              <Icon
                name="trash"
                className="delete-image-icon"
                onClick={() => {
                  console.log('Clicked delete icon');
                  console.log(image);
                  ModalDialog.show({
                    title: 'Delete image?',
                    message: 'Are you sure you want to delete this image?',
                    type: 'error',
                    buttons: [{
                      label: 'Cancel',
                      onClick: () => {
                        ModalDialog.close();
                      },
                    }, {
                      label: 'Delete',
                      style: { color: 'rgb(239, 81, 100)' },
                      onClick: () => deleteImage(image),
                    }],
                  });
                }}
              />
              <Icon
                name="maximize2"
                className="expand-icon"
                onClick={() => {
                  setSelectedIndex(index);
                  setShowLightbox(true);
                }}
              />
            </div>
            <img
              src={image}
              alt="Cigar"
              style={{
                height: 200,
                width: 200,
                objectFit: 'contain',
                border: '1px solid #eaeaea',
                borderRadius: 12,
              }}
            />
          </span>
        ))}
        <div id="add-images-dropzone-wrapper">
          <Dropzone
            id="add-images-dropzone"
            // ref={(ref) => this.cigarImageDisplay = ref}
            config={{
              postUrl: 'no-url',
              iconFiletypes: ['.jpg', '.png'],
            }}
            djsConfig={{
              dictDefaultMessage: 'Drop images here',
              // previewTemplate: djsPreviewTemplate,
            }}
            // eventHandlers={this.dropzoneEventHandlers('cigarImageDisplay')}
          >
            {({
              getRootProps,
              getInputProps,
            }) => (
              <section className="dropzone">
                <div style={{ height: '100%' }} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>Drop display image here</p>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
        <div style={{ textAlign: 'center', margin: 20 }}> - or - </div>
        <div style={{ textAlign: 'left', margin: 12 }}>
          <label>Image from URL</label>
          <div className="input-group">
            <input
              className="form-control"
              type="text"
              name="image-url"
              placeholder="https://"
              value={urlToImport}
              onChange={(e) => setUrlToImport(e.target.value)}
            />
            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={() => {
                  // TODO Loading indicator
                  axios.put(`${Constants.apiPath}/actions/cigars/${selectedCigarId}/images`, {
                    image_url: urlToImport,
                    type: selectedType,
                  }).then(() => {
                    const updatedImages = selectedImages.concat(urlToImport);
                    setSelectedImages(updatedImages);
                    setCigars(cigars.map((cigar) => {
                      if (cigar.id === selectedCigarId) {
                        cigar.targets[selectedType] = updatedImages;
                      }
                      return cigar;
                    }));
                    setUrlToImport('');
                  }).catch((err) => {
                    console.error(err);
                  });
                }}
              >
                {'Import'}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <Button onClick={() => setShowModal(false)}>Close</Button>
      </div>
    </Modal>
  );

  const renderPagePickerModal = () => (
    <Modal
      isOpen={showPagePickerModal}
      onClosed={() => setShowPagePickerModal(false)}
      toggle={() => setShowPagePickerModal(false)}
    >
      <div className="modal-header">
        <h5 className="modal-title">Select Page</h5>
      </div>
      <div className="modal-body">
        <TouchSpin
          max={Math.ceil(totalCount / PAGE_SIZE)}
          min={1}
          step={1}
          value={currentPage || pageToSet}
          onChange={(value) => setPageToSet(value)}
        />
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-outline-secondary" onClick={() => setShowPagePickerModal(false)}>
          {'Cancel'}
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            setCurrentPage(pageToSet);
            setShowPagePickerModal(false);
          }}
        >
          {'Go'}
        </button>
      </div>
    </Modal>
  );

  return (
    <div>
      <Breadcrumb title="Cigar Band Image Recognition" label="Image Recognition" parent="Cigar Manager" />

      <div className="container-fluid">
        <Card>
          <CardHeader>
            {/* <h5>Cigar Band Image Recognition</h5> */}
            <span style={{ float: 'left' }}>
              {/* TODO Count all objects in S3 bucket (training and tests) - this can help us estimate training cost, etc which can also be displayed here */}
              {`Total images: ${imageCount}`}
              <br />
              {`Estimated training cost: ${new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(imageCount * 0.00075)}`}
            </span>
            <button
              className="btn"
              onClick={() => window.open(
                'https://studio-d-bjvrogq1w54p.studio.us-east-1.sagemaker.aws/data-wrangler',
                '_blank',
              )}
              style={{
                backgroundColor: '#ff9900',
                float: 'right',
                color: '#16191f',
                fontWeight: 600,
              }}
            >
              {'View in AWS SageMaker'}
              <Icon name="external-link" style={{ marginLeft: 10, marginTop: -3 }} />
            </button>
          </CardHeader>
          <div className="table-responsive">
            <Table size="lg">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Training Targets</th>
                  <th scope="col">Test Targets</th>
                </tr>
              </thead>
              <tbody>
                {cigars.map((cigar) => (
                  <tr>
                    {/* Clicking ID should open the cigar details in new window or popup */}
                    <td>
                      <a
                        href={`/cigars/manager?id=${cigar.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {cigar.id}
                      </a>
                      <Icon
                        name="search"
                        style={{ marginLeft: 10, cursor: 'pointer' }}
                        size={14}
                        onClick={() => searchGoogle(cigar.id)}
                      />
                    </td>
                    <td>{renderImageGrid(cigar.id, cigar.targets.training, 'training')}</td>
                    {/*
                      TODO Also may want to include confidence scores per item if possible - may need to cache them
                        in a new db table unless we can get them from AWS without additional fees - this will help us
                        determine which training data may need updated
                     */}
                    <td>{renderImageGrid(cigar.id, cigar.targets.tests, 'tests')}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div style={{ margin: 20, display: 'flex', justifyContent: 'center' }}>
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={totalCount}
              pageSize={PAGE_SIZE}
              onPageChange={(page) => setCurrentPage(page)}
            />
            <span className="pagination-container" onClick={() => setShowPagePickerModal(true)}>
              <span className="pagination-item">Select Page</span>
            </span>
          </div>
        </Card>
      </div>

      {renderModal()}
      {renderPagePickerModal()}

      {showLightbox && (
        <Lightbox
          mainSrc={selectedImages[selectedIndex]}
          nextSrc={selectedImages[(selectedIndex + 1) % selectedImages.length].image_url}
          prevSrc={selectedImages[(selectedIndex + selectedImages.length - 1) % selectedImages.length].image_url}
          imageTitle={`${selectedIndex + 1}/${selectedImages.length}`}
          onCloseRequest={() => setShowLightbox(false)}
          onMovePrevRequest={() => setSelectedIndex((selectedIndex + selectedImages.length - 1) % selectedImages.length)}
          onMoveNextRequest={() => setSelectedIndex((selectedIndex + 1) % selectedImages.length)}
        />
      )}
    </div>
  );
}

export default CigarBandRecognition;
