import React from 'react';
import { Search } from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { Card } from 'reactstrap';
import '../../assets/css/linked-cigars.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import { getAllOrders } from '../../redux/actions/shop.actions';
import Breadcrumb from '../../components/common/breadcrumb.component';

const { SearchBar } = Search;

const defaultSorted = [{
  dataField: 'name',
  order: 'asc',
}];

const cellEditProps = {
  mode: 'dbclick',
};

class PaymentGatewayTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    // TODO https://www.npmjs.com/package/react-device-detect use a different layout for mobile
    return (
      <div>
        {/* <SweetAlert */}
        {/*  show={this.state.message.show} */}
        {/*  type={this.state.message.type} */}
        {/*  title={this.state.message.title} */}
        {/*  onConfirm={this.closeAlert} */}
        {/* > */}
        {/*    {this.state.message.text} */}
        {/* </SweetAlert> */}

        <Breadcrumb title="Payment Gateways" label="Payment Gateway Config" parent="Shop" />

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <Card style={{ padding: 20 }}>
                <h5>PayPal Smart Buttons</h5>
                <div style={{ maxWidth: 185 }}>
                  <img src="https://webassets.ssisurveys.com/opinionworld/responsive/en-uk/paypal-logo.png" style={{ height: 30, width: 'auto', marginBottom: 20 }} />
                </div>
                <div style={{ display: 'inline', position: 'absolute', right: 20 }}>
                  {'Active'}
                  <Switch
                    checked
                    onChange={() => {}}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </div>
                <span style={{ fontWeight: 700 }}>API Key</span>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  <input
                    className="form-control"
                    placeholder="Currently set via code (do not enter here)"
                    // value={address.city}
                    // style={{textTransform: 'capitalize'}}
                    // onChange={this.handleAddressChange('firstName')}
                  />
                </div>
                <span style={{ fontWeight: 700, marginTop: 10 }}>Sandbox Mode</span>
                <span>Test your PayPal setup by simulating successful and failed transactions.</span>
                <FormControlLabel
                  control={<Checkbox checked={false} onChange={() => {}} name="checkedA" />}
                  label="Enable Sandbox Mode"
                  style={{ fontSize: 12 }}
                />
              </Card>
            </div>
            <div className="col-md-6">
              <Card style={{ padding: 20 }}>
                <h5>Coinbase Commerce</h5>
                <div style={{ maxWidth: 185 }}>
                  <img src="https://assets.coinbase.com/assets/coinbase-commerce.d36bf9ba8e61db78585a84702ba9a0f9.png" style={{ height: 30, width: 'auto', marginBottom: 20 }} />
                </div>
                <div style={{ display: 'inline', position: 'absolute', right: 20 }}>
                  {'Active'}
                  <Switch
                    checked
                    onChange={() => {}}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </div>
                <span style={{ fontWeight: 700 }}>API Key</span>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  <input
                    className="form-control"
                    placeholder="Currently set via code (do not enter here)"
                    // value={address.city}
                    // style={{textTransform: 'capitalize'}}
                    // onChange={this.handleAddressChange('firstName')}
                  />
                </div>
                <span style={{ fontWeight: 700, marginTop: 10 }}>Test Mode</span>
                <span>Test your Coinbase Commerce setup by simulating successful and failed transactions.</span>
                <FormControlLabel
                  control={<Checkbox checked={false} onChange={() => {}} name="checkedA" />}
                  label="Enable Test Mode"
                  style={{ fontSize: 12 }}
                />
              </Card>
            </div>
            <div className="col-md-6">
              <Card style={{ padding: 20 }}>
                <h5>Apple Pay</h5>
                <div style={{ maxWidth: 185 }}>
                  <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b0/Apple_Pay_logo.svg/2560px-Apple_Pay_logo.svg.png" style={{ height: 30, width: 'auto', marginBottom: 20 }} />
                </div>
                <div style={{ display: 'inline', position: 'absolute', right: 20 }}>
                  {'Active'}
                  <Switch
                    checked
                    onChange={() => {}}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </div>
                <span style={{ fontWeight: 700 }}>API Key</span>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  <input
                    className="form-control"
                    placeholder="Currently set via code (do not enter here)"
                    // value={address.city}
                    // style={{textTransform: 'capitalize'}}
                    // onChange={this.handleAddressChange('firstName')}
                  />
                </div>
              </Card>
            </div>
            <div className="col-md-6">
              <Card style={{ padding: 20 }}>
                <h5>Google Pay</h5>
                <div style={{ maxWidth: 185 }}>
                  <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/Google_Pay_%28GPay%29_Logo_%282018-2020%29.svg/1280px-Google_Pay_%28GPay%29_Logo_%282018-2020%29.svg.png" style={{ height: 30, width: 'auto', marginBottom: 20 }} />
                </div>
                <div style={{ display: 'inline', position: 'absolute', right: 20 }}>
                  {'Active'}
                  <Switch
                    checked
                    onChange={() => {}}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </div>
                <span style={{ fontWeight: 700 }}>API Key</span>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  <input
                    className="form-control"
                    placeholder="Currently set via code (do not enter here)"
                    // value={address.city}
                    // style={{textTransform: 'capitalize'}}
                    // onChange={this.handleAddressChange('firstName')}
                  />
                </div>
              </Card>
            </div>
            <div className="col-md-6">
              <Card style={{ padding: 20 }}>
                <h5>Authorize.net</h5>
                <div style={{ maxWidth: 185 }}>
                  <img src="https://www.southfloridawebadvisors.com/wp-content/uploads/2015/03/authorize-net-logo.gif" style={{ height: 30, width: 'auto', marginBottom: 20 }} />
                </div>
                <div style={{ display: 'inline', position: 'absolute', right: 20 }}>
                  {'Active'}
                  <Switch
                    checked={false}
                    onChange={() => {}}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </div>
                <span style={{ fontWeight: 700 }}>API Login ID</span>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  <input
                    className="form-control"
                    placeholder="Currently set via code (do not enter here)"
                    // value={address.city}
                    // style={{textTransform: 'capitalize'}}
                    // onChange={this.handleAddressChange('firstName')}
                  />
                </div>
                <span style={{ fontWeight: 700 }}>Transaction Key</span>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  <input
                    className="form-control"
                    placeholder="Currently set via code (do not enter here)"
                    // value={address.city}
                    // style={{textTransform: 'capitalize'}}
                    // onChange={this.handleAddressChange('firstName')}
                  />
                </div>
                <span style={{ fontWeight: 700, marginTop: 10 }}>Test Mode</span>
                <span>Test your Authorize.net setup by simulating successful and failed transactions.</span>
                <FormControlLabel
                  control={<Checkbox checked={false} onChange={() => {}} name="checkedA" />}
                  label="Enable Test Mode"
                  style={{ fontSize: 12 }}
                />
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ ShopManager }) => ({ ShopManager });

export default connect(
  mapStateToProps, {
    getAllOrders,
  }
)(PaymentGatewayTable);
