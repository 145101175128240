import axios from 'axios';

const env = process.env.NODE_ENV || 'development';
const config = require('../config/config.json')[env];

export const Resize = {
  // TODO https://docs.cloudimage.io/go/cloudimage-documentation-v7/en/image-resizing
  //  `func=crop` => cover, `func=bound` => contain, can also contain with blurred background with `func=fit&bg_img_fit=1&bg_opacity=0.75`
  //  https://docs.cloudimage.io/go/cloudimage-documentation-v7/en/image-resizing/
  thumbnail: (imageUrl, { cropType = 'crop', additionalParams = '' } = {}) => `https://aouikjkrpo.cloudimg.io/v7/${imageUrl}?w=200&h=200&func=${cropType}&force_format=webp${additionalParams || ''}`,
  size: (imageUrl, { width = 500, height = 500, cropType = 'crop', additionalParams = '' } = {}) => `https://aouikjkrpo.cloudimg.io/v7/${imageUrl}?w=${width}&h=${height}&func=${cropType}&force_format=webp${additionalParams || ''}`,
  fromCDN: (originalUrl, prefix, { width = 500, height = 500 } = {}) => originalUrl.replace(prefix, `${width}x${height}${prefix}`),
};

function request(url, opts = {}, onProgress) {
  return new Promise((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.open(opts.method || 'GET', url);
    // eslint-disable-next-line no-restricted-syntax,guard-for-in
    for (const k in opts.headers || {}) {
      xhr.setRequestHeader(k, opts.headers[k]);
    }
    xhr.onload = (e) => res(e.target.responseText);
    xhr.onerror = rej;
    if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress;
    xhr.send(opts.body);
  });
}

export const uploadToS3 = (
  file,
  folder,
  filename,
  uuid,
  callback,
  setUploadStatus
) => new Promise((resolve, reject) => {
  axios
    .post(
      `${config.mediaEndPoint}/sign`,
      {},
      {
        params: {
          uuid,
          filename,
          filetype: file.type,
          type: folder,
        },
      }
    )
    .then((result) => {
      console.debug('Signed response:');
      console.debug(JSON.stringify(result.data.signed_url));
      const options = {
        method: 'PUT',
        body: file,
      };

      if (typeof callback === 'function') {
        callback(result);
      }

      console.debug('Direct upload in progress...');
      // INFO This wasn't working consistently with Axios
      request(result.data.signed_url, options, (event) => {
        console.log('Upload progress:');
        const progress = Math.round((event.loaded / event.total) * 100);
        console.log(progress);
        if (typeof setUploadStatus === 'function') {
          setUploadStatus({ progress, status: 'uploading' });
        }
      })
        .then(() => {
          console.debug('S3 success');
          if (typeof setUploadStatus === 'function') {
            setUploadStatus({ progress: 100, status: 'completed' });
          }
          resolve(result);
        })
        .catch((err) => {
          console.debug('S3 failure:');
          console.debug(err);
          if (typeof setUploadStatus === 'function') {
            setUploadStatus({ progress: 0, status: 'failed' });
          }
          reject(err);
        });
    })
    .catch((err) => {
      console.debug('Failed');
      reject(err);
    });
});
