import React from 'react';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactDOM from 'react-dom';
// import IosAlert from '../IosModal';
import './style.scss';

// SEE https://github.com/GA-MO/react-confirm-alert/blob/master/src/index.js
// It would be nice to be able to show these by calling a function with options rather than rendering
// Should do the same for toasts and similar types of display elements that just need triggered
function ModalDialog(props) {
  // if (isIOS) {
  //   const items = [{
  //     label: 'Dismiss',
  //     onClick: props.onClose,
  //   }];
  //   return (
  //     <IosAlert
  //       show={props.show}
  //       title={props.title}
  //       content={props.message}
  //       buttons={props.buttons || items}
  //       onDismiss={() => {}}
  //     />
  //   );
  // }

  // if (isAndroid) {
  if (isMobile) {
    // FIXME From props? Build from an array of objects?
    const actions = props.buttons ? props.buttons.map((button) => (
      <Button
        primary // TODO Allow different button types?
        onClick={button.onClick}
      >
        {button.label}
      </Button>
    )) : [
      <Button
        primary
        onClick={props.onClose}
      >
        Dismiss
      </Button>,
    ];
    return (
      <Dialog
        title={props.title}
        actions={actions}
        modal={false}
        open={props.show}
        onClose={props.onClose}
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {actions}
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <SweetAlert
      show={props.show}
      title={props.title}
      type={props.type || 'info'}
      showCancelButton
      onConfirm={props.onConfirm}
      onCancel={props.onClose}
      customButtons={props.buttons && (
        <React.Fragment>
          {props.buttons.map((button) => (
            <Button
                primary
                style={button.style}
                onClick={button.onClick}
            >
              {button.label}
            </Button>
          ))}
        </React.Fragment>
      )}
      onOutsideClick={props.onClose}
    >
      {props.message}
    </SweetAlert>
  );
}

ModalDialog.show = (props) => {
  let divTarget = document.getElementById('react-modal-dialog');
  if (divTarget) {
    // Rerender - the mounted ModalDialog
    ReactDOM.render(<ModalDialog show {...props} />, divTarget);
  } else {
    // Mount the ModalDialog component
    // FIXME Something to add to prevent the back divs from scrolling?
    // document.body.children[0].classList.add('react-confirm-alert-blur');
    divTarget = document.createElement('div');
    divTarget.id = 'react-modal-dialog';
    document.body.appendChild(divTarget);
    ReactDOM.render(<ModalDialog show {...props} />, divTarget);
  }
};

ModalDialog.close = () => {
  const target = document.getElementById('react-modal-dialog');
  if (target) {
    ReactDOM.unmountComponentAtNode(target);
    target.parentNode.removeChild(target);
  }
};

export default ModalDialog;
