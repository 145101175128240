import React from 'react';

export default function TableLoader() {
  return (
    <div className="table-loader">
      <div className="loader">
        <div className="line bg-primary" />
        <div className="line bg-primary" />
        <div className="line bg-primary" />
        <div className="line bg-primary" />
        <div className="line bg-primary" />
      </div>
    </div>
  );
}
