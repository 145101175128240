import { UPDATE_CART } from './actionTypes';

export const setShopState = (cart) => dispatch => {
  dispatch({
    type: UPDATE_CART,
    payload: {
      loading: false,
      data: cart,
    },
  });
};
