import React, { useRef } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { isMobile } from 'mobile-device-detect';
import Cropper from 'react-cropper';
import Icon from '../icon';
import 'cropperjs/dist/cropper.css';
import './style.scss';
import Button from '@material-ui/core/Button';

const maxResolution = 4000000;
export const resizedImage = (height, width) => {
  let x = width;
  let y = height;
  const ratio = height / width;

  if ((width * height) > maxResolution) {
    x = Math.sqrt(maxResolution / ratio);
    y = maxResolution / x;
  }
  return { height: y, width: x };
};

function MediaCropper(props) {
  const cropper = useRef();

  const crop = () => {
    if (typeof cropper.current.getCroppedCanvas === 'function') {
      const canvas = cropper.current.getCroppedCanvas({ fillColor: '#fff' });
      const { width, height } = canvas;
      const resized = resizedImage(height, width);
      if (typeof props.onSave === 'function') {
        props.onSave(cropper.current.getCroppedCanvas({ fillColor: '#fff', width: resized.width, height: resized.height }));
      }
    }
  };

  const renderCropper = () => (
    <Cropper
      ref={cropper}
      src={props.src}
      style={{
        height: isMobile ? '100%' : '624px',
        width: '100%',
        maxHeight: isMobile ? '100vh' : '624px',
        maxWidth: '100vw',
      }}
      // Cropper.js options
      background={false}
      viewMode={1}
      aspectRatio={props.circle ? 1 : props.aspectRatio} // TODO Use state and app bar to allow user to change this between common types and freeform, unless circle is true
      guides
    />
  );

  return (
    <Modal
      isOpen={props.open}
      toggle={props.toggle}
      style={{ maxWidth: 724 }}
      fade
    >
      <div className="modal-header">
        <Button className="close" color="" onClick={props.onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        <div className={props.circle ? 'circle-crop' : ''}>
          {renderCropper()}
        </div>
      </ModalBody>
      <ModalFooter>
        <div style={{ float: 'left' }}>
          <Icon name="rotate-ccw" style={{ cursor: 'pointer' }} onClick={() => cropper.current && cropper.current.rotate(-45)} />
          <Icon name="rotate-cw" style={{ cursor: 'pointer' }} onClick={() => cropper.current && cropper.current.rotate(45)} />
        </div>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button onClick={crop} variant="contained" color="secondary">Next</Button>
      </ModalFooter>
    </Modal>
  );
}

MediaCropper.defaultProps = {
  aspectRatio: null,
};

export default MediaCropper;
