import React from 'react';
import { ChipSet, Chip } from '@material/react-chips';
import AsyncCreatableSelect from 'react-select/async-creatable';
import '@material/react-chips/dist/chips.css';
import axios from 'axios';
import { Constants } from '../../../constants';
import Button from '../../overrides/button';
import './style.scss';
import { Modal } from 'reactstrap';

const lengths = [];
const gauges = [];

export const defaultVitolas = [{
  label: 'Churchill',
  value: 'Churchill',
}, {
  label: 'Cigarillo',
  value: 'Cigarillo',
}, {
  label: 'Corona',
  value: 'Corona',
}, {
  label: 'Double Corona',
  value: 'Double Corona',
}, {
  label: 'Double Toro',
  value: 'Double Toro',
}, {
  label: 'Lancero',
  value: 'Lancero',
}, {
  label: 'Perfecto',
  value: 'Perfecto',
}, {
  label: 'Robusto',
  value: 'Robusto',
}, {
  label: 'Toro',
  value: 'Toro',
}, {
  label: 'Torpedo',
  value: 'Torpedo',
}];

export const formattedVitolaName = ({ alias, shape, size }) => {
  let name = '';
  if (alias && shape) {
    // Alias (Shape - #.#"x#)
    name = `${alias} (${shape} - ${size})`;
  } else if (alias && !shape) {
    // Alias (#.#"x#)
    name = `${alias} (${size})`;
  } else if (!alias && shape) {
    // Shape (#.#"x#)
    name = `${shape} (${size})`;
  } else {
    // #.#"x#
    name = size;
  }
  return name;
};

class VitolaEditor extends React.Component {
    state = {
      alias: '',
      shape: null,
      length: '',
      gauge: '',
    };

    constructor(props) {
      super(props);

      if (lengths.length === 0) {
        for (let l = 4.0; l < 8.0; l += 0.5) {
          lengths.push(l);
        }
        lengths.push('Other');
      }

      if (gauges.length === 0) {
        for (let g = 34; g < 71; g++) {
          gauges.push(g);
        }
        gauges.push('Other');
      }
    }

    loadShapes = (searchTerm, callback) => {
      console.log(`${Constants.clientPath}/cigars/shapes?q=${searchTerm}`);
      return axios.get(`${Constants.clientPath}/cigars/shapes?q=${searchTerm}`)
        .then((res) => {
          console.log(res.data);
          callback(res.data.map((shape) => ({
            value: shape,
            label: shape,
          })));
        }).catch((err) => {
          console.log(err);
        });
    };

    createLengthChipSet = () => (
      <ChipSet
        choice
        selectedChipIds={this.state.length}
        handleSelect={(length) => this.setState({ length })}
      >
        {lengths.map((length) => {
          if (length !== 'Other') {
            return this.createChip(length, `${length}"`);
          }
          return this.createChip(length, length);
        })}
      </ChipSet>
    );

    createGaugeChipSet = () => (
      <ChipSet
        choice
        selectedChipIds={this.state.gauge}
        handleSelect={(gauge) => this.setState({ gauge })}
      >
        {gauges.map((gauge) => {
          if (gauge !== 'Other') {
            return this.createChip(gauge, `${gauge}"`);
          }
          return this.createChip(gauge, gauge);
        })}
      </ChipSet>
    );

    createChip = (id, label) => (
      <Chip id={id} label={label} style={{ margin: 8 }} />
    );

    render() {
      console.log('Selected length');
      console.log(this.state.length);
      return (
        <Modal isOpen={this.props.open} onClosed={this.props.onClose}>
          <div className="modal-header">
            <h5 className="modal-title">Add Vitola</h5>
          </div>
          <div className="modal-body md">
            {/*  FIXME Make this a mobile only option / route instead of modal?  */}
            <div>
              <div style={{ paddingTop: 10 }}>
                <span>Alias (Optional)</span>
                <input
                  name="alias"
                  className="form-control"
                  type="text"
                  value={this.state.alias}
                  style={{ textTransform: 'capitalize' }}
                  placeholder="Example: No. 2"
                />
              </div>
              <div style={{ paddingTop: 10 }}>
                <span>Shape</span>
                <AsyncCreatableSelect
                  value={this.state.shape}
                  placeholder="Search to select"
                  onChange={(value) => this.setState({ shape: value })}
                  getOptionLabel={(option) => `${option.label}`}
                  getOptionValue={(option) => `${option}`}
                  isOptionSelected={(option) => this.state.shape && this.state.shape.value === option.value}
                  loadOptions={this.loadShapes}
                  defaultOptions={defaultVitolas}
                />
              </div>
              <div style={{ paddingTop: 10 }}>
                <span>Length</span>
                {this.createLengthChipSet()}
                {this.state.length.length > 0 && this.state.length[0] === 'Other' && (
                  <input className="form-control" placeholder="Example: 7.2" />
                )}
              </div>
              <div style={{ paddingTop: 10 }}>
                <span>Gauge</span>
                {this.createGaugeChipSet()}
                {this.state.gauge.length > 0 && this.state.gauge[0] === 'Other' && (
                  <input className="form-control" placeholder="Example: 80" />
                )}
              </div>

              {/*  TODO box pressed and tubo switch toggles  */}
            </div>
          </div>
          <div className="modal-footer">
            <Button onClick={this.props.onClose}>Cancel</Button>
            <Button
              onClick={() => {
                if (!this.state.shape) {
                  alert('Shape is required');
                  return;
                }
                if (this.state.gauge.length === 0 || this.state.length.length === 0) {
                  alert('Size is required');
                  return;
                }
                if (typeof this.props.onSave === 'function') {
                  const vitola = {
                    alias: this.state.alias,
                    shape: this.state.shape.value,
                    size: `${parseFloat(this.state.length).toFixed(1)}"x${this.state.gauge}`,
                  };
                  this.props.onSave({
                    ...vitola,
                    formatted_name: formattedVitolaName(vitola),
                    select_shape: this.state.shape,
                  });
                  this.setState({
                    alias: '',
                    shape: null,
                    length: '',
                    gauge: '',
                  });
                }
              }}
              variant="contained"
              color="secondary"
            >
              {'Set'}
            </Button>
          </div>
        </Modal>
      );
    }
}

export default VitolaEditor;
