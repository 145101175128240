import axios from 'axios';
import store from '../store';
import { setShopState } from '../redux/actions/cart.actions';
import { v4 as uuidv4 } from 'uuid';
import { Constants } from '../constants';

const env = process.env.NODE_ENV || 'development';
const config = require('../config/config.json')[env];

const calculateDiscountTotal = (cart) => {
  let total = 0;
  const discounts = cart.discounts || [];
  for (const discount of discounts) {
    if (discount.type === 'percentage') {
      total += cart.subtotal_price * (discount.amount / 100);
    } else if (discount.type === 'fixed') {
      total += parseFloat(discount.amount);
    }
  }
  return total;
};

export default {
  // FIXME Will we ever need this function? update() is probably the one we want so long as the cart gets reset in Redux after being converted to a draft or order
  create: () => new Promise(async (resolve, reject) => {
    const cart = {};
    await axios.post(`${config.shopEndPoint}/carts`, cart)
      .then((response) => {
        store.dispatch(setShopState(response.data));
        resolve(response.data);
      }).catch((err) => {
        reject(err);
      });
  }),
  update: (cart) => new Promise(async (resolve, reject) => {
    if (cart.id) {
      await axios.put(`${config.shopEndPoint}/carts/${cart.id}`, cart)
        .then((response) => {
          store.dispatch(setShopState(response.data));
          resolve(response.data);
        }).catch((err) => {
          reject(err);
        });
    } else {
      await axios.post(`${config.shopEndPoint}/carts`, cart)
        .then((response) => {
          store.dispatch(setShopState(response.data));
          resolve(response.data);
        }).catch((err) => {
          reject(err);
        });
    }
  }),
  addProduct: (product, cart) => new Promise((resolve, reject) => {
    // FIXME This needs to recognize if a product is already in the cart and simply increase the quantity instead of add another line item
    // TODO Link product to cart on server FIXME I actually don't think we need to do it every time. Wait for events like closing tab or moving to checkout and pass the whole cart
    console.log('Store state:');
    console.log(store.getState());
    let { cart } = store.getState().CartManager;
    console.log('Cart state:');
    console.log(cart);
    if (!cart) {
      cart = {
        token: uuidv4(),
        // TODO Link whatever else we have available
      };
    }
    if (!cart.items) {
      cart.items = [];
    }
    cart.items.push({
      product: {
        ...product,
        id: product.product_id,
      },
      quantity: product.quantity || 1,
      price: product.price,
      subtotal_price: (product.price * (product.quantity || 1)),
      unique_id: uuidv4(),
    });
    let total = 0;
    cart.items.forEach((item) => {
      total += item.subtotal_price;
    });
    cart.subtotal_price = total;
    console.log('Updated cart line items:');
    // FIXME This needs to account for the other items - discounts, taxes, and shipping
    cart.discount_price = calculateDiscountTotal(cart);
    cart.total_price = total - cart.discount_price;
    console.log(cart);
    store.dispatch(setShopState(cart));
    resolve(cart);
  }),
  updateProduct: (data) => new Promise((resolve, reject) => {
    console.log(store);
    const { cart } = store.getState().CartManager;
    console.log('Updating cart:');
    console.log(cart);
    if (cart) {
      if (cart.items) {
        let i = cart.items.length;
        while (i--) {
          const item = cart.items[i];
          console.log('Updating item:');
          console.log(item);
          if (item.id === data.id) {
            item.quantity = data.quantity;
            item.total_price = (data.price * data.quantity);
            break;
          }
        }
        let total = 0;
        cart.items.forEach((item) => {
          total += item.total_price;
        });
        cart.subtotal_price = total;
        // FIXME This needs to account for the other items - discounts, taxes, and shipping
        cart.discount_price = calculateDiscountTotal(cart);
        cart.total_price = total - cart.discount_price;
        store.dispatch(setShopState(cart));
        resolve(cart);
      } else {
        reject('Product not found');
      }
    } else {
      reject('Cart not initialized');
    }
  }),
  removeProduct: (uniqueId) => new Promise((resolve, reject) => {
    console.log(`Removing product with unique ID ${uniqueId}`);
    // TODO Remove the line item (what's the best way to do this in the db - actually delete the record or set active flag to false?)
    const { cart } = store.getState().CartManager;
    if (cart.items) {
      let i = cart.items.length;
      while (i--) {
        const item = cart.items[i];
        if (item.unique_id === uniqueId) {
          cart.items.splice(i, 1);
          break;
        }
      }
      let total = 0;
      cart.items.forEach((item) => {
        total += item.total_price;
      });
      cart.subtotal_price = total;
      // FIXME This needs to account for the other items - discounts, taxes, and shipping
      cart.discount_price = calculateDiscountTotal(cart);
      cart.total_price = total - cart.discount_price;
      store.dispatch(setShopState(cart));
    } else {
      reject('Product not found');
    }
  }),
  applyDiscount: (discountCode) => new Promise(async (resolve, reject) => {
    const { cart } = store.getState().CartManager;
    if (!cart.discounts || !Array.isArray(cart.discounts)) {
      cart.discounts = [];
    }
    await axios.post(`${config.shopEndPoint}/discounts`, { code: discountCode }).then((response) => {
      console.log(response.data);
      if (response.status === 200) {
        const discount = response.data;
        cart.discounts.push(discount);
        // TODO Calculate discount price here and update cart subtotal_price
        if (discount.type === 'percentage') {
          discount.price = (cart.subtotal_price * (discount.amount / 100)).toFixed(2);
        } else if (discount.type === 'fixed') {
          discount.price = discount.amount.toFixed(2);
        }
        cart.discount_price = calculateDiscountTotal(cart);
        cart.total_price = cart.subtotal_price - cart.discount_price;
        store.dispatch(setShopState(cart));
        resolve(cart);
      } else {
        reject(response.data);
        // TODO Need to update the front end to reflect the error - typically, invalid code
      }
    }).catch((err) => {
      reject(err.response.data);
    });
  }),
  removeDiscount: (discountCode) => new Promise((resolve, reject) => {
    const { cart } = store.getState().CartManager;
    if (!cart.remove_discounts) {
      cart.remove_discounts = [];
    }
    // FIXME Not sure this is necessary - we don't really need to make these kind of changes in real-time on the server
    //  Instead, right before the user closes the tab or maybe after a timeout, we need to send the whole cart to the
    //  server and mark it as abandoned for follow-up - should automate it to an extent using a queue or similar
    // axios.delete(`${config.shopEndPoint}/shop/discounts?code=${discountCode}`).then((response) => {
      let i = cart.discounts.length;
      while (i--) {
        const item = cart.discounts[i];
        if (item.code === discountCode) {
          cart.remove_discounts.push(item);
          cart.discounts.splice(i, 1);
          break;
        }
      }
      cart.discount_price = calculateDiscountTotal(cart);
      cart.total_price = cart.subtotal_price - cart.discount_price;
      store.dispatch(setShopState(cart));
      resolve(cart);
    // }).catch((err) => {
    //   reject(err);
    // });
  }),
  fetchShippingRates: () => new Promise((resolve, reject) => {
    const { cart } = store.getState().CartManager;
    axios.get(`${config.shopEndPoint}/shipping/methods?zip=${cart.shipping_address.zip}`).then((response) => {
      cart.shipping_rates = response.data;
      store.dispatch(setShopState(cart));
      resolve(cart);
    }).catch((err) => {
      reject(err);
    });
  }),
  setShippingInformation: (shippingRate, shippingAddress) => new Promise((resolve, reject) => {
    // INFO Right now, the callback handles calling cartActions.update() to pass these to the server and recalculate the totals
    const { cart } = store.getState().CartManager;
    cart.shipping_rate = shippingRate;
    cart.shipping_address = shippingAddress;
    store.dispatch(setShopState(cart));
    resolve(cart);
  }),
  initializePaymentSession: () => new Promise((resolve, reject) => {
    console.log('Getting payment methods from server:');
    axios.get(`${config.shopEndPoint}/payments/methods`).then((response) => {
      resolve(response.data);
    }).catch((err) => {
      reject(err);
    });
  }),
  processPayment: (data) => new Promise((resolve, reject) => {
    axios.post(`${Constants.apiPath}/shop/payments/confirm`, data).then((response) => {
      resolve(response.data);
    }).catch((err) => {
      reject(err);
    });
  }),
  confirm: (data) => new Promise((resolve, reject) => {
    axios.post(`${Constants.apiPath}/shop/orders`, data).then((response) => {
      resolve(response.data);
    }).catch((err) => {
      reject(err);
    });
  }),
};
