import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationListStandalone, PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import AsyncSelect from 'react-select/async';
import Modal from 'react-responsive-modal';
import { connect } from 'react-redux';
import { Input, Label, Tooltip, UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { isMobile } from 'react-device-detect';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '../../components/icon';
import Breadcrumb from '../../components/common/breadcrumb.component';
import { getPendingCigars } from '../../redux/actions/cigar.actions';
import Button from '../../components/overrides/button';
import Pagination from '../../components/ui/base/pagination';
import { DebounceInput } from 'react-debounce-input';
import DebounceSearchBar from '../../components/DebounceSearchBar';

const { SearchBar } = Search;

const defaultSorted = [{
  dataField: 'brand',
  order: 'asc',
}];

const cellEditProps = {
  mode: 'dbclick',
};

// TODO Remote Search https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Remote&selectedStory=Remote%20Search&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel

const defaults = {
  mergeCigar: '',
};

const NoDataIndication = () => (
  <div className="table-loader">
    <div className="loader">
      <div className="line bg-primary"></div>
      <div className="line bg-primary"></div>
      <div className="line bg-primary"></div>
      <div className="line bg-primary"></div>
      <div className="line bg-primary"></div>
    </div>
  </div>
);

class CigarTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      showModal: false,
      showTooltip: false,
      showPendingCigars: true,
      showCustomCigars: true,
      ...defaults,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  keyForType = (type) => {
    switch (type) {
      case 'merge':
        return 'showModal';
      case 'filter':
        return 'showFilterDrawer';
      default:
        return '';
    }
  };

  onOpenModal = (type) => {
    this.setState({ [this.keyForType(type)]: true });
  };

  onCloseModal = (type) => {
    this.setState({
      [this.keyForType(type)]: false,
      ...defaults,
    });
  };

  onSaveModal = (type) => {
    console.log('Submitting!');
    // TODO axios submit + loading, then finish
    this.setState({
      [this.keyForType(type)]: false,
      ...defaults,
    });
  };

  imageFormatter = (cell, row) => (
    <img style={{ maxWidth: 75 }} src={cell} />
  );

  handleChange = (key) => (value) => {
    this.setState({ [key]: value });
  };

  handleCheckboxChange = (key) => (event) => {
    this.setState({ [key]: event.target.checked });
  };

  checkBoxFormatter = (cell, row) => (
    <div className="checkbox checkbox-secondary">
      <input name="checkbox" checked={cell} type="checkbox" />
      <label></label>
    </div>
  );

  viewBtnFormatter = (cell, row) => {
    if (cell && cell.indexOf('href') !== -1) {
      const href = cell.match(/href="([^"]*)/)[1];
      if (href) {
        const id = href.replace('cigar-manager.jsp?id=', '');
        return (
          <Link to={`${process.env.PUBLIC_URL}/manager/cigars?id=${id}`}>View</Link>
        );
      }
    }
    return (<span></span>);
  };

  mergeBtnFormatter = (cell, row, index) =>
    // TODO Set the active row so we have the ID to merge
    (
      <div
        style={{
          width: 50,
          textAlign: 'right',
        }}
      >
        <div id={`actions-${index}`} className="d-none">
          <Icon
            id={`merge-${index}`}
            name="git-merge"
            style={{
              height: 18,
              margin: 0,
              padding: 0,
              cursor: 'pointer',
            }}
            onClick={() => this.onOpenModal('merge')}
          />
          <UncontrolledTooltip placement="bottom" target={`merge-${index}`} trigger="hover">Merge</UncontrolledTooltip>
          <Icon
            name="trash"
            style={{
              color: '#FF5370',
              height: 18,
              margin: 0,
              padding: 0,
              cursor: 'pointer',
            }}
            onClick={() => alert('Coming soon!')}
          />
        </div>
      </div>
    )
  ;

  handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit }) => {
    this.props.getPendingCigars(page, sizePerPage);
  };

  toggleTooltip() {
    this.setState({
      showTooltip: !this.state.showTooltip,
    });
  }

  resetFilters = () => {
    // TODO Set them back to defaults and reload
    alert('Reset coming soon!');
  };

  renderFilterOptions = () => (
    <SwipeableDrawer
      anchor="right"
      open={this.state.showFilterDrawer}
      // onClick={() => this.setState({ showFilterDrawer: false })}
      // onKeyDown={() => this.setState({ showFilterDrawer: false })}
      onClose={() => this.setState({ showFilterDrawer: false })}
    >
      {/* TODO Add app bar on mobile with back arrow */}
      <div
        style={{
          width: isMobile ? 'calc(100vw - 40px)' : 300,
          marginLeft: 20,
          marginRight: 20,
          marginBottom: 30,
        }}
      >
        <div style={{ height: 20 }} />
        <FormLabel component="legend">By Type</FormLabel>
        <div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={this.state.showPendingCigars}
                onChange={this.handleCheckboxChange('showPendingCigars')}
                value="primary"
                inputProps={{ 'aria-label': 'Pending cigars' }}
              />
            )}
            label="Pending"
          />
        </div>
        <div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={this.state.showCustomCigars}
                onChange={this.handleCheckboxChange('showCustomCigars')}
                value="primary"
                inputProps={{ 'aria-label': 'Custom pending cigars' }}
              />
            )}
            label="Custom"
          />
        </div>

        <div
          style={{
            display: 'flex',
            position: 'fixed',
            bottom: 0,
            backgroundColor: 'white',
            width: 300,
            paddingTop: 10,
            paddingBottom: 10,
            margin: 'auto',
          }}
        >
          <Button
            onClick={() => {
              this.setState({
                showFilterDrawer: false,
              }, this.resetFilters);
            }}
            style={{
              display: 'block',
              flex: 1,
              marginRight: 10,
              textAlign: 'center',
            }}
          >
            {'Reset'}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{
              display: 'block',
              flex: 1,
              textAlign: 'center',
            }}
            onClick={() => this.onSaveModal('filter')}
          >
            {'Apply'}
          </Button>
        </div>
      </div>
    </SwipeableDrawer>
  );

  render() {
    const { sizePerPage, page } = this.state;
    const { pendingCigars, totalSize } = this.props.CigarManager;

    console.log('Pending cigars:');
    console.log(pendingCigars);

    const columns = [{
      dataField: 'image-full',
      text: 'Image',
      formatter: this.imageFormatter,
      sort: false,
    }, {
      dataField: 'brand',
      text: 'Brand',
      sort: true,
    }, {
      dataField: 'name',
      text: 'Name',
      sort: true,
    }, {
      dataField: 'origin',
      text: 'Origin',
      sort: true,
    }, {
      dataField: 'wrapper',
      text: 'Wrapper',
      sort: true,
    }, {
      dataField: 'custom',
      text: 'Custom',
      formatter: this.checkBoxFormatter,
      sort: true,
    }, {
      dataField: 'view',
      text: '',
      formatter: this.viewBtnFormatter,
      sort: true,
    }, {
      dataField: 'accept',
      text: '',
      formatter: this.mergeBtnFormatter,
      sort: true,
    }];

    const rowEvents = {
      onMouseEnter: (e, row, index) => {
        // console.log("Mouse entered: " + index);
        document.querySelector(`#actions-${index}`).classList.remove('d-none');
      },
      onMouseLeave: (e, row, index) => {
        document.querySelector(`#actions-${index}`).classList.add('d-none');
      },
      onDoubleClick: (e, row, index) => {
        // INFO If we don't include this event, the double click to edit doesn't work
        // console.log(e);
      },
    };

    const pageButtonRenderer = ({ page, active, onPageChange }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      let classname = 'btn btn-outline-secondary';
      if (active) {
        classname = 'btn btn-secondary';
      }
      return (
        <li className="page-item pl-1" key={page}>
          <a href="#" onClick={handleClick} className={classname}>{page}</a>
        </li>
      );
    };

    // TODO https://www.npmjs.com/package/react-device-detect change for mobile
    return (
      <div>
        <Breadcrumb title="Pending Cigars" label="Pending" parent="Cigars" />

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body datatable-react">
                  <PaginationProvider
                    pagination={paginationFactory({
                      pageButtonRenderer,
                      page,
                      sizePerPage,
                      custom: true,
                      totalSize,
                      showTotal: true,
                    })}
                  >
                    {
                      ({
                        paginationProps,
                        paginationTableProps,
                      }) => (
                        <div>
                          <ToolkitProvider
                            keyField="id"
                            data={pendingCigars}
                            columns={columns}
                            search
                          >
                            {
                              (toolkitprops) => (
                                <div>
                                  <DebounceSearchBar
                                    {...toolkitprops.searchProps}
                                    placeholder="Search pending cigars..."
                                    onChange={() => this.setState({ page: 1 })}
                                  />
                                  <button
                                    className="btn btn-outline-primary mr-2"
                                    onClick={() => this.onOpenModal('filter')}
                                    style={isMobile ? {
                                      padding: '6px 14px',
                                      float: 'right',
                                    } : { float: 'right' }}
                                  >
                                    {isMobile ? (<i className="icon icon-filter" />) : 'Filter'}
                                  </button>
                                  <div style={{ marginLeft: 10, float: 'left' }}>
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <BootstrapTable
                                    remote
                                    keyField="id"
                                    data={pendingCigars}
                                    columns={columns}
                                    rowEvents={rowEvents}
                                    defaultSorted={defaultSorted}
                                    wrapperClasses="table-responsive"
                                    {...paginationTableProps}
                                    cellEdit={cellEditFactory(cellEditProps)}
                                    onTableChange={this.handleTableChange}
                                    noDataIndication={() => <NoDataIndication />}
                                    {...toolkitprops.baseProps}
                                  />
                                  <PaginationTotalStandalone
                                    {...paginationProps}
                                  />
                                  <div style={isMobile ? { marginTop: 10 } : { width: 200, display: 'inline-block', float: 'right' }}>
                                    <Pagination
                                      hideNavigation={!isMobile}
                                      page={page}
                                      sizePerPage={sizePerPage}
                                      totalSize={totalSize}
                                      handlePageChange={this.handlePageChange}
                                      onShowPagePicker={() => this.onOpenModal('page')}
                                    />
                                  </div>
                                  {!isMobile && (
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  )}
                                  {this.mergeModal()}
                                </div>
                              )
                            }
                          </ToolkitProvider>
                        </div>
                      )
                    }
                  </PaginationProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderFilterOptions()}
      </div>
    );
  }

  mergeModal = () => (
    <Modal open={this.state.showModal} onClose={() => this.onCloseModal('merge')}>
      <div className="modal-header">
        <h5 className="modal-title">Merge Cigar</h5>
      </div>
      {/* INFO We can control the size on this div tag */}
      <div className="modal-body">
        Select cigar to merge:
        <AsyncSelect
          value={this.state.mergeCigar}
          onChange={this.handleChange('mergeCigar')}
          getOptionLabel={(option) => `${option.label}`}
          getOptionValue={(option) => `${option}`}
          isOptionSelected={(option) => this.state.mergeCigar.value === option.value}
        />
        <div className="checkbox checkbox-primary ml-3">
          <Input name="force" id="force-cb" type="checkbox" checked={this.state.selectedCanSmoke} />
          <Label className="mb-0" htmlFor="force-cb">
            {'Force into active cigar?'}
            <i className="icon-info-alt" id="forceTooltip" />
          </Label>
        </div>
        <Tooltip placement="top" isOpen={this.state.showTooltip} target="forceTooltip" toggle={this.toggleTooltip}>
          {'This will force the cigar from the select field above into the cigar you are actively editing.'}
        </Tooltip>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-outline-secondary" onClick={() => this.onCloseModal('merge')}>
          {'Close'}
        </button>
        <button type="button" className="btn btn-primary" onClick={() => this.onSaveModal('merge')}>Merge</button>
      </div>
    </Modal>
  );
}

const mapStateToProps = ({ CigarManager }) => ({ CigarManager });

export default connect(
  mapStateToProps, {
    getPendingCigars,
  },
)(CigarTable);
