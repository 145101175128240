import React, { Component } from 'react';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import Modal from 'react-responsive-modal';
import Autocomplete from 'react-google-autocomplete';
import { withRouter } from 'react-router-dom';
import Breadcrumb from '../../components/common/breadcrumb.component';
import ClusterMap from './components/cluster-map';
import { Constants } from '../../constants';
import Placeholder from '../../config/placeholder.config';
import Hashids from 'hashids';
const hashids = new Hashids('', 12);

const centerCoords = {
  lat: 39.8097343,
  lng: -98.5556199,
};

const env = process.env.NODE_ENV || 'development';
const config = require('../../config/config.json')[env];

class VenueDashboard extends Component {
  constructor(props) {
    super(props);

    axios.get(`${Constants.apiPath}/venues/dashboard`).then((response) => {
      const { data } = response;
      console.log(data);
      this.setState({
        topRatedVenues: data.top_rated_venues,
        mostCheckedInVenues: data.most_checked_in_venues,
      });
    });

    this.state = {
      data: 'grouped',
      legendPosition: 'top-left',
      interpolate: 'linear',
      xLabel: 'M',
      yLabel: 'GGGGGGGGGG',
      hidePoints: false,
      hideLines: false,
      yMin: null,
      yMax: null,
      xMax: null,
      xMin: null,
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      showTowerModal: false,
      selectedTowerCenterPoint: null,
      selectedTowerRadius: 0,
      zoom: 4,
      mapCenter: centerCoords,
      towers: [],
      venueMap: {},
      venues: [],
      checkins: [],
      topRatedVenues: [],
      mostCheckedInVenues: [],
    };
  }

  componentWillMount() {
    axios.get(`${Constants.apiPath}/venues/towers`).then((results) => {
      console.log(results.data);
      this.setState({
        towers: results.data.map((location) => ({
          address: location.address,
          lat: parseFloat(location.latitude),
          lng: parseFloat(location.longitude),
        })),
      });
    }).catch((err) => {
      console.log(err);
    });
    this.updateVenues();
  }

  updateVenues = () => {
    axios.get(`${config.apiEndPoint}/venues/nearby`, {
      params: {
        limit: 500,
        latitude: parseFloat(this.state.mapCenter.lat),
        longitude: parseFloat(this.state.mapCenter.lng),
        radius: 10000000,
        can_buy: null,
        can_smoke: null,
      },
    }).then((results) => {
      console.log(JSON.stringify(results.data));
      // FIXME How well will this handle a lot of data? Consider something like https://www.npmjs.com/package/use-supercluster?
      //  Example at https://www.leighhalliday.com/google-maps-clustering - I'd like to update this file and/or CluserMap to a functional component anyway
      //  https://www.youtube.com/watch?v=-NI5e_GTIko
      this.setState((prevState) => {
        const { venueMap } = prevState;
        for (const venue of results.data) {
          if (venue.location && venue.location.coordinate && venue.location.coordinate.latitude && venue.location.coordinate.longitude) {
            if (venue.id && venue.id !== 30938) { // Ignore Boxpressd at Home location
              venueMap[venue.id] = venue;
            }
          }
        }
        return {
          venueMap,
          venues: Object.keys(venueMap).map((key) => {
            const venue = venueMap[key];
            return {
              name: venue.name,
              address: venue.location.formatted_address,
              lat: parseFloat(venue.location.coordinate.latitude),
              lng: parseFloat(venue.location.coordinate.longitude),
            };
          }),
        };
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  keyForType = (type) => {
    switch (type) {
      case 'tower':
        return 'showTowerModal';
      default:
        return '';
    }
  };

  onOpenModal = (type) => {
    this.setState({ [this.keyForType(type)]: true });
  };

  onCloseModal = (type) => {
    if (type === 'tower') {
      this.setState({
        [this.keyForType(type)]: false,
        // ...defaults
      });
    } else {
      this.setState({
        [this.keyForType(type)]: false,
      });
    }
  };

  onSaveModal = (type) => {
    if (type === 'tower') {
      // TODO Send to server
      alert('Not yet implemented');
    }
  };

  // onGroupedHover(point) {
  //     const formatted = d3.time.format("%b %d")(d3.time.format("%Y-%m-%d").parse(point.x));
  //     return `<b>Date: </b>${formatted}<br /><b>Value: </b>${point.y}`;
  // }
  onScatterHover(point) {
    return `<b>Date: </b>${point.x}<br /><b>Value: </b>${point.y}`;
  }

  onMarkerClick = (place, marker, e) => {
    this.setState({
      selectedPlace: place,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  };

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  handleInputChange = (key) => (event) => {
    console.log(key);
    console.log(event.target.value);
    this.setState({ [key]: event.target.value });
  };

  // FIXME Put this in a helper class - it gets reused
  addressFromComponents = (comp) => {
    const address = {};

    for (let i = 0; i < comp.length; i++) {
      const obj = comp[i];
      // console.log(obj.types);
      if (obj.types.indexOf('street_number') !== -1) {
        address.street_number = obj.long_name;
      } else if (obj.types.indexOf('route') !== -1) {
        address.street = `${address.street_number} ${obj.long_name}`;
      } else if (obj.types.indexOf('locality') !== -1) {
        address.city = obj.long_name;
      } else if (obj.types.indexOf('administrative_area_level_1') !== -1) {
        address.state = obj.short_name;
      } else if (obj.types.indexOf('postal_code') !== -1) {
        address.zip = obj.short_name;
      } else if (obj.types.indexOf('country') !== -1) {
        address.country = obj.long_name;
      }
    }
    return address;
  };

  towerModal = () => (
    <Modal open={this.state.showTowerModal} onClose={() => this.onCloseModal('tower')}>
      <div className="modal-header">
        <h5 className="modal-title">Add Tower</h5>
      </div>
      <div className="modal-body md">
        {'Center Point'}
        <Autocomplete
          style={{ width: '100%' }}
          className="form-control"
          placeholder="Start typing..."
          value={this.state.selectedTowerCenterPoint}
          onChange={this.handleInputChange('selectedTowerCenterPoint')}
          onPlaceSelected={(place) => {
            console.log(place);

            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            const comp = place.address_components;

            const tower = this.addressFromComponents(comp);
            tower.latitude = lat;
            tower.longitude = lng;

            // TODO Do something with the address object?
          }}
          // types={['establishment']}
          fields={[
            'address_components',
            'geometry.location',
            'place_id',
          ]}
          // componentRestrictions={{country: "us"}}
        />

        <div className="row">
          <div className="col-md-4">
            Radius in Miles
            <input
              className="form-control"
              type="number"
              name="website"
              value={this.state.selectedTowerRadius}
              onChange={this.handleInputChange('selectedTowerRadius')}
            />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-outline-secondary" onClick={() => this.onCloseModal('tower')}>
          {'Close'}
        </button>
        <button type="button" className="btn btn-primary" onClick={() => this.onSaveModal('tower')}>Save</button>
      </div>
    </Modal>
  );

  render() {
    const { topRatedVenues, mostCheckedInVenues } = this.state;

    return (
      <div>
        {/* Container-fluid starts */}
        <Breadcrumb title="Venues" parent="Dashboard" />
        {/* Container-fluid Ends */}

        {/* Container-fluid starts */}
        {/* https://github.com/istarkov/google-map-clustering-example */}
        <div className="container-fluid">
          {/* <BoxRowOne /> */}
          <div className="card height-equal equal-height-lg">
            <div className="card-header">
              <h5>Top Business Posts</h5>
              {/* TODO Include toggle or dropdown to allow data to be displayed for user's first launch vs all time (possibly just both in one, stacked graph) */}
            </div>
            <div className="card-body" style={{ padding: '12px 0px' }}>
              <div style={{ padding: 12 }}>
                {/* TODO Bar graph showing actions taken by feature (add to humidor, smoke now, view venue / checkin, etc) */}
                {/* TODO May also want a pie graph to the right of the bar graph */}
                <div style={{ textAlign: 'center' }}>Coming soon. Displays the top business posts by engagement</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h5>Active Venues</h5>
                  <button
                    className="btn btn-primary mr-2"
                    onClick={() => this.props.history.push('/venues/manager')}
                    style={{
                      float: 'right',
                      marginTop: -30,
                    }}
                  >
                    {isMobile ? (<i className="icon icon-plus" />) : 'Add Venue'}
                  </button>
                </div>
                <div id="gmap-simple" className="map-block" style={{ height: 350 }}>
                  <ClusterMap
                    zoom={this.state.zoom}
                    center={this.state.mapCenter}
                    locations={this.state.venues}
                    icon={{
                      url: Placeholder.location.marker,
                      // https://stackoverflow.com/questions/12093327/how-to-align-the-icon-of-a-marker-in-google-map
                      size: new window.google.maps.Size(42, 42),
                      origin: new window.google.maps.Point(0, 0),
                      anchor: new window.google.maps.Point(42, 42),
                      scaledSize: new window.google.maps.Size(42, 42),
                    }}
                    onCenterChanged={(newCenter) => {
                      this.setState({ mapCenter: JSON.parse(JSON.stringify(newCenter)) }, () => {
                        this.updateVenues();
                      });
                    }}
                    onZoomChanged={(newZoom) => {
                      this.setState({ zoom: newZoom });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h5>Popular</h5>
                  {/* <button className="btn btn-primary mr-2" onClick={() => this.onOpenModal('tower')} style={{ float: 'right', marginTop: -30 }}> */}
                  {/*  {isMobile ? (<i className="icon icon-plus" />) : 'Add Tower'} */}
                  {/* </button> */}
                  <div>Recent Checkins By Location</div>
                </div>
                <div id="gmap-simple" className="map-block" style={{ height: 350 }}>
                  {/* TODO Include data for the recent checkins for a given period of time (or all time) */}
                  <ClusterMap zoom={4} center={centerCoords} locations={this.state.checkins} />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="card height-equal equal-height-lg">
                <div className="card-header">
                  <h5>Top Rated</h5>
                </div>
                <div className="card-body" style={{ padding: '12px 0px' }}>
                  <div
                    style={{
                      height: 486,
                      overflow: 'auto',
                    }}
                  >
                    <table className="table table-bordernone">
                      <thead>
                        <tr>
                          <th scope="col" className="pt-0" style={{ borderTop: 'none' }}>Name</th>
                          <th scope="col" className="pt-0" style={{ borderTop: 'none' }}>Rating</th>
                          <th scope="col" className="pt-0" style={{ borderTop: 'none' }}>Total Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {topRatedVenues.map((venue) => (
                          <tr>
                            <td><a href={`${config.appEndPoint}/venues/${hashids.encode(venue.id)}`}>{venue.name}</a></td>
                            <td className="digits">{venue.avg_rating}</td>
                            <td className="digits">{venue.total_ratings}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {topRatedVenues.length === 0 && (
                      <div
                        style={{
                          textAlign: 'center',
                          height: 450,
                          paddingTop: 200,
                        }}
                      >
                        {'No data available'}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="card height-equal equal-height-lg">
                <div className="card-header">
                  <h5>Most Checked In</h5>
                </div>
                <div className="card-body" style={{ padding: '12px 0px' }}>
                  <div
                    style={{
                      height: 486,
                      overflow: 'auto',
                    }}
                  >
                    <table className="table table-bordernone">
                      <thead>
                        <tr>
                          <th scope="col" className="pt-0" style={{ borderTop: 'none' }}>Name</th>
                          <th scope="col" className="pt-0" style={{ borderTop: 'none' }}>Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mostCheckedInVenues.map((venue) => (
                          <tr>
                            <td><a href={`${config.appEndPoint}/venues/${hashids.encode(venue.id)}`}>{venue.name}</a></td>
                            <td className="digits">{venue.count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {mostCheckedInVenues.length === 0 && (
                      <div
                        style={{
                          textAlign: 'center',
                          height: 450,
                          paddingTop: 200,
                        }}
                      >
                        {'No data available'}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.towerModal()}

        {/* Container-fluid Ends */}
      </div>
    );
  }
}

export default withRouter(VenueDashboard);
