import React, { Component } from 'react';
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import CountUp from 'react-countup';

class BoxRowOne extends Component {
  // FIXME This scale needs to be based on the dates - not just points
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-xl-3 col-sm-6 col-12 xl-50">
            <div className="card">
              <div className="ecommerce-widget card-body">
                <div className="row">
                  <div className="col-8">
                    <h4 className="total-num counter"><CountUp end={this.props.data.totalOrders.reduce((a, b) => parseInt(a) + parseInt(b), 0)} /></h4>
                    <span>Total Orders</span>
                  </div>
                  <div className="col-4">
                    <div className="icon text-md-right">
                      <i className="icon-package"></i>
                    </div>
                  </div>
                </div>
                <div className="flot-chart-container">
                  <Sparklines data={this.props.data.totalOrders} margin={6}>
                    <SparklinesLine style={{ strokeWidth: 3, stroke: '#336aff', fill: 'none' }} />
                    <SparklinesSpots size={3} style={{ stroke: '#336aff', strokeWidth: 2, fill: 'white' }} />
                  </Sparklines>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12 xl-50">
            <div className="card">
              <div className="ecommerce-widget card-body">
                <div className="row">
                  <div className="col-8">
                    <h4 className="total-num">
                      <span className="counter">
                        <CountUp end={this.props.data.abandonedCarts.reduce((a, b) => parseInt(a) + parseInt(b), 0)} />
                      </span>
                    </h4>
                    <span>Abandoned Carts</span>
                  </div>
                  <div className="col-4">
                    <div className="icon text-md-right">
                      <i className="icon-shopping-cart"></i>
                    </div>
                  </div>
                </div>
                <div className="flot-chart-container">
                  <Sparklines data={this.props.data.abandonedCarts} margin={6}>
                    <SparklinesLine style={{ strokeWidth: 3, stroke: '#FF5370', fill: 'none' }} />
                    <SparklinesSpots size={3} style={{ stroke: '#FF5370', strokeWidth: 2, fill: 'white' }} />
                  </Sparklines>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12 xl-50">
            <div className="card">
              <div className="ecommerce-widget card-body">
                <div className="row">
                  <div className="col-8">
                    <h4 className="total-num">
                      <span className="counter"><CountUp end={this.props.data.totalVisitors.reduce((a, b) => parseInt(a) + parseInt(b), 0)} /></span>
                    </h4>
                    <span>Total Visitors</span>
                  </div>
                  <div className="col-4">
                    <div className="icon text-md-right">
                      <i className="icon-user"></i>
                    </div>
                  </div>
                </div>
                <div className="flot-chart-container">
                  <Sparklines data={this.props.data.totalVisitors} margin={6}>
                    <SparklinesLine style={{ strokeWidth: 3, stroke: '#ab8ce4', fill: 'none' }} />
                    <SparklinesSpots size={3} style={{ stroke: '#ab8ce4', strokeWidth: 2, fill: 'white' }} />
                  </Sparklines>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12 xl-50">
            <div className="card">
              <div className="ecommerce-widget card-body">
                <div className="row">
                  <div className="col-8">
                    <h4 className="total-num">
                      <span className="counter">
                        $<CountUp end={this.props.data.totalSales.reduce((a, b) => parseInt(a) + parseInt(b), 0)} />
                      </span>
                    </h4>
                    {/* FIXME I would prefer to see net profit here - not the total sales*/}
                    {/*<span>Net Profit</span>*/}
                    <span>Total Sales</span>
                  </div>
                  <div className="col-4">
                    <div className="icon text-md-right">
                      <i className="icon-money"></i>
                    </div>
                  </div>
                </div>
                <div className="flot-chart-container">
                  <Sparklines data={this.props.data.totalSales} margin={6}>
                    <SparklinesLine style={{ strokeWidth: 3, stroke: '#26c6da', fill: 'none' }} />
                    <SparklinesSpots size={3} style={{ stroke: '#26c6da', strokeWidth: 2, fill: 'white' }} />
                  </Sparklines>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    );
  }
}

export default BoxRowOne;
