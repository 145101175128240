import axios from 'axios';
import {
  GET_ABANDONED_CARTS,
  GET_ALL_ORDERS,
  GET_ALL_PRODUCTS,
  GET_DISCOUNTS,
  GET_SHIPPING_RATES,
  GET_TAX_RATES,
  POST_PRODUCT,
  PUT_PRODUCT,
} from './actionTypes';
import { Constants } from '../../constants';

export const getAllProducts = (page, limit, query, filters = {}) => (dispatch) => {
  axios.get(`${Constants.apiPath}/shop/products/datatables/all`, {
    params: {
      page,
      limit,
      q: query,
      ...filters,
      in_stock_only: filters.in_stock_only || filters.no_issues || null,
      // out_of_stock_only: filters.out_of_stock_only,
      // no_issues_only: filters.no_issues_only,
      // shop_id: filters.shop_id,
      // sort: filters.sort,
      // direction: filters.order,
    },
  }).then((res) => {
    console.log(res);
    console.log('Products:');
    console.log(res.data.data);
    // console.log(res.data.recordsFiltered);
    dispatch({
      type: GET_ALL_PRODUCTS,
      payload: {
        data: res.data.data,
        totalSize: res.data.recordsTotal,
      },
    });
  }).catch((e) => {
    console.log(e);
    dispatch({
      type: GET_ALL_PRODUCTS,
      payload: {
        data: [],
        totalSize: 0,
        error: e,
      },
    });
  });
};

export const getAllOrders = (page, limit, filters = {}) => (dispatch) => {
  axios.get(`${Constants.apiPath}/shop/orders/datatables/all`, {
    params: {
      page,
      limit,
    },
  }).then((res) => {
    console.log(res);
    console.log('Products:');
    console.log(res.data.items);
    // console.log(res.data.recordsFiltered);
    dispatch({
      type: GET_ALL_ORDERS,
      payload: {
        data: res.data.data,
        totalSize: res.data.recordsTotal,
      },
    });
  }).catch((e) => {
    console.log(e);
    dispatch({
      type: GET_ALL_ORDERS,
      payload: {
        data: [],
        totalSize: 0,
        error: e,
      },
    });
  });
};

export const getAllAbandonedCarts = (page, limit, filters = {}) => (dispatch) => {
  axios.get(`${Constants.apiPath}/shop/carts/datatables/all/abandoned`, {
    params: {
      page,
      limit,
    },
  }).then((res) => {
    console.log(res);
    console.log('Products:');
    console.log(res.data.items);
    // console.log(res.data.recordsFiltered);
    dispatch({
      type: GET_ABANDONED_CARTS,
      payload: {
        data: res.data.data,
        totalSize: res.data.recordsTotal,
      },
    });
  }).catch((e) => {
    console.log(e);
    dispatch({
      type: GET_ABANDONED_CARTS,
      payload: {
        data: [],
        totalSize: 0,
        error: e,
      },
    });
  });
};

export const getDiscounts = (page, limit, filters = {}) => (dispatch) => {
  axios.get(`${Constants.apiPath}/shop/discounts/datatables/all`, {
    params: {
      page,
      limit,
    },
  }).then((res) => {
    console.log(res);
    console.log('Discounts:');
    console.log(res.data.items);
    // console.log(res.data.recordsFiltered);
    dispatch({
      type: GET_DISCOUNTS,
      payload: {
        data: res.data.data,
        totalSize: res.data.recordsTotal,
      },
    });
  }).catch((e) => {
    console.log(e);
    dispatch({
      type: GET_DISCOUNTS,
      payload: {
        data: [],
        totalSize: 0,
        error: e,
      },
    });
  });
};

export const getShippingRates = (page, limit, filters = {}) => (dispatch) => {
  axios.get(`${Constants.apiPath}/shop/shipping/datatables/all`, {
    params: {
      page,
      limit,
    },
  }).then((res) => {
    console.log(res);
    console.log('Shipping Rates:');
    console.log(res.data.items);
    // console.log(res.data.recordsFiltered);
    dispatch({
      type: GET_SHIPPING_RATES,
      payload: {
        data: res.data.data,
        totalSize: res.data.recordsTotal,
      },
    });
  }).catch((e) => {
    console.log(e);
    dispatch({
      type: GET_SHIPPING_RATES,
      payload: {
        data: [],
        totalSize: 0,
        error: e,
      },
    });
  });
};

export const getTaxRates = (page, limit, filters = {}) => (dispatch) => {
  axios.get(`${Constants.apiPath}/shop/taxes/datatables/all`, {
    params: {
      page,
      limit,
    },
  }).then((res) => {
    console.log(res);
    console.log('Tax Rates:');
    console.log(res.data.items);
    // console.log(res.data.recordsFiltered);
    dispatch({
      type: GET_TAX_RATES,
      payload: {
        data: res.data.data,
        totalSize: res.data.recordsTotal,
      },
    });
  }).catch((e) => {
    console.log(e);
    dispatch({
      type: GET_TAX_RATES,
      payload: {
        data: [],
        totalSize: 0,
        error: e,
      },
    });
  });
};

export const postProduct = (product) => (dispatch) => new Promise((resolve, reject) => {
  axios.post(`${Constants.apiPath}/shop/products`, product).then((res) => {
    dispatch({
      type: POST_PRODUCT,
      payload: {
        data: res.data,
      },
    });
    resolve(res.data);
  }).catch((e) => {
    console.log(e);
    reject(e);
  });
});

export const putProduct = (product) => (dispatch) => new Promise((resolve, reject) => {
  axios.put(`${Constants.apiPath}/shop/products/${product.id}`, product).then((res) => {
    dispatch({
      type: PUT_PRODUCT,
      payload: {
        data: res.data,
      },
    });
    resolve(res.data);
  }).catch((e) => {
    console.log(e);
    reject(e);
  });
});
