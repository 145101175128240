import React, {Component} from 'react';
import {Link} from "react-router-dom";

// Import authService

class Notification extends Component {

  render() {

    return (
      <li className="onhover-dropdown">
        <a className="txt-dark">
          <img className="align-self-center pull-right mr-2"
               src={require("../../../assets/images/dashboard/notification.png")} alt="header-notification"/>
          <span className="badge badge-pill badge-primary notification">3</span>
        </a>
        <ul className="notification-dropdown onhover-show-div">
          <li>Notification <span
            className="badge badge-pill badge-secondary text-white text-uppercase pull-right">3 New</span></li>
          <li>
            <div className="media">
              <div className="image-circle" style={{
                height: 50, width: 50, borderRadius: 25, marginRight: 20,
                backgroundImage: "url('https://www.sigaro.io/api/targets/cigars/pending/images/-52565271681--95625368460-1549496349493.jpg')",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
              }}/>
              <div className="media-body">
                <h6 className="mt-0">John is waiting on a match</h6>
                <span><i className="icofont icofont-clock-time p-r-5"></i>Just Now</span>
              </div>
            </div>
          </li>
          <li>
            <div className="media">
              <div className="image-circle" style={{
                height: 50, width: 50, borderRadius: 25, marginRight: 20,
                backgroundImage: "url('https://www.sigaro.io/api/targets/cigars/pending/images/92928841509--71469482172-1559953389078.jpg')",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
              }}/>
              <div className="media-body">
                <h6 className="mt-0">Melissa is waiting on a match</h6>
                <span><i className="icofont icofont-clock-time p-r-5"></i>5 minutes ago</span>
              </div>
            </div>
          </li>
          <li>
            <div className="media">
              <div className="image-circle" style={{
                height: 50, width: 50, borderRadius: 25, marginRight: 20,
                backgroundImage: "url('https://www.sigaro.io/api/targets/cigars/pending/images/-81580183824--36183894966-1557801482425.jpg')",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
              }}/>
              <div className="media-body">
                <h6 className="mt-0">Marc is waiting on a match</h6>
                <span><i className="icofont icofont-clock-time p-r-5"></i>25 minutes ago</span>
              </div>
            </div>
          </li>
          <li className="text-center"><Link to={`${process.env.PUBLIC_URL}/notifications`}>View all notifications</Link>
          </li>
        </ul>
      </li>
    );
  }
}

export default Notification
