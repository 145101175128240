import {
  GET_ALL,
  SEARCH_CIGARS,
  GET_BRANDS,
  SEARCH_BRANDS,
  GET_MANUFACTURERS,
  GET_ORIGINS,
  GET_WRAPPERS,
  GET_BINDERS,
  GET_FILLERS,
  GET_PENDING,
  GET_MATCHES,
  ADD_VITOLAS,
  POST_CIGAR,
  PUT_CIGAR,
  SCRAPE_CIGAR_URL,
  GET_MATCHES_NOT_FOUND,
} from '../actions/actionTypes';

const initialState = {
  cigars: [],
  cigarSearch: [],
  totalSize: 0,
  loading: true,
  loadingBrands: true,
  pendingCigars: [],
  totalPendingCigars: 0,
  pendingMatches: [],
  totalPendingMatches: 0,
  matchIdNotFound: false,
  brands: [],
  brandSearch: [],
  manufacturers: [],
  origins: [
    'Cuba',
    'Dominican Republic',
    'Honduras',
    'Nicaragua',
  ],
  wrappers: [
    'Cuban',
    'Dominican',
    'Honduran',
    'Nicaraguan',
  ],
  binders: [
    'Cuban',
    'Dominican',
    'Honduran',
    'Nicaraguan',
  ],
  fillers: [
    'Cuban',
    'Dominican',
    'Honduran',
    'Nicaraguan',
  ],
};

export default (state = initialState, action) => {
  // console.log("Getting state for type: " + action.type);
  switch (action.type) {
    case GET_ALL:
      return { ...state, loading: false, cigars: action.payload.data, totalSize: action.payload.totalSize };
    case SEARCH_CIGARS:
      return { ...state, loading: false, cigarSearch: action.payload.data };
    case GET_BRANDS:
      return { ...state, loadingBrands: false, brands: action.payload.data };
    case GET_MANUFACTURERS:
      return { ...state, loading: false, manufacturers: action.payload.data };
    case GET_ORIGINS:
      return { ...state, loading: false, origins: action.payload.data };
    case GET_WRAPPERS:
      return { ...state, loading: false, wrappers: action.payload.data };
    case GET_BINDERS:
      return { ...state, loading: false, binders: action.payload.data };
    case GET_FILLERS:
      return { ...state, loading: false, fillers: action.payload.data };
    case GET_PENDING:
      return { ...state, loading: false, pendingCigars: action.payload.data, totalPendingCigars: action.payload.totalSize };
    case GET_MATCHES:
      return { ...state, loading: false, pendingMatches: action.payload.data, totalPendingMatches: action.payload.totalSize };
    case GET_MATCHES_NOT_FOUND:
      return { ...state, loading: false, matchIdNotFound: true };
    case POST_CIGAR:
      return { ...state, loading: false };
    case PUT_CIGAR:
      return { ...state, loading: false };
    case ADD_VITOLAS:
      return { ...state, loading: false };
    case SCRAPE_CIGAR_URL:
      return { ...state, loading: false, scrapedCigar: action.payload.data };
    default:
      return initialState;
  }
};
