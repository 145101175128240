import React, { Component } from 'react';
import axios from 'axios';
import { Table } from 'reactstrap';
import Breadcrumb from '../../components/common/breadcrumb.component';
import BoxRowOne from './box-row-one.component';
import { Constants } from '../../constants';

class ShopDashboard extends Component {
  constructor(props) {
    super(props);
    axios.get(`${Constants.apiPath}/shop/dashboard`).then((response) => {
      const { data } = response;
      console.log(data);
      this.setState({
        abandonedCarts: data.abandoned_carts,
        mostViewedProducts: data.most_viewed_products,
        topRatedProducts: data.top_rated_products,
        topSellingProducts: data.top_selling_products,
        totalOrders: data.total_orders,
        totalSales: data.total_sales,
        totalVisitors: data.total_visitors,
      });
    });

    this.state = {
      abandonedCarts: [],
      mostViewedProducts: [],
      topRatedProducts: [],
      topSellingProducts: [],
      totalOrders: [],
      totalSales: [],
      totalVisitors: [],
    };
  }

  render() {
    const { totalOrders, abandonedCarts, totalVisitors, totalSales } = this.state;

    const data = {
      totalOrders: totalOrders.map((order) => order.count),
      abandonedCarts: abandonedCarts.map((cart) => cart.count),
      totalVisitors: totalVisitors.map((user) => user.count),
      totalSales: totalSales.map((order) => order.sum),
    };

    return (
      <div>
        <Breadcrumb title="Shop" parent="Dashboard" />
        <div className="container-fluid">
          <BoxRowOne data={data} />
        </div>
        {/* Yearly Income chart Start */}
        <div className="container-fluid">
          {/* Customer Product & Review Table Start */}

          <div className="row">
            <div className="col-xl-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Top Selling Products</h5>
                  {/* <span>Our best sellers this week</span> */}
                  <span>Our best sellers</span>
                </div>
                <div className="card-body" style={{ padding: '12px 0px' }}>
                  <div className="table-responsive text-center user-status">
                    <Table className="table table-bordernone">
                      <thead>
                        <tr>
                          <th scope="col">Image</th>
                          <th scope="col">Name</th>
                          <th scope="col">Quantity</th>
                          <th scope="col">Total</th>
                          <th scope="col">Net Profit</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.topSellingProducts.map((product) => (
                          <tr>
                            <td className="bd-t-none" scope="row">
                              {product.images && product.images[0].url && (
                                <img
                                  src={`//${product.images[0].url}`}
                                  style={{ width: 50 }}
                                  alt={product.name}
                                />
                              )}
                            </td>
                            <td>
                              {product.name}
                              {product.packaging && (
                                <span>
                                  - Package type :
                                  <br />
                                  <span>{`${product.packaging.type} of ${product.packaging.quantity}`}</span>
                                </span>
                              )}
                            </td>
                            <td className="digits">{product.count}</td>
                            <td>
$
                              {product.sale_price}
                            </td>
                            <td>N/A</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Customer Product & Review Table Ends */}

          <div className="row">
            <div className="col-xl-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Most Viewed Products</h5>
                  {/* <span>These products were viewed the most this week</span> */}
                  <span>These products were viewed the most</span>
                </div>
                <div className="card-body" style={{ padding: '12px 0px' }}>
                  <div className="table-responsive text-center user-status">
                    <Table className="table table-bordernone">
                      <thead>
                        <tr>
                          <th scope="col">Image</th>
                          <th scope="col">Name</th>
                          <th scope="col">Visits</th>
                          {/* TODO Include this data too - be nice to see well the pages convert to adds and possibly even orders */}
                          {/*<th scope="col">Adds to Cart</th>*/}
                          {/*<th scope="col">Percentage</th>*/}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.mostViewedProducts.map((product) => (
                          <tr>
                            <td className="bd-t-none" scope="row">
                              {product.images && product.images[0].url && (
                                <img
                                  src={`//${product.images[0].url}`}
                                  style={{ width: 50 }}
                                  alt={product.name}
                                />
                              )}
                            </td>
                            <td>{product.name}</td>
                            <td className="digits">{product.count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default ShopDashboard;
