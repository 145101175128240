import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createStyles } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import { darken, fade, lighten } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  text: (props) => {
    // FIXME Why is theme undefined here sometimes?
    // https://github.com/mui-org/material-ui/issues/13875#issuecomment-526491817
    if (theme.palette[props.color]) {
      const color = theme.palette[props.color].main;
      return {
        color,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: fade(color, theme.palette.action.hoverOpacity),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
      };
    }
  },
  outlined: (props) => {
    if (theme.palette[props.color]) {
      const color = theme.palette[props.color].main;
      return {
        color,
        backgroundColor: 'transparent', // lighten(color, 0.935),
        '&:hover': {
          backgroundColor: fade(color, theme.palette.action.hoverOpacity),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
        borderColor: color,
      };
    }
  },
  contained: (props) => {
    console.log('Theme');
    console.log(theme);
    if (theme.palette[props.color]) {
      const color = theme.palette[props.color].main;
      return {
        color: theme.palette[props.color].contrastText,
        backgroundColor: color,
        '&:hover': {
          backgroundColor: darken(color, 0.125),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
      };
    }
  },
}));

export default function Button(props) {
  const classes = useStyles(props);
  return <MuiButton classes={classes} {...props} />;
}
