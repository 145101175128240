import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import AsyncSelect from 'react-select/async';
import Modal from 'react-responsive-modal';
import { connect } from 'react-redux';
import { Input, Label, Tooltip, UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  getPendingVenues,
} from '../../redux/actions/venue.actions';
import Breadcrumb from '../../components/common/breadcrumb.component';
import Icon from '../../components/icon';

const { SearchBar } = Search;

const defaultSorted = [{
  dataField: 'name',
  order: 'asc',
}];

const cellEditProps = {
  mode: 'dbclick',
};

// TODO Remote Search https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Remote&selectedStory=Remote%20Search&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel

const defaults = {
  mergeVenue: '',
};

const NoDataIndication = () => (
  <div className="table-loader">
    <div className="loader">
      <div className="line bg-primary"></div>
      <div className="line bg-primary"></div>
      <div className="line bg-primary"></div>
      <div className="line bg-primary"></div>
      <div className="line bg-primary"></div>
    </div>
  </div>
);

class VenueTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      showModal: false,
      showTooltip: false,
      ...defaults,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

    keyForType = (type) => {
      switch (type) {
        case 'merge':
          return 'showModal';
        default:
          return '';
      }
    };

    onOpenModal = (type) => {
      this.setState({ [this.keyForType(type)]: true });
    };

    onCloseModal = (type) => {
      this.setState({
        [this.keyForType(type)]: false,
        ...defaults,
      });
    };

    onSaveModal = (type) => {
      console.log('Submitting!');
      // TODO axios submit + loading, then finish
      this.setState({
        [this.keyForType(type)]: false,
        ...defaults,
      });
    };

    imageFormatter = (cell, row) => (
      <img style={{ maxWidth: 75 }} src={cell} />
    );

    handleChange = (key) => (value) => {
      this.setState({ [key]: value });
    };

    checkBoxFormatter = (cell, row) => (
      <div className="checkbox checkbox-secondary">
        <input name="checkbox" checked={cell} type="checkbox" />
        <label></label>
      </div>
    );

    viewBtnFormatter = (cell, row) => {
      if (cell && cell.indexOf('href') !== -1) {
        const href = cell.match(/href="([^"]*)/)[1];
        if (href) {
          const id = href.replace('venue-manager.jsp?id=', '');
          return (
            <Link to={`${process.env.PUBLIC_URL}/manager/venues?id=${id}`}>View</Link>
          );
        }
      }
      return (<span></span>);
    };

    mergeBtnFormatter = (cell, row, index) =>
    // TODO Set the active row so we have the ID to merge
      (
        <div style={{ width: 50, textAlign: 'right' }}>
          <div id={`actions-${index}`} className="d-none">
            <Icon id={`merge-${index}`} name="git-merge" style={{ height: 18, margin: 0, padding: 0, cursor: 'pointer' }} onClick={() => this.onOpenModal('merge')} />
            <UncontrolledTooltip placement="bottom" target={`merge-${index}`} trigger="hover">Merge</UncontrolledTooltip>
            <Icon name="trash" style={{ color: '#FF5370', height: 18, margin: 0, padding: 0, cursor: 'pointer' }} onClick={() => alert('Coming soon!')} />
          </div>
        </div>
      )
    ;

    handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit }) => {
      this.props.getPendingVenues(page, sizePerPage);
    };

    toggleTooltip() {
      this.setState({
        showTooltip: !this.state.showTooltip,
      });
    }

    render() {
      const { sizePerPage, page } = this.state;
      const { pendingVenues, totalPendingVenues } = this.props.VenueManager;

      console.log('Pending Venues:');
      console.log(pendingVenues);

      const columns = [{
        dataField: 'image-full',
        text: 'Image',
        formatter: this.imageFormatter,
        sort: false,
      }, {
        dataField: 'name',
        text: 'Name',
        sort: true,
      }, {
        dataField: 'website',
        text: 'Website',
        sort: true,
      }, {
        dataField: 'location.formatted_address',
        text: 'Address',
        sort: true,
      }, {
        dataField: 'custom',
        text: 'Custom',
        formatter: this.checkBoxFormatter,
        sort: true,
      }, {
        dataField: 'accept',
        text: '',
        formatter: this.mergeBtnFormatter,
        sort: true,
      }];

      const rowEvents = {
        onMouseEnter: (e, row, index) => {
          // console.log("Mouse entered: " + index);
          document.querySelector(`#actions-${index}`).classList.remove('d-none');
        },
        onMouseLeave: (e, row, index) => {
          document.querySelector(`#actions-${index}`).classList.add('d-none');
        },
        onDoubleClick: (e, row, index) => {
          // INFO If we don't include this event, the double click to edit doesn't work
          // console.log(e);
        },
      };

      const pageButtonRenderer = ({ page, active, onPageChange }) => {
        const handleClick = (e) => {
          e.preventDefault();
          onPageChange(page);
        };
        let classname = 'btn btn-outline-secondary';
        if (active) {
          classname = 'btn btn-secondary';
        }
        return (
          <li className="page-item pl-1" key={page}>
            <a href="#" onClick={handleClick} className={classname}>{ page }</a>
          </li>
        );
      };

      // TODO https://www.npmjs.com/package/react-device-detect change for mobile
      return (
        <div>
          <Breadcrumb title="Pending Venues" label="Pending" parent="Venues" />

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card mb-0">
                  <div className="card-body datatable-react">
                    <ToolkitProvider
                      keyField="id"
                      data={pendingVenues}
                      columns={columns}
                      search
                    >
                      {
                        (toolkitprops) => (
                          <div>
                            <SearchBar {...toolkitprops.searchProps} />
                            {/* <ClearSearchButton { ...props.searchProps } /> */}
                            <BootstrapTable
                              remote
                              keyField="id"
                              data={pendingVenues}
                              columns={columns}
                              defaultSorted={defaultSorted}
                              rowEvents={rowEvents}
                              wrapperClasses="table-responsive"
                              pagination={paginationFactory({ pageButtonRenderer, page, sizePerPage, totalSize: totalPendingVenues })}
                              cellEdit={cellEditFactory(cellEditProps)}
                              onTableChange={this.handleTableChange}
                              noDataIndication={() => <NoDataIndication />}
                              {...toolkitprops.baseProps}
                            />

                            { this.mergeModal() }
                          </div>
                        )
                      }
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    mergeModal = () => (
      <Modal open={this.state.showModal} onClose={() => this.onCloseModal('merge')}>
        <div className="modal-header">
          <h5 className="modal-title">Merge Venue</h5>
        </div>
        {/* INFO We can control the size on this div tag */}
        <div className="modal-body">
          {'Select Venue to merge:'}
          <AsyncSelect
            value={this.state.mergeVenue}
            onChange={this.handleChange('mergeVenue')}
            getOptionLabel={(option) => `${option.label}`}
            getOptionValue={(option) => `${option}`}
            isOptionSelected={(option) => this.state.mergeVenue.value === option.value}
          />
          <div className="checkbox checkbox-primary ml-3">
            <Input name="force" id="force-cb" type="checkbox" checked={this.state.selectedCanSmoke} />
            <Label className="mb-0" htmlFor="force-cb">
              {'Force into active venue?'}
              <i className="icon-info-alt" id="forceTooltip" />
            </Label>
          </div>
          <Tooltip placement="top" isOpen={this.state.showTooltip} target="forceTooltip" toggle={this.toggleTooltip}>
            {'This will force the venue from the select field above into the venue you are actively editing.'}
          </Tooltip>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-outline-secondary" onClick={() => this.onCloseModal('merge')}>Close</button>
          <button type="button" className="btn btn-primary" onClick={() => this.onSaveModal('merge')}>Merge</button>
        </div>
      </Modal>
    );
}

const mapStateToProps = ({ VenueManager }) => ({ VenueManager });

export default connect(
  mapStateToProps, {
    getPendingVenues,
  }
)(VenueTable);
