import axios from 'axios';
import {
  GET_ALL_DRINKS,
} from './actionTypes';
import { Constants } from '../../constants';

export const getAllDrinks = (page, limit, query, drinkId) => (dispatch) => {
  axios.get(`${Constants.apiPath}/cigars/datatables/pairings`, {
    params: {
      page,
      limit,
      q: query,
    },
  }).then((res) => {
    dispatch({
      type: GET_ALL_DRINKS,
      payload: {
        data: res.data.data,
        totalSize: res.data.recordsFiltered,
      },
    });
  }).catch((e) => {
    console.log(e.message);
  });
};
