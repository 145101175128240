import React from 'react';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { Modal, UncontrolledTooltip } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import SweetAlert from 'react-bootstrap-sweetalert';
import RowMoreOptions from '../../components/table/row-more-options';
import '../../assets/css/linked-cigars.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import TableLoader from '../../components/table-loader';
import NoDataIndicator from '../../components/no-data-indicator';
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import { getTaxRates } from '../../redux/actions/shop.actions';
import Breadcrumb from '../../components/common/breadcrumb.component';
import { countries, usStates } from '../../utils/geographyUtils';
import { Constants } from '../../constants';

const { SearchBar } = Search;

const defaultSorted = [{
  dataField: 'name',
  order: 'asc',
}];

const cellEditProps = {
  mode: 'dbclick',
};

const defaults = {
  name: null,
  rate: null,
  state: null,
  country: null,
  message: {
    type: 'info',
    show: false,
    title: '',
    text: '',
  },
};

class TaxTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      // data: data.data,
      // totalSize: data.recordsFiltered,
      sizePerPage: 10,
      showCreateTaxModal: false,
      ...defaults,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  componentDidMount() {
    this.handleTableChange(null, { page: 1, sizePerPage: 30 });
  }

  toggleTooltip(key) {
    return () => {
      this.setState({
        [key]: !this.state[key],
      });
    };
  }

    handleChange = (key) => (e) => {
      this.setState({ [key]: e.target.value });
    };

    handleProductChange = (key) => (event) => {
      const product = this.state.selectedProduct;
      product[key] = event.target.value;
      this.setState({ selectedProduct: product });
    };

    handleEditorStateChange = (editorState) => {
      this.setState({
        editorState,
      });
    };

    handleInputChange = (key) => (event) => {
      console.log(key);
      console.log(event.target.value);
      this.setState({ [key]: event.target.value });
    };

    handleAddressChange = (key) => (event) => {
      console.log(key);
      console.log(event.target.value);
      // FIXME Should update locally - not in the actual Snipcart store
      // if (this.state.editingShippingAddress) {
      //     this.setState({
      //         shippingAddress: {
      //             ...this.state.shippingAddress,
      //             [key]: event.target.value
      //         }
      //     });
      // } else {
      //     this.setState({
      //         billingAddress: {
      //             ...this.state.billingAddress,
      //             [key]: event.target.value
      //         }
      //     });
      // }
    };

    handleSelectChange = (key) => (value) => {
      this.setState({ [key]: value });
    };

    handleCheckboxChange = (key) => {
      console.log('Check change');
      return (event) => {
        this.setState({ [key]: event.target.checked });
      };
    };

    handleRadioChange = (key) => (event) => {
      this.setState({ [key]: event.target.value });
    };

    handleLinkedCigarChange = (index, type) => {
      const { linkedCigars } = this.state;
      console.log(linkedCigars);
      const linkedCigar = linkedCigars[index] || {};
      return (value) => {
        linkedCigar[type] = value;
        linkedCigars[index] = linkedCigar;
        this.setState({ linkedCigars }, () => {
          console.log('State updated!');
          if (type === 'cigar') {
            console.log('Running this.loadVitolas(index)...');
            this.loadVitolas(index);
          }
        });
      };
    };

    keyForType = (type) => {
      switch (type) {
        case 'tax':
          return 'showCreateTaxModal';
        default:
          return '';
      }
    };

    onCloseModal = (type) => {
      this.setState({
        [this.keyForType(type)]: false,
      });
    };

    onOpenModal = (type, row) => {
      this.setState({ [this.keyForType(type)]: true });
    };

    onSaveModal = (type) => {
      switch (type) {
        case 'tax': {
          const { name, rate, selectedState, selectedCountry } = this.state;
          axios.post(`${Constants.apiPath}/shop/taxes`, {
            name,
            rate,
            country: selectedCountry.value,
            state: selectedState.value,
          }).then(() => {
            this.onCloseModal('tax');
            // TODO Reset the defaults
          }).catch((err) => {
            console.log(err);
          });
        }
          break;
        default:
          this.onCloseModal(type);
          break;
      }
    };

    itemFormatter = (items, row) => {
      if (items) {
        return (
          <label>
            {items.map((item) => <p>{`${item.quantity}x ${item.name}`}</p>)}
          </label>
        );
      }
      return null;
    };

    itemQuantityFormatter = (items, row) => {
      if (items) {
        return (
          <label>{items.length}</label>
        );
      }
      return null;
    };

    priceFormatter = (cell, row) => {
      if (cell) {
        let price = cell;
        if (typeof price === 'number' || (typeof price === 'string' && price.indexOf('$') === -1)) {
          // FIXME This should be fixed on the server, but since the dev db and prod db aren't configured the same, we need this. Once configured correctly, remove this
          price = `$${parseFloat(cell).toFixed(2)}`;
        }
        return (
          <label>{price}</label>
        );
      }
      return null;
    };

    checkBoxFormatter = (cell, row) => (
      <div className="checkbox checkbox-secondary">
        <input name="checkbox" checked={cell} type="checkbox" />
        <label></label>
      </div>
    );

    actionDropdown = (index, product) => (
      <RowMoreOptions
        row={index}
        items={[{
          type: 'MenuItem',
          onClick: () => {},
          title: 'TODO',
        }]}
        onShow={() => {
        // Hide the row buttons
          document.querySelector(`#dropdown-actions-${index}`).classList.add('d-none');
        }}
      />
    );

    editBtnFormatter = (cell, row, index) => {
      console.log(`Index rendered: ${index}`);
      return (
        <div style={{ width: 68, float: 'right' }}>
          <div id={`dropdown-actions-${index}`} className="d-none">
            <i className="icon-pencil ml-1 mr-3" style={{ cursor: 'pointer' }} onClick={() => {}} />
            {this.actionDropdown(index, row)}
          </div>
        </div>
      );
    };

    handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit, searchText }) => {
      console.log('Updating table...');
      this.props.getTaxRates(page, sizePerPage, {
        // TODO Filter options
      });

      this.setState({
        page,
        sizePerPage,
      });
    };

    closeAlert = () => {
      this.setState({
        message: {
          show: false,
          title: '',
          text: '',
        },
      });
    };

    newTaxModal = () => {
      const { selectedCountry, selectedState } = this.state;
      return (
        <Modal isOpen={this.state.showCreateTaxModal} onClosed={() => this.onCloseModal('tax')}>
          <div className="modal-header">
            <h5 className="modal-title">Create New Tax</h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-6">
                <span style={{ fontWeight: 700 }}>Name</span>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  <input
                    className="form-control"
                    value={this.state.name}
                    style={{ textTransform: 'capitalize' }}
                    onChange={this.handleChange('name')}
                  />
                </div>
                <span style={{ fontWeight: 700 }}>Country</span>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  <Select
                    value={selectedCountry}
                    onChange={this.handleSelectChange('selectedCountry')}
                    options={countries.map((country) => ({
                      value: country.abbreviation,
                      label: country.name,
                    }))}
                    getOptionLabel={(option) => `${option.label}`}
                    getOptionValue={(option) => `${option}`}
                    isOptionSelected={(option) => selectedCountry && (selectedCountry.value === option.value)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <span style={{ fontWeight: 700 }}>Rate</span>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  <input
                    className="form-control"
                    placeholder="0.06"
                    value={this.state.rate}
                    style={{ textTransform: 'capitalize' }}
                    onChange={this.handleChange('rate')}
                  />
                </div>
                <span style={{ fontWeight: 700 }}>State</span>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  <Select
                    value={selectedState}
                    onChange={this.handleSelectChange('selectedState')}
                    options={usStates.map((usState) => ({
                      value: usState.abbreviation,
                      label: usState.name,
                    }))}
                    getOptionLabel={(option) => `${option.label}`}
                    getOptionValue={(option) => `${option}`}
                    isOptionSelected={(option) => selectedState && (selectedState.value === option.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Button onClick={() => this.onCloseModal('tax')}>Cancel</Button>
            <Button
              onClick={() => this.onSaveModal('tax')}
              variant="contained"
              color="secondary"
            >
              {'Create'}
            </Button>
          </div>
        </Modal>
      );
    };

    render() {
      const { sizePerPage, page } = this.state;
      const { taxRates, totalTaxRateSize, loadingTaxRates, error } = this.props.ShopManager;

      const columns = [{
        dataField: 'name',
        text: 'Name',
        sort: true,
        editable: false,
      }, {
        dataField: 'country',
        text: 'Country',
        sort: true,
        editable: false,
      }, {
        dataField: 'state',
        text: 'State / Province code',
        sort: true,
        editable: false,
      }, {
        dataField: 'rate',
        text: 'Rate',
        sort: true,
        editable: false,
      }, {
        dataField: 'edit',
        text: '',
        formatter: this.editBtnFormatter,
        sort: true,
      }];

      const rowEvents = {
        onMouseEnter: (e, row, index) => {
          // console.log("Mouse entered: " + index);
          document.querySelector(`#dropdown-actions-${index}`).classList.remove('d-none');
        },
        onMouseLeave: (e, row, index) => {
          document.querySelector(`#dropdown-actions-${index}`).classList.add('d-none');
        },
        onDoubleClick: (e, row, index) => {
          // INFO If we don't include this event, the double click to edit doesn't work
          // console.log(e);
        },
      };

      const pageButtonRenderer = ({ page, active, onPageChange }) => {
        const handleClick = (e) => {
          e.preventDefault();
          onPageChange(page);
        };
        let classname = 'btn btn-outline-secondary';
        if (active) {
          classname = 'btn btn-secondary';
        }
        return (
          <li className="page-item pl-1" key={page}>
            <a href="#" onClick={handleClick} className={classname}>{ page }</a>
          </li>
        );
      };

      // TODO https://www.npmjs.com/package/react-device-detect use a different layout for mobile
      return (
        <div>
          <SweetAlert
            show={this.state.message.show}
            type={this.state.message.type}
            title={this.state.message.title}
            onConfirm={this.closeAlert}
          >
            {this.state.message.text}
          </SweetAlert>

          <Breadcrumb title="Taxes" label="Tax Config" parent="Shop" />

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card mb-0">
                  <div className="card-body datatable-react">
                    <ToolkitProvider
                      keyField="id"
                      data={taxRates}
                      columns={columns}
                      search
                    >
                      {
                        (toolkitprops) => (
                          <div>
                            {/* Known issue https://github.com/react-bootstrap-table/react-bootstrap-table2/issues/787 */}
                            <SearchBar {...toolkitprops.searchProps} delay={800} />
                            {/* <ClearSearchButton { ...props.searchProps } /> */}
                            <button
                              className="btn btn-primary mr-2"
                              onClick={() => {
                                this.onOpenModal('tax');
                              }}
                              style={isMobile ? { padding: '6px 14px', float: 'right' } : { float: 'right' }}
                            >
                              {isMobile ? (<i className="icon icon-plus" />) : 'Create New Tax'}
                            </button>
                            <BootstrapTable
                              remote
                              keyField="id"
                              data={taxRates}
                              columns={columns}
                              rowEvents={rowEvents}
                              defaultSorted={defaultSorted}
                              wrapperClasses="table-responsive"
                              pagination={paginationFactory({ pageButtonRenderer, page, sizePerPage, totalTaxRateSize })}
                              cellEdit={cellEditFactory(cellEditProps)}
                              // filter={ filterFactory() }
                              onTableChange={this.handleTableChange}
                              noDataIndication={() => (!loadingTaxRates ? (error ? <NoDataIndicator message={`${error.message}. Please contact an admin if the problem persists.`} /> : <NoDataIndicator message="No tax rates found." />) : <TableLoader />)}
                              {...toolkitprops.baseProps}
                            />
                          </div>
                        )
                      }
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.newTaxModal()}
        </div>
      );
    }
}

const mapStateToProps = ({ ShopManager }) => ({ ShopManager });

export default connect(
  mapStateToProps, {
    getTaxRates,
  }
)(TaxTable);
