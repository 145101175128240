/* TODO Move this to actions folder */

/* TODO APP */
export const GET_LIST = 'GET_LIST';
export const GET_LIST_SUCCESS = 'GET_LIST_SUCCESS';
export const FILTER_WITH_CATEGORY = 'FILTER_WITH_CATEGORY';
export const FILTER_WITH_LABEL = 'FILTER_WITH_LABEL';
export const FILTER_WITH_STATUS = 'FILTER_WITH_STATUS';
export const MARK_ALL_ITEMS = 'MARK_ALL_ITEMS';
export const ADD_NEW_ITEM = 'ADD_NEW_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const SELECTED_ITEM = 'SELECTED_ITEM';

/* CIGARS */
export const GET_ALL = 'GET_ALL';
export const SEARCH_CIGARS = 'SEARCH_CIGARS';
export const GET_BRANDS = 'GET_BRANDS';
export const SEARCH_BRANDS = 'SEARCH_BRANDS';
export const GET_ALL_BRANDS = 'GET_ALL_BRANDS';
export const GET_MANUFACTURERS = 'GET_MANUFACTURERS';
export const GET_ORIGINS = 'GET_ORIGINS';
export const GET_WRAPPERS = 'GET_WRAPPERS';
export const GET_BINDERS = 'GET_BINDERS';
export const GET_FILLERS = 'GET_FILLERS';
export const GET_PENDING = 'GET_PENDING';
export const GET_MATCHES = 'GET_MATCHES';
export const GET_MATCHES_NOT_FOUND = 'GET_MATCHES_NOT_FOUND';
export const POST_CIGAR = 'POST_CIGAR';
export const PUT_CIGAR = 'PUT_CIGAR';
export const ADD_VITOLAS = 'ADD_VITOLAS';
export const SCRAPE_CIGAR_URL = 'SCRAPE_CIGAR_URL';

/* VENUES */
export const GET_ALL_VENUES = 'GET_ALL_VENUES';
export const SEARCH_VENUES = 'SEARCH_VENUES';
export const GET_PENDING_VENUES = 'GET_PENDING_VENUES';
export const GET_CLAIMS = 'GET_CLAIMS';
export const POST_VENUE = 'POST_VENUE';
export const PUT_VENUE = 'PUT_VENUE';

/* GROUPS */
export const GET_ALL_GROUPS = 'GET_ALL_GROUPS';

/* DRINKS */
export const GET_ALL_DRINKS = 'GET_ALL_DRINKS';

/* EVENTS */
export const GET_ALL_EVENTS = 'GET_ALL_EVENTS';

/* SHOP */
export const UPDATE_CART = 'UPDATE_CART';
export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const POST_PRODUCT = 'POST_PRODUCT';
export const PUT_PRODUCT = 'PUT_PRODUCT';
export const GET_ALL_ORDERS = 'GET_ALL_ORDERS';
export const GET_ABANDONED_CARTS = 'GET_ABANDONED_CARTS';
export const GET_DISCOUNTS = 'GET_DISCOUNTS';
export const GET_SHIPPING_RATES = 'GET_SHIPPING_RATES';
export const GET_TAX_RATES = 'GET_TAX_RATES';

/* CHAT APP */
export const GET_MEMBERS = 'GET_MEMBERS';
export const GET_MEMBERS_SUCCESS = 'GET_MEMBERS_SUCCESS';
export const GET_CHATS = 'GET_CHATS';
export const GET_CHATS_SUCCESS = 'GET_CHATS_SUCCESS';
export const GET_CHATS_ERROR = 'GET_CHATS_ERROR';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEARCH_MEMBER = 'SEARCH_MEMBER';
export const CHANGE_CHAT = 'CHANGE_CHAT';
export const CREATE_CHAT = 'CREATE_CHAT';
export const UPDATE_STATUS = 'UPDATE_STATUS';
