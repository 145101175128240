import {
  GET_ALL_EVENTS,
} from '../actions/actionTypes';

const initialState = {
  events: [],
  totalSize: 0,
  loading: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_EVENTS:
      return { ...state, loading: false, events: action.payload.data, totalEvents: action.payload.totalSize };
    default:
      return initialState;
  }
};
