import { format, parse, differenceInMilliseconds } from 'date-fns';

const oneMin = 60 * 1000;
const oneHr = 60 * oneMin;
const oneDay = 24 * oneHr;
const twoDays = 2 * oneDay;
// const oneWeek = 7 * oneDay;
// const oneMonth = 30 * oneDay;
// const oneYear = 12 * oneMonth;

export function renderTimestamp(timestamp) {
  try {
    let formattedDate = 'Just Now';

    // 2019-11-04T01:09:47 - INFO Need to append the timezone as UTC .000Z to all incoming timestamps
    let strFormat = 'yyyy-MM-dd HH:mm:ss.SSSxxx';
    if (timestamp.indexOf('T') !== -1) {
      strFormat = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx";
    }

    let date;
    if (timestamp.indexOf('Z') === -1) {
      // console.log(`${timestamp}.000Z`);
      date = parse(`${timestamp}.000Z`, strFormat, new Date());
    } else {
      date = parse(timestamp, strFormat, new Date());
    }
    const now = new Date();

    // console.log(date.toISOString());
    // console.log(now.toISOString());

    const diff = differenceInMilliseconds(now, date);
    // console.log(`Difference is ${diff}`);

    // Only do intervals up to 2 days before now.
    if (diff > twoDays) {
      // Show formatted timestamp (mmm dd[, yyyy]) <- only show year if not current
      if (format(date, 'yyyy') === format(now, 'yyyy')) {
        formattedDate = format(date, 'MMM d');
      } else {
        formattedDate = format(date, 'MMM d, yyyy');
      }
    } else if (diff >= oneDay) {
      // Show days
      formattedDate = `${Math.ceil(diff / oneDay)} day ago`;
    } else if (diff >= oneHr) {
      // Show hours
      formattedDate = `${Math.ceil(diff / oneHr)} hr ago`;
    } else if (diff >= oneMin) {
      // Show mins
      formattedDate = `${Math.ceil(diff / oneMin)} min ago`;
    }

    return formattedDate;
  } catch (e) {
    console.log(e);
    return '';
  }
}

export function renderEventTimestamp(timestamp) {
  try {
    let formattedDate;

    // 2019-11-04T01:09:47 - INFO Need to append the timezone as UTC .000Z to all incoming timestamps
    let strFormat = 'yyyy-MM-dd HH:mm:ss.SSSxxx';
    if (timestamp.indexOf('T') !== -1) {
      strFormat = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx";
    }

    let date;
    if (timestamp.indexOf('Z') === -1) {
      // console.log(`${timestamp}.000Z`);
      date = parse(`${timestamp}.000Z`, strFormat, new Date());
    } else {
      date = parse(timestamp, strFormat, new Date());
    }
    const now = new Date();

    // console.log(date.toISOString());
    // console.log(now.toISOString());

    const diff = differenceInMilliseconds(now, date);
    // console.log(`Difference is ${diff}`);

    // TODO Include the time and future dates
    if (diff > twoDays) {
      // Show formatted timestamp (mmm dd[, yyyy]) <- only show year if not current
      if (format(date, 'yyyy') === format(now, 'yyyy')) {
        formattedDate = format(date, 'MMM d');
      } else {
        formattedDate = format(date, 'MMM d, yyyy');
      }
    } else if (diff >= oneDay) {
      // Show days
      formattedDate = `${Math.ceil(diff / oneDay)} day`;
    } else if (diff >= oneHr) {
      // Show hours
      formattedDate = `${Math.ceil(diff / oneHr)} hr`;
    } else if (diff >= oneMin) {
      // Show mins
      formattedDate = `${Math.ceil(diff / oneMin)} min`;
    } else {
      // TODO If in between start and end, use 'Happening Now', otherwise
      // formattedDate = 'Happening Now';
      if (format(date, 'yyyy') === format(now, 'yyyy')) {
        formattedDate = format(date, 'MMM d');
      } else {
        formattedDate = format(date, 'MMM d, yyyy');
      }
    }

    return formattedDate;
  } catch (e) {
    console.log(e);
    return '';
  }
}
