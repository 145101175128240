import './style.scss';
import React, { PureComponent } from 'react';
import classnames from 'classnames/dedupe';
import Dropzone from 'react-dropzone';
import csv from 'csvtojson';
import Icon from '../icon';

function fileSizeToHumanReadable(size) {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / Math.pow(1024, i)).toFixed(2) * 1} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
}

class ComponentDropzone extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
    };

    this.onChange = this.onChange.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }

  onChange() {
    const {
      onChange = () => {},
    } = this.props;
    onChange(this.state.files);
  }

  removeFile(file) {
    const newFiles = [...this.state.files];
    newFiles.splice(newFiles.indexOf(file), 1);
    this.setState({
      files: newFiles,
    }, this.onChange);
  }

  render() {
    // const {
    //   settings,
    // } = this.props;

    return (
      <Dropzone
        onDrop={(newFiles) => {
          this.setState({
            files: [
              ...this.state.files,
              ...newFiles,
            ],
          }, this.onChange);

          newFiles.forEach((file) => {
            console.log(file);
            if (file.type === 'text/csv') {
              const reader = new FileReader();

              reader.onload = () => {
                const fileAsBinaryString = reader.result;

                csv({
                  noheader: true,
                  output: 'json',
                }).fromString(fileAsBinaryString).then((csvRows) => {
                  const toJson = [];
                  csvRows.forEach((aCsvRow, i) => {
                    if (i !== 0) {
                      const builtObject = {};

                      Object.keys(aCsvRow).forEach((aKey) => {
                        const valueToAddInBuiltObject = aCsvRow[aKey];
                        const keyToAddInBuiltObject = csvRows[0][aKey];
                        builtObject[keyToAddInBuiltObject] = valueToAddInBuiltObject;
                      });

                      toJson.push(builtObject);
                    }
                  });

                  const { getJson } = this.props;
                  getJson(toJson);
                });
              };

              reader.onabort = () => console.log('File reading was aborted');
              reader.onerror = () => console.log('File reading has failed');

              reader.readAsText(file, 'ISO-8859-1');
            }
          });
        }}
        // getJson={this.props.getJson}
      >
        { (data) => {
          const rootProps = data.getRootProps();
          const inputProps = data.getInputProps();

          return (
            <div
              className={
                classnames(
                  'rui-dropzone',
                  data.isDragActive ? 'rui-dropzone-is-active' : ''
                )
              }
              {...rootProps}
            >
              <input {...inputProps} />
              { this.state.files && this.state.files.length ? (
                this.state.files.map((fileData) => {
                  const fileExt = fileData.name.split('.').pop();

                  return (
                    <div
                      key={fileData.name}
                      className="rui-dropzone-preview"
                    >
                      {/* <div className="rui-dropzone-image"> */}
                      {/*  {settings && <img src={settings.img_file[fileExt] || settings.img_file.empty} className="icon-file" alt="" />} */}
                      {/* </div> */}
                      <div className="rui-dropzone-details">
                        <div className="rui-dropzone-size"><span>{ fileSizeToHumanReadable(fileData.size) }</span></div>
                        <div className="rui-dropzone-filename"><span>{ fileData.name }</span></div>
                      </div>
                      <button
                        className="rui-dropzone-remove"
                        onClick={(e) => {
                          e.stopPropagation();
                          this.removeFile(fileData);
                        }}
                      >
                        <Icon name="x" />
                      </button>
                    </div>
                  );
                })
              ) : (
                <div className="rui-dropzone-message">
                  <span className="rui-dropzone-icon">
                    <Icon name="upload-cloud" />
                  </span>
                  <span className="rui-dropzone-text">
                    {!this.props.hideDropText && (this.props.dropText || 'Drop CSV file here or click to upload.')}
                  </span>
                </div>
              ) }
            </div>
          );
        } }
      </Dropzone>
    );
  }
}

export default ComponentDropzone;
