import {
  UPDATE_CART,
} from '../actions/actionTypes';

const initialState = {
  cart: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CART:
      return {...state, cart: action.payload.data};
    default:
      return initialState;
  }
};
