import {
  GET_ALL_DRINKS,
} from '../actions/actionTypes';

const initialState = {
  data: [],
  totalSize: 0,
  manufacturers: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_DRINKS:
      return { ...state, loading: false, data: action.payload.data, totalSize: action.payload.totalSize };
    default:
      return initialState;
  }
};
