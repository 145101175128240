import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import Toast from 'cogo-toast';

import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {
  getPendingClaims,
} from '../../redux/actions/brand.actions';
import Breadcrumb from '../../components/common/breadcrumb.component';
import Icon from '../../components/icon';
import RowMoreOptions from '../../components/table/row-more-options';
import Button from '../../components/overrides/button';
import NoDataIndicator from '../../components/no-data-indicator';
import TableLoader from '../../components/table-loader';

const { SearchBar } = Search;

const defaultSorted = [{
  dataField: 'brand',
  order: 'asc',
}];

const cellEditProps = {
  mode: 'dbclick',
};

// TODO Remote Search https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Remote&selectedStory=Remote%20Search&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel

const defaults = {
  mergeCigar: '',
};

const NoDataIndication = () => (
  <div className="table-loader">
    <div className="loader">
      <div className="line bg-primary"></div>
      <div className="line bg-primary"></div>
      <div className="line bg-primary"></div>
      <div className="line bg-primary"></div>
      <div className="line bg-primary"></div>
    </div>
  </div>
);

class ClaimsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      selectedClaim: null,
      showModal: false,
      showManualModal: false,
      showTooltip: false,
      ...defaults,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  componentDidMount() {
    this.props.getPendingClaims(1, 10);
  }

  keyForType = (type) => {
    switch (type) {
      case 'detail':
        return 'showModal';
      case 'manual':
        return 'showManualModal';
      default:
        return '';
    }
  };

  onOpenModal = (type) => {
    this.setState({ [this.keyForType(type)]: true });
  };

  onCloseModal = (type) => {
    this.setState({
      [this.keyForType(type)]: false,
      ...defaults,
    });
  };

  onSaveModal = (type) => {
    console.log('Submitting!');
    // TODO axios submit + loading, then finish
    this.setState({
      [this.keyForType(type)]: false,
      ...defaults,
    });
  };

  imageFormatter = (cell, row) => (
    <img style={{ maxWidth: 75 }} src={cell} />
  );

  handleChange = (key) => (value) => {
    this.setState({ [key]: value });
  };

  checkBoxFormatter = (cell, row) => (
    <div className="checkbox checkbox-secondary">
      <input name="checkbox" checked={cell} type="checkbox" />
      <label></label>
    </div>
  );

  viewBtnFormatter = (cell, row) => {
    if (cell && cell.indexOf('href') !== -1) {
      const href = cell.match(/href="([^"]*)/)[1];
      if (href) {
        const id = href.replace('cigar-manager.jsp?id=', '');
        return (
          <Link to={`${process.env.PUBLIC_URL}/manager/cigars?id=${id}`}>View</Link>
        );
      }
    }
    return (<span></span>);
  };

  codeFormatter = (cell, row) => {
    if (!cell || cell.length === 0) {
      return 'Autogenerated';
    }
    return cell;
  };

  deleteClaim = (claim) => {
    alert('Coming soon!');
  };

  actionDropdown = (index, claim) => (
    <RowMoreOptions
      style={{ float: 'right' }}
      row={index}
      items={[{
        type: 'MenuItem',
        onClick: () => {
          // TODO Check for Intagram or Twitter handle and redirect - eventually, try to send through API
          alert('Coming soon!');
        },
        title: 'Copy Code + Send',
      }, {
        type: 'MenuItem',
        onClick: () => {
          // TODO Integrate https://www.mypostcard.com/en/print-api
          window.open('https://www.mypostcard.com', '_blank');
        },
        title: 'Send Postcard',
      }, {
        type: 'MenuItem',
        onClick: () => {
          this.setState({
            selectedClaim: claim,
          }, () => {
            this.onOpenModal('detail');
          });
        },
        title: 'View Details',
      }, {
        type: 'MenuItem',
        onClick: () => this.deleteClaim(claim),
        style: { color: '#ff4700' },
        title: 'Delete',
      }]}
      onShow={() => {
        // Hide the row buttons
        const dropdownActions = document.querySelector(`#dropdown-actions-${index}`);
        if (dropdownActions) {
          dropdownActions.classList.add('d-none');
        }
      }}
    />
  );

  editBtnFormatter = (cell, row, index) => {
    console.log(`Index rendered: ${index}`);
    return (
      <div style={{ width: 68 }}>
        <div id={`dropdown-actions-${index}`} className="d-none">
          {this.actionDropdown(index, row)}
        </div>
      </div>
    );
  };

  handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit }) => {
    console.log('Getting claims:');
    this.props.getPendingClaims(page, sizePerPage);
  };

  toggleTooltip() {
    this.setState({
      showTooltip: !this.state.showTooltip,
    });
  }

  render() {
    const { sizePerPage, page } = this.state;
    const { loading, pendingClaims, totalPendingClaims, error } = this.props.BrandManager;

    const columns = [{
      dataField: 'brand.name',
      text: 'Brand',
      sort: false,
    }, {
      dataField: 'contact_name',
      text: 'Contact Name',
      sort: true,
    }, {
      dataField: 'user.full_name',
      text: 'Requested By',
      sort: true,
    }, {
      dataField: 'claimed_timestamp',
      text: 'Date Claimed',
      sort: true,
    }, {
      dataField: 'verification_method',
      text: 'Method',
      sort: true,
    }, {
      dataField: 'verification_code',
      text: 'Code',
      formatter: this.codeFormatter,
      sort: true,
    }, {
      text: '',
      formatter: this.editBtnFormatter,
      sort: false,
      editable: false,
    }];

    const rowEvents = {
      onMouseEnter: (e, row, index) => {
        // console.log("Mouse entered: " + index);
        document.querySelector(`#dropdown-actions-${index}`).classList.remove('d-none');
      },
      onMouseLeave: (e, row, index) => {
        document.querySelector(`#dropdown-actions-${index}`).classList.add('d-none');
      },
      onDoubleClick: (e, row, index) => {
        // INFO If we don't include this event, the double click to edit doesn't work
        // console.log(e);
      },
    };

    const pageButtonRenderer = ({ page, active, onPageChange }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      let classname = 'btn btn-outline-secondary';
      if (active) {
        classname = 'btn btn-secondary';
      }
      return (
        <li className="page-item pl-1" key={page}>
          <a href="#" onClick={handleClick} className={classname}>{page}</a>
        </li>
      );
    };

    // TODO https://www.npmjs.com/package/react-device-detect change for mobile
    return (
      <div>
        <Breadcrumb title="Pending Claims" label="Pending Claims" parent="Venues" />

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body datatable-react">
                  <ToolkitProvider
                    keyField="id"
                    data={pendingClaims}
                    columns={columns}
                    search
                  >
                    {
                      (toolkitprops) => (
                        <div>
                          <SearchBar {...toolkitprops.searchProps} />
                          {/* <ClearSearchButton { ...props.searchProps } /> */}
                          <button
                            className="btn btn-primary mr-2"
                            onClick={() => this.onOpenModal('manual')}
                            style={isMobile ? {
                              padding: '6px 14px',
                              float: 'right',
                            } : { float: 'right' }}
                          >
                            {isMobile ? (<i className="icon icon-plus" />) : 'Manually Claim Brand'}
                          </button>
                          <BootstrapTable
                            remote
                            keyField="id"
                            data={pendingClaims}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            wrapperClasses="table-responsive"
                            rowEvents={rowEvents}
                            pagination={paginationFactory({
                              pageButtonRenderer,
                              page,
                              sizePerPage,
                              totalSize: totalPendingClaims,
                            })}
                            cellEdit={cellEditFactory(cellEditProps)}
                            onTableChange={this.handleTableChange}
                            noDataIndication={ () => (!loading ? (error ? <NoDataIndicator message={`${error.message}. Please contact an admin if the problem persists.`} /> : <NoDataIndicator message={'No pending claims.'} />) : <TableLoader />) }
                            {...toolkitprops.baseProps}
                          />

                          {this.detailsModal()}
                          {this.manualModal()}
                        </div>
                      )
                    }
                  </ToolkitProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // copyText = (ref) => {
  //   console.log(ref);
  //   if (ref && ref.current) {
  //     ref.current.select();
  //     ref.current.setSelectionRange(0, 99999); // For mobile devices
  //     document.execCommand('copy');
  //     Toast.success('Copied code to clipboard');
  //   }
  // };

  copyText = (text) => {
    navigator.clipboard.writeText(text);
    Toast.success('Copied code to clipboard');
  };

  detailsModal = () => {
    const { selectedClaim } = this.state;
    return (
      <Modal isOpen={this.state.showModal} onClosed={() => this.onCloseModal('detail')}>
        <div className="modal-header">
          <h5 className="modal-title">Claim Details</h5>
        </div>
        <div className="modal-body">
          {selectedClaim && (
            <table>
              <tbody>
                <tr>
                  <td>
                    {'Venue'}
                  </td>
                  <td>
                    {selectedClaim.brand.name}
                  </td>
                </tr>
                <tr>
                  <td>
                    {'Contact Name'}
                  </td>
                  <td>
                    {selectedClaim.contact_name}
                  </td>
                </tr>
                <tr>
                  <td>
                    {'Contact Number'}
                  </td>
                  <td>
                    {selectedClaim.contact_number}
                  </td>
                </tr>
                <tr>
                  <td>
                    {'Verification Method'}
                  </td>
                  <td>
                    {selectedClaim.verification_method}
                  </td>
                </tr>
                {selectedClaim.verification_code && (
                  <tr>
                    <td>
                      {'Verification Code'}
                    </td>
                    <td>
                      <span>{selectedClaim.verification_code}</span>
                      {' '}
                      <button
                        style={{
                          border: 'none',
                          color: '#959595',
                        }}
                        onClick={() => this.copyText(selectedClaim.verification_code)}
                      >
                        {'Copy'}
                        <Icon name="copy" vendor="fa" />
                      </button>
                    </td>
                  </tr>
                )}
                {selectedClaim.instagram_username && (
                  <tr>
                    <td>
                      {'Instagram ID'}
                    </td>
                    <td>
                      {selectedClaim.instagram_username}
                    </td>
                  </tr>
                )}
                {selectedClaim.twitter_username && (
                  <tr>
                    <td>
                      {'Twitter ID'}
                    </td>
                    <td>
                      {selectedClaim.twitter_username}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
        <div className="modal-footer">
          <Button onClick={() => this.onCloseModal('detail')}>Cancel</Button>
        </div>
      </Modal>
    );
  };

  manualModal = () => (
    <Modal isOpen={this.state.showManualModal} onClosed={() => this.onCloseModal('manual')}>
      <div className="modal-header">
        <h5 className="modal-title">Claim Details</h5>
      </div>
      <div className="modal-body">
        Coming Soon - For now, follow the steps below to manually link a user to their business:
        <ol>
          <li>Set user <code>acct_type</code> to <code>2</code></li>
          <li>Set brand record for <code>claimed</code> to <code>true</code></li>
          <li>Create a new record in the <code>business_claims</code> table and change <code>user_id</code> to the user's and <code>brand_id</code> to the brand's.</li>
          <li>Ensure the 2 timestamps are set to the current date</li>
        </ol>
        {/* TODO Eventually, consider adding "status" select to create pending claims - for now, we just need to be able to link users to their profiles */}
        {/* Select for brand */}
        {/* Select for user */}
      </div>
      <div className="modal-footer">
        <Button onClick={() => this.onCloseModal('manual')}>Cancel</Button>
        <Button variant="contained" color="secondary" onClick={() => this.onSaveModal('manual')}>Add Claim</Button>
      </div>
    </Modal>
  );
}

const mapStateToProps = ({ BrandManager }) => ({ BrandManager });

export default connect(
  mapStateToProps, {
    getPendingClaims,
  },
)(ClaimsTable);
