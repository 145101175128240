import {
  GET_ALL_VENUES, GET_CLAIMS, GET_PENDING_VENUES, POST_VENUE, PUT_VENUE, SEARCH_VENUES,
} from '../actions/actionTypes';

const initialState = {
  data: [],
  venueSearch: [],
  totalSize: 0,
  pendingVenues: [],
  totalPendingVenues: 0,
  pendingClaims: [],
  totalPendingClaims: 0,
  loading: true,
};

export default (state = initialState, action) => {
  // console.log("Getting state for type: " + action.type);
  switch (action.type) {
    case GET_ALL_VENUES:
      return { ...state, loading: false, data: action.payload.data, totalSize: action.payload.totalSize };
    case SEARCH_VENUES:
      return { ...state, loading: false, venueSearch: action.payload.data };
    case POST_VENUE:
      return { ...state, loading: false };
    case PUT_VENUE:
      return { ...state, loading: false };
    case GET_PENDING_VENUES:
      return { ...state, loading: false, pendingVenues: action.payload.data, totalPendingVenues: action.payload.totalSize };
    case GET_CLAIMS:
      return { ...state, loading: false, pendingClaims: action.payload.data, totalPendingClaims: action.payload.totalSize, error: action.payload.error };
    default:
      return initialState;
  }
};
