import axios from 'axios';
import {
  GET_ALL,
  SEARCH_CIGARS,
  GET_BRANDS,
  SEARCH_BRANDS,
  GET_MANUFACTURERS,
  GET_ORIGINS,
  GET_WRAPPERS,
  GET_BINDERS,
  GET_FILLERS,
  GET_PENDING,
  GET_MATCHES,
  POST_CIGAR,
  PUT_CIGAR,
  SCRAPE_CIGAR_URL,
  GET_MATCHES_NOT_FOUND,
} from './actionTypes';
import { Constants } from '../../constants';

export const getCigarById = (cigarId) => (dispatch) => {
  const cigarUrl = `${Constants.apiPath}/cigars/datatables/all`;
  console.log(cigarUrl);

  axios.get(cigarUrl, { params: { id: cigarId } }).then((res) => {
    console.debug('Single cigar res:');
    console.debug(res);
    dispatch({
      type: GET_ALL,
      payload: {
        loading: false,
        data: res.data.data,
        // totalSize: res.data.recordsFiltered,
        totalSize: res.data.recordsTotal,
      },
    });
  }).catch((e) => {
    console.log(e.message);
  });
};

export const getAllCigars = (page, limit, query, status) => (dispatch) => {
  const cigarUrl = `${Constants.apiPath}/cigars/datatables/all`;
  console.log(cigarUrl);
  axios.get(cigarUrl, {
    params: {
      page,
      limit,
      q: query,
      status,
    },
  }).then((res) => {
    dispatch({
      type: GET_ALL,
      payload: {
        loading: false,
        data: res.data.data,
        // totalSize: res.data.recordsFiltered,
        totalSize: res.data.recordsTotal,
      },
    });
  }).catch((e) => {
    console.log(e);
  });
};

export const getPendingCigars = (page, limit, query, cigarId) => (dispatch) => {
  let cigarUrl = `${Constants.apiPath}/cigars/datatables/pending`;

  if (cigarId) {
    cigarUrl = `${Constants.apiPath}/cigars/datatables/pending/${cigarId}`;
  }

  console.log(cigarUrl);
  axios.get(cigarUrl, {
    params: {
      page,
      limit,
    },
  }).then((res) => {
    dispatch({
      type: GET_PENDING,
      payload: {
        loading: false,
        data: res.data.data,
        // totalSize: res.data.recordsFiltered,
        totalSize: res.data.recordsTotal,
      },
    });
  }).catch((e) => {
    console.log(e.message);
  });
};

export const getCigarMatchById = (cigarId) => (dispatch) => {
  const cigarUrl = `${Constants.apiPath}/cigars/datatables/matches/${cigarId}`;
  console.log(cigarUrl);
  axios.get(cigarUrl).then((res) => {
    dispatch({
      type: GET_MATCHES,
      payload: {
        loading: false,
        data: [res.data],
        // totalSize: res.data.recordsFiltered
      },
    });
  }).catch((e) => {
    console.log(e.message);
    if (e.response.status === 404) {
      dispatch({
        type: GET_MATCHES_NOT_FOUND,
        payload: {
          loading: false,
        },
      });
    }
    // TODO Error handling?
  });
};

export const getCigarMatches = (page, limit) => (dispatch) => {
  const cigarUrl = `${Constants.apiPath}/cigars/datatables/matches`;
  console.log(cigarUrl);

  axios.get(cigarUrl, {
    params: {
      page,
      limit,
    },
  }).then((res) => {
    // console.log(res.data);
    dispatch({
      type: GET_MATCHES,
      payload: {
        loading: false,
        data: res.data,
        // totalSize: res.data.recordsFiltered
      },
    });
  }).catch((e) => {
    console.log(e.message);
  });
};

export const searchCigars = (query) => (dispatch) => {
  const cigarUrl = `${Constants.apiPath}/cigars/datatables/all`;
  console.log(cigarUrl);
  axios.get(cigarUrl, {
    params: {
      q: query,
    },
  }).then((res) => {
    dispatch({
      type: SEARCH_CIGARS,
      payload: {
        loading: false,
        data: res.data.data,
      },
    });
  }).catch((e) => {
    console.log(e);
  });
};

export const getBrands = (query) => (dispatch) => {
  axios.get(`${Constants.apiPath}/cigars/brands`, {
    params: {
      q: query,
    },
  }).then((res) => {
    // console.log(JSON.stringify(res.data));
    dispatch({
      type: GET_BRANDS,
      payload: {
        loading: false,
        data: res.data,
      },
    });
  }).catch((e) => {
    console.log(e.message);
  });
};

export const getManufacturers = (query) => (dispatch) => {
  axios.get(`${Constants.apiPath}/cigars/manufacturers`, {
    params: {
      q: query,
    },
  }).then((res) => {
    // console.log(JSON.stringify(res.data));
    dispatch({
      type: GET_MANUFACTURERS,
      payload: {
        loading: false,
        data: res.data,
      },
    });
  }).catch((e) => {
    console.log(e.message);
  });
};

export const getOrigins = (query) => (dispatch) => {
  axios.get(`${Constants.apiPath}/cigars/origins`, {
    params: {
      q: query,
    },
  }).then((res) => {
    // console.log(JSON.stringify(res.data));
    dispatch({
      type: GET_ORIGINS,
      payload: {
        loading: false,
        data: res.data,
      },
    });
  }).catch((e) => {
    console.log(e.message);
  });
};

export const getWrappers = (query) => (dispatch) => {
  axios.get(`${Constants.apiPath}/cigars/wrappers`, {
    params: {
      q: query,
    },
  }).then((res) => {
    // console.log(JSON.stringify(res.data));
    dispatch({
      type: GET_WRAPPERS,
      payload: {
        loading: false,
        data: res.data,
      },
    });
  }).catch((e) => {
    console.log(e.message);
  });
};

export const getBinders = (query) => (dispatch) => {
  axios.get(`${Constants.apiPath}/cigars/binders`, {
    params: {
      q: query,
    },
  }).then((res) => {
    // console.log(JSON.stringify(res.data));
    dispatch({
      type: GET_BINDERS,
      payload: {
        loading: false,
        data: res.data,
      },
    });
  }).catch((e) => {
    console.log(e.message);
  });
};

export const getFillers = (query) => (dispatch) => {
  axios.get(`${Constants.apiPath}/cigars/fillers`, {
    params: {
      q: query,
    },
  }).then((res) => {
    // console.log(JSON.stringify(res.data));
    dispatch({
      type: GET_FILLERS,
      payload: {
        loading: false,
        data: res.data,
      },
    });
  }).catch((e) => {
    console.log(e.message);
  });
};

export const postCigar = (cigar) => (dispatch) => new Promise((resolve, reject) => {
  axios.post(`${Constants.apiPath}/cigars`, cigar).then((res) => {
    dispatch({
      type: POST_CIGAR,
      payload: {
        loading: false,
        data: res.data,
      },
    });
    resolve(res.data);
  }).catch((e) => {
    console.log(e.message);
    dispatch({
      type: POST_CIGAR,
      payload: {
        error: e,
      },
    });
    reject(e);
  });
});

export const putCigar = (cigar) => (dispatch) => new Promise((resolve, reject) => {
  axios.put(`${Constants.apiPath}/cigars/${cigar.id}`, cigar).then((res) => {
    dispatch({
      type: PUT_CIGAR,
      payload: {
        loading: false,
        data: res.data,
      },
    });
    resolve(res.data);
  }).catch((e) => {
    console.log(e.message);
    dispatch({
      type: PUT_CIGAR,
      payload: {
        error: e,
      },
    });
    reject(e);
  });
});

export const updateVitolas = (cigarId, vitolas) => (dispatch) => new Promise((resolve, reject) => {
  axios.post(`${Constants.apiPath}/cigars/${cigarId}/vitolas`, vitolas).then((res) => {
    // dispatch({
    //   type: UPDATE_VITOLAS,
    //   payload: {
    //     loading: false,
    //     data: res.data,
    //   },
    // });
    resolve(res.data);
  }).catch((e) => {
    console.log(e.message);
    // dispatch({
    //   type: UPDATE_VITOLAS,
    //   payload: {
    //     error: e,
    //   },
    // });
    reject(e);
  });
});

export const scrapeUrl = (url) => (dispatch) => {
  axios.get(`${Constants.apiPath}/scrape/cigar`, {
    params: {
      url,
    },
  }).then((res) => {
    // console.log(JSON.stringify(res.data));
    dispatch({
      type: SCRAPE_CIGAR_URL,
      payload: {
        loading: false,
        data: res.data,
      },
    });
  }).catch((e) => {
    console.log(e.message);
  });
};
