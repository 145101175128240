import React, { Component } from 'react';

import { Form } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Sentry from '@sentry/browser';
import firebaseApp from '../../../firebase.config';

// import withFirebaseAuth from 'react-with-firebase-auth'
// import * as firebase from 'firebase/app';
// import 'firebase/auth';
// import firebaseConfig from '../../firebase.config';
//
// const firebaseApp = firebase.initializeApp(firebaseConfig);

// var images = require.context('../../assets/images/', true);

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {},
      error: {
        show: false,
        title: '',
        message: '',
      },
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ style: { display: 'none' } });
    }, 1000);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSignIn = async (event) => {
    event.preventDefault();
    const { email, password } = this.state;
    console.log(`${JSON.stringify(email)}:${JSON.stringify(password)}`);
    try {
      const user = await firebaseApp.auth().signInWithEmailAndPassword(email, password);
      Sentry.setUser({
        email,
      });
      console.log(JSON.stringify(user));
      console.log('Redirecting');
      // TODO Redirect back to attempted route if known, not default
      window.location.href = '/';
      // FIXME Not working
      // return (
      //     <Redirect to="/dashboard/cigars" />
      //     <Redirect to={{pathname: '/dashboard/cigars'}}/>
      // );
    } catch (error) {
      this.setState({
        error: {
          show: true,
          title: 'Unable To Log In',
          message: error.message,
        },
      });
    }
  };

  closeAlert = () => {
    this.setState({
      error: {
        show: false,
        title: '',
        message: '',
      },
    });
  };

  render() {
    const { style } = this.state;
    const background = require('../../../assets/images/auth-layer.png');

    return (
      <div>
        {/* Loader starts */}
        <div className="loader-wrapper" style={style}>
          <div className="loader bg-white">
            <div className="line bg-primary" />
            <div className="line bg-primary" />
            <div className="line bg-primary" />
            <div className="line bg-primary" />
            <div className="line bg-primary" />
            <h4>
              {'\'Bout that time...'}
              <span>&#x1F4A8;</span>
            </h4>
          </div>
        </div>
        {/* Loader ends */}

        <SweetAlert
          show={this.state.error.show}
          type="danger"
          title={this.state.error.title}
          onConfirm={this.closeAlert}
        >
          {this.state.error.message}
        </SweetAlert>

        {/* page-wrapper Start */}
        <div className="page-wrapper">
          <div className="container-fluid">
            {/* login page start */}
            <div className="authentication-main">
              <div className="row">
                <div className="col-md-4 p-0">
                  <div
                    className="auth-innerleft"
                    style={{
                      backgroundImage: `url(${background})`,
                      backgroundSize: 'cover',
                    }}
                  >
                    <div className="text-center">
                      {/* <img src={require('../../assets/images/logo-login.png')} */}
                      {/*     className="logo-login" alt=""/> */}
                      {/* <hr/> */}
                      {/* <div className="social-media"> */}
                      {/*    <ul className="list-inline"> */}
                      {/*        <li className="list-inline-item"><i */}
                      {/*            className="fa fa-facebook txt-fb" aria-hidden="true"></i></li> */}
                      {/*        <li className="list-inline-item"><i */}
                      {/*            className="fa fa-google-plus txt-google-plus" */}
                      {/*            aria-hidden="true"></i></li> */}
                      {/*        <li className="list-inline-item"><i */}
                      {/*            className="fa fa-twitter txt-twitter" aria-hidden="true"></i> */}
                      {/*        </li> */}
                      {/*        <li className="list-inline-item"><i */}
                      {/*            className="fa fa-linkedin txt-linkedin" aria-hidden="true"></i> */}
                      {/*        </li> */}
                      {/*    </ul> */}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-8 p-0">
                  <div className="auth-innerright">
                    <div className="authentication-box">
                      <h4>LOGIN</h4>
                      <h6>Authorized access only</h6>
                      <div className="card mt-4 p-4 mb-0">
                        <Form className="theme-form" onSubmit={this.handleSignIn}>
                          <div className="form-group">
                            <label className="col-form-label pt-0">Email</label>
                            <input
                              required
                              type="text"
                              name="email"
                              className="form-control form-control-lg"
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <label className="col-form-label">Password</label>
                            <input
                              required
                              type="password"
                              name="password"
                              className="form-control form-control-lg"
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="checkbox p-0">
                            <input id="checkbox1" type="checkbox" />
                            <label htmlFor="checkbox1">Remember me</label>
                          </div>
                          <div className="form-group form-row mt-3">
                            <div className="col-md-3">
                              <button type="submit" className="btn btn-primary">LOGIN</button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* login page end */}
          </div>
        </div>
        {/* page-wrapper Ends */}

      </div>

    );
  }
}

// const firebaseAppAuth = firebaseApp.auth();
//
// const providers = {
//     googleProvider: new firebase.auth.GoogleAuthProvider(),
//     facebookProvider: new firebase.auth.GoogleAuthProvider(),
// };
//
// export default withFirebaseAuth({
//     providers,
//     firebaseAppAuth,
// })(App);

export default LoginPage;
