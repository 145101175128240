import { DebounceInput } from 'react-debounce-input';
import React from 'react';

const DebounceSearchBar = (props) => (
  <DebounceInput
    className="form-control"
    type="text"
    placeholder={props.placeholder || 'Search...'}
    style={{ float: 'left', width: 200 }}
    debounceTimeout={200}
    onChange={(e) => {
      if (typeof props.onChange === 'function') {
        props.onChange(e);
      }
      setTimeout(() => {
        if (typeof props.onSearch === 'function') {
          if (e.target) {
            props.onSearch(e.target.value);
          }
        }
      }, 100);
    }}
  />
);

export default DebounceSearchBar;
