import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import Placeholder from '../../../config/placeholder.config';
import firebaseApp from '../../../firebase.config';
import { UserStore } from '../../../stores';
import ls from "local-storage";

class UserMenu extends Component {
  logOut(e) {
    e.preventDefault();
    firebaseApp.auth().signOut().then(() => {
      UserStore.update((s) => {
        s.firebaseUser = null;
        s.user = null;
        s.authenticated = false;
        ls('boxpressd_admin_token_id', 0);
      });
      this.props.history.push('/login');
    }).catch((error) => {
      UserStore.update((s) => {
        s.firebaseUser = null;
        s.user = null;
        s.authenticated = false;
        ls('boxpressd_admin_token_id', 0);
      });
      this.props.history.push('/login');
    });
  }

  render() {
    const user = firebaseApp.auth().currentUser || {}; // FIXME Why would this ever be undefined?
    // console.log('User menu user from Firebase:');
    // console.log(user);
    const userImage = user.photoURL || Placeholder.avatar;
    return (
      <li className="onhover-dropdown">
        <div className="media  align-items-center">
          <img
            className="align-self-center pull-right mr-2"
            src={userImage}
            style={{ height: 24, width: 24, borderRadius: 12 }}
            alt=""
          />
          <div className="media-body">
            <h6 className="m-0 txt-dark f-16">
              {user.displayName ? user.displayName.split(' ')[0] : 'Account'}
              <i className="fa fa-angle-down pull-right ml-2" style={{ lineHeight: '18px' }} />
            </h6>
          </div>
        </div>
        <ul className="profile-dropdown onhover-show-div p-20">
          {/* <li> */}
          {/*    <a href="#"> */}
          {/*        <i className="icon-user"></i> */}
          {/*        Edit Profile */}
          {/*    </a> */}
          {/* </li> */}
          {/* <li> */}
          {/*    <a href="#"> */}
          {/*        <i className="icon-email"></i> */}
          {/*        Inbox */}
          {/*    </a> */}
          {/* </li> */}
          {/* <li> */}
          {/*    <a href="#"> */}
          {/*        <i className="icon-check-box"></i> */}
          {/*        Task */}
          {/*    </a> */}
          {/* </li> */}
          {/* <li> */}
          {/*    <a href="#"> */}
          {/*        <i className="icon-comments"></i> */}
          {/*        Chat */}
          {/*    </a> */}
          {/* </li> */}
          <li>
            <a href="#" onClick={this.logOut.bind(this)}>
              <i className="icon-power-off" />
              Logout
            </a>
          </li>
        </ul>
      </li>
    );
  }
}

export default withRouter(UserMenu);
