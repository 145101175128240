import React from 'react';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { connect } from 'react-redux';
import { Input, Label, Modal } from 'reactstrap';
import FancyBox from 'react-fancybox';
import { isMobile } from 'react-device-detect';
import Breadcrumb from '../../components/common/breadcrumb.component';
import { getAllDrinks } from '../../redux/actions/drink.actions';
import { Constants } from '../../constants';
import DebounceSearchBar from '../../components/DebounceSearchBar';
import NoDataIndicator from '../../components/no-data-indicator';
import TableLoader from '../../components/table-loader';
import Pagination from '../../components/ui/base/pagination';
import TouchSpin from '../../components/touch-spin';
import Button from '../../components/overrides/button';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

const defaultSorted = [{
  dataField: 'name',
  order: 'asc',
}];

const cellEditProps = {
  mode: 'dbclick',
};

// TODO Remote Search https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Remote&selectedStory=Remote%20Search&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel

const defaults = {
  selectedDrink: '',
  selectedName: '',
  selectedManufacturer: '',
  selectedDrinkType: null,
  pairedCigars: [
    {
      value: '',
      label: '',
    },
  ],
};

class DrinkTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      // data: data.data,
      // totalSize: data.recordsFiltered,
      showDrinkModal: false,
      showMergeModal: false,
      showPairingModal: false,
      showPagePickerModal: false,
      ...defaults,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  keyForType = (type) => {
    switch (type) {
      case 'drink':
        return 'showDrinkModal';
      case 'merge':
        return 'showMergeModal';
      case 'paired':
        return 'showPairingModal';
      case 'page':
        return 'showPagePickerModal';
      default:
        return '';
    }
  };

  onOpenModal = (type, row) => {
    if (row) {
      console.log(JSON.stringify(row));
      this.setState({
        selectedDrink: row,
        selectedName: row.name || '',
        selectedManufacturer: row.manufacturer ? {
          value: row.manufacturer,
          label: row.manufacturer,
        } : '',
      });
    }
    if (type === 'paired') {
      // TODO Pull in paired cigars
    }
    this.setState({ [this.keyForType(type)]: true });
  };

  onCloseModal = (type) => {
    this.setState({
      [this.keyForType(type)]: false,
      ...defaults,
    });
  };

  onSaveModal = (type) => {
    console.log('Submitting!');
    if (type === 'drink') {
      const drinkPairing = {
        name: this.state.selectedName,
        manufacturer: this.state.selectedManufacturer.value,
        type: this.state.selectedDrinkType || 'other',
      };
      // alert(JSON.stringify(drinkPairing));
      if (this.state.selectedDrink && this.state.selectedDrink.id) {
        alert('Editing coming soon. Please contact an admin');
      } else {
        axios.post(`${Constants.apiPath}/cigars/pairings`, drinkPairing).then((res) => {
          // TODO Update the table
          this.setState({
            [this.keyForType(type)]: false,
            ...defaults,
          });
        }).catch((err) => {
          console.error(err);
          alert('Something went wrong'); // TODO Sweet alert
        });
      }
    } else {
      this.setState({
        [this.keyForType(type)]: false,
        ...defaults,
      });
    }
  };

  imageFormatter = (cell, row) => {
    if (cell) {
      const imageUrl = cell.replace('ei.isnooth.com/multimedia', 'snooth.boxpressd.io').replace('http://', 'https://');
      return (
        <FancyBox
          thumbnail={imageUrl}
          image={imageUrl}
        />
      );
    }
    return null;
  };

  handleChange = (key) => (value) => {
    this.setState({ [key]: value });
  };

  handleInputChange = (key) => (e) => {
    this.setState({ [key]: e.target.value });
  };

  handleRadioChange = (key) => (event) => {
    this.setState({ [key]: event.target.value });
  };

  loadManufacturers = (inputValue, callback) => {
    axios.get(`${Constants.apiPath}/cigars/datatables/pairings/manufacturers`, {
      params: { q: inputValue },
    }).then((res) => {
      callback(res.data.map((value) => ({ value, label: value })));
    }).catch((err) => {
      console.error(err);
    });
  };

  editBtnFormatter = (cell, row) => (
    <button className="btn btn-outline-primary" onClick={() => this.onOpenModal('drink', row)}>Edit</button>
  );

  updateRecords = () => {
    // FIXME This hack is to ensure that the new details load - not sure why they won't just update without switching the page...
    //  It works fine in the Brand and Venue Managers..
    const {
      page,
      sizePerPage,
      searchText,
      tableChangeType,
    } = this.state;
    this.handleTableChange(tableChangeType, {
      page: page - 1 > 0 ? page - 1 : 1,
      sizePerPage,
      searchText,
    });
    setTimeout(() => {
      console.debug('Rebuilding table for images...');
      this.handleTableChange(tableChangeType, {
        page,
        sizePerPage,
        searchText,
      });
    }, 50);
    // FIXME End hack
  }

  handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit, searchText }) => {
    // const currentIndex = (page - 1) * sizePerPage;
    this.props.getAllDrinks(page, sizePerPage, searchText && searchText.length > 0 ? searchText : null);

    this.setState({
      page,
      sizePerPage,
    });
  };

  handlePageChange = (e, index) => {
    this.setState({
      page: index,
    });
    const {
      sizePerPage,
      searchText,
    } = this.state;
    this.props.getAllDrinks(index + 1, sizePerPage, searchText && searchText.length > 0 ? searchText : null);
  };

  render() {
    const { sizePerPage, page } = this.state;
    // const { data, totalSize } = this.state;
    const { data, totalSize, loading } = this.props.DrinkManager;

    // console.log(JSON.stringify(this.props.DrinkManager));

    const columns = [{
      dataField: 'image_url',
      text: 'Image',
      formatter: this.imageFormatter,
      sort: false,
    }, {
      dataField: 'name',
      text: 'Name',
      sort: true,
    }, {
      dataField: 'manufacturer',
      text: 'Manufacturer',
      sort: true,
    }, {
      dataField: 'edit',
      text: 'Edit',
      formatter: this.editBtnFormatter,
      sort: true,
    }];

    const pageButtonRenderer = ({ page, active, onPageChange }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      let classname = 'btn btn-outline-secondary';
      if (active) {
        classname = 'btn btn-secondary';
      }
      return (
        <li className="page-item pl-1" key={page}>
          <a href="#" onClick={handleClick} className={classname}>{page}</a>
        </li>
      );
    };

    return (
      <div>
        <Breadcrumb title="Drink Pairings" label="Cigars" parent="Pairings" />

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body datatable-react">
                  <ToolkitProvider
                    keyField="id"
                    data={data}
                    columns={columns}
                    search
                  >
                    {
                      (toolkitprops) => (
                        <div>
                          <PaginationProvider
                            pagination={paginationFactory({
                              pageButtonRenderer,
                              page,
                              sizePerPage,
                              // withFirstAndLast: false,
                              custom: true,
                              totalSize,
                              showTotal: true,
                            })}
                          >
                            {
                              ({
                                paginationProps,
                                paginationTableProps,
                              }) => (
                                <div>
                                  {isMobile && (
                                    <DebounceSearchBar
                                      {...toolkitprops.searchProps}
                                      placeholder="Search drink pairings..."
                                      onChange={() => this.setState({ page: 1 })}
                                      onSearch={(query) => {
                                        // INFO Since mobile doesn't use BootstrapTable, this needs managed manually
                                        this.setState({ searchText: query }, this.updateRecords);
                                      }}
                                    />
                                  )}
                                  {!isMobile && (
                                    <DebounceSearchBar
                                      {...toolkitprops.searchProps}
                                      placeholder="Search drink pairings..."
                                      onChange={() => this.setState({ page: 1 })}
                                    />
                                  )}
                                  <div style={{ marginLeft: 10, float: 'left' }}>
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => this.onOpenModal('drink')}
                                    style={{ float: 'right' }}
                                  >
                                    {'Add Drink'}
                                  </button>
                                  <BootstrapTable
                                    remote
                                    keyField="id"
                                    data={data}
                                    columns={columns}
                                    defaultSorted={defaultSorted}
                                    wrapperClasses="table-responsive"
                                    cellEdit={cellEditFactory(cellEditProps)}
                                    onTableChange={this.handleTableChange}
                                    noDataIndication={() => (!loading
                                      ? <NoDataIndicator message="No drink pairings found matching search query." />
                                      : <TableLoader />)}
                                    {...paginationTableProps}
                                    {...toolkitprops.baseProps}
                                  />

                                  <PaginationTotalStandalone
                                    {...paginationProps}
                                  />
                                  <div style={{ width: 200, display: 'inline-block', float: 'right' }}>
                                    <Pagination
                                      hideNavigation
                                      page={page}
                                      sizePerPage={sizePerPage}
                                      totalSize={totalSize}
                                      handlePageChange={this.handlePageChange}
                                      onShowPagePicker={() => this.onOpenModal('page')}
                                    />
                                  </div>
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              )
                            }
                          </PaginationProvider>

                          {this.pagePickerModal()}
                          {this.mergeModal()}
                          {this.pairingModal()}
                          {this.drinkModal()}
                        </div>
                      )
                    }
                  </ToolkitProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  drinkModal = () => {
    const {
      manufacturers,
    } = this.props.DrinkManager;

    return (
      <Modal isOpen={this.state.showDrinkModal} onClosed={() => this.onCloseModal('drink')}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">Add New Drink Pairing</h5>
        </div>
        <div className="modal-body">
          {/* TODO Include dropzone for image to left of rest of content on desktop, above on mobile */}
          <span style={{ marginTop: 8, display: 'inline-block' }}>{'Name'}</span>
          <input
            className="form-control"
            type="text"
            name="name"
            value={this.state.selectedName}
            style={{ textTransform: 'capitalize' }}
            onChange={this.handleInputChange('selectedName')}
          />
          <span style={{ marginTop: 8, display: 'inline-block' }}>{'Manufacturer'}</span>
          <AsyncCreatableSelect
            value={this.state.selectedManufacturer}
            onChange={this.handleChange('selectedManufacturer')}
            getOptionLabel={(option) => `${option.label}`}
            getOptionValue={(option) => `${option}`}
            isOptionSelected={(option) => this.state.selectedManufacturer.value === option.value}
            loadOptions={this.loadManufacturers}
            defaultOptions={manufacturers.map((value) => ({
              value,
              label: value,
            }))}
          />
          <div>
            <span style={{ marginTop: 8, display: 'inline-block' }}>{'Drink Type'}</span>
            <div>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="drink type"
                  name="drink_type"
                  value={this.state.selectedDrinkType}
                  onChange={this.handleRadioChange('selectedDrinkType')}
                >
                  <FormControlLabel
                    value="spirit"
                    control={<Radio color="primary" />}
                    label="Spirit"
                  />
                  <FormControlLabel
                    value="beer"
                    control={<Radio color="primary" />}
                    label="Beer"
                  />
                  <FormControlLabel
                    value="wine"
                    control={<Radio color="primary" />}
                    label="Wine"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio color="primary" />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div className="row" style={{ justifyContent: 'center' }}>
            <button
              id="merge"
              style={{ margin: 5 }}
              type="button"
              className="btn btn-raised btn-default btn-sm"
              onClick={() => this.onOpenModal('merge')}
            >
              {'Merge'}
            </button>
            <button
              id="merge"
              style={{ margin: 5 }}
              type="button"
              className="btn btn-raised btn-default btn-sm"
              onClick={() => this.onOpenModal('paired')}
            >
              {'Cigar Pairings'}
            </button>
          </div>
        </div>
        <div className="modal-footer">
          {this.state.selectedDrink && (
            <Button onClick={() => console.log('TODO Implement delete confirm modal')} color="danger">Delete</Button>
          )}
          <Button onClick={() => this.onCloseModal('drink')}>Cancel</Button>
          <Button onClick={() => this.onSaveModal('drink')} variant="contained" color="secondary">Save</Button>
        </div>
      </Modal>
    );
  };

  mergeModal = () => (
    <Modal isOpen={this.state.showMergeModal} onClosed={() => this.onCloseModal('merge')}>
      <div className="modal-header">
        <h5 className="modal-title">Merge Drink</h5>
      </div>
      <div className="modal-body">
        Select drink to merge:
        {/* TODO Just AsyncSelect - no create */}
        <AsyncCreatableSelect
          value={this.state.mergeCigar}
          onChange={this.handleChange('mergeCigar')}
          getOptionLabel={(option) => `${option.label}`}
          getOptionValue={(option) => `${option}`}
          isOptionSelected={(option) => this.state.mergeCigar.value === option.value}
        />
        {/* TODO Force switch */}
      </div>
      <div className="modal-footer">
        <Button onClick={() => this.onCloseModal('merge')}>Cancel</Button>
        <Button onClick={() => this.onSaveModal('merge')} variant="contained" color="secondary">Merge</Button>
      </div>
    </Modal>
  );

  pairingModal = () => (
    <Modal isOpen={this.state.showPairingModal} onClosed={() => this.onCloseModal('paired')}>
      <div className="modal-header">
        <h5 className="modal-title">Link Recommended Cigars</h5>
      </div>
      <div className="modal-body">
        {/* TODO Need to be dynamic */}
        {this.state.pairedCigars.map((cigar) => (
          <div key={cigar.value}>
            <AsyncCreatableSelect
              value={cigar}
              onChange={this.handleChange('mergeCigar')}
              getOptionLabel={(option) => `${option.label}`}
              getOptionValue={(option) => `${option}`}
              isOptionSelected={(option) => cigar.value === option.value}
            />
            {/* TODO +/- buttons to add and remove references */}
          </div>
        ))}
      </div>
      <div className="modal-footer">
        <Button onClick={() => this.onCloseModal('paired')}>Cancel</Button>
        <Button onClick={() => this.onSaveModal('paired')} variant="contained" color="secondary">Update</Button>
      </div>
    </Modal>
  );

  // FIXME Make this a re-usable component
  pagePickerModal = () => (
    <Modal isOpen={this.state.showPagePickerModal} onClosed={() => this.onCloseModal('page')}>
      <div className="modal-header">
        <h5 className="modal-title">Select Page</h5>
      </div>
      <div className="modal-body">
        <TouchSpin
          max={Math.ceil(this.props.DrinkManager.totalSize / this.state.sizePerPage)}
          min={1}
          step={1}
          value={this.state.number || (!this.state.edited && this.state.page)}
          onChange={(value) => {
            this.setState({ edited: true, number: value });
          }}
        />
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-outline-secondary" onClick={() => this.onCloseModal('page')}>
          {'Cancel'}
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            this.handlePageChange(null, this.state.number);
            this.onCloseModal('page');
          }}
        >
          {'Go'}
        </button>
      </div>
    </Modal>
  );
}

const mapStateToProps = ({ DrinkManager }) => ({ DrinkManager });

export default connect(
  mapStateToProps, {
    getAllDrinks,
  },
)(DrinkTable);
