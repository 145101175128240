import React, { Component } from 'react';
// import PropTypes from 'prop-types';

import MarkerClusterer from 'node-js-marker-clusterer';

class GoogleMap extends Component {
  componentDidMount() {
    this.loadMap();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.google !== this.props.google || prevProps.locations !== this.props.locations) {
      this.loadMap();
    }
  }

  loadMap() {
    if (this.props && this.props.google) {
      const { google, locations, icon } = this.props;
      const node = this.mapRef;
      const mapConfig = { center: this.props.center,
        zoom: this.props.zoom,
        mapTypeControl: false,
        streetViewControl: false,
        gestureHandling: 'cooperative' };

      this.map = new google.maps.Map(node, mapConfig);
      this.map.addListener('dragend', () => {
        if (typeof this.props.onCenterChanged === 'function') {
          this.props.onCenterChanged(this.map.getCenter());
        }
      });
      this.map.addListener('zoom_changed', () => {
        if (typeof this.props.onZoomChanged === 'function') {
          this.props.onZoomChanged(this.map.getZoom());
        }
      });

      const infoWindow = new google.maps.InfoWindow({
        content: this.props.loading,
      });

      // console.log("Creating markers");

      const markers = locations.map((location) => {
        console.log(location);
        console.log(`Creating marker at lat: ${location.lat}, lng: ${location.lng}`);

        const marker = new google.maps.Marker({
          position: {
            lat: location.lat,
            lng: location.lng,
          },
          map: this.map,
          content: `<div class="c-maps__callout">${location.address}</div>`,
          icon,
        });

        if (location.isOpen) {
          setTimeout(() => {
            infoWindow.setContent(marker.content);
            infoWindow.open(this.map, marker);
          }, 1000);
        }

        google.maps.event.addListener(marker, 'click', () => {
          infoWindow.setContent(marker.content);
          infoWindow.open(this.map, marker);
        });

        return marker;
      });

      // console.log(markers);

      return new MarkerClusterer(
        this.map,
        markers,
        {
          styles: [
            {
              textColor: 'white',
              url: require('../../../assets/images/maps/cluster-sm.svg'),
              height: 50,
              width: 50,
            },
            {
              textColor: 'white',
              url: require('../../../assets/images/maps/cluster-md.svg'),
              height: 50,
              width: 50,
            },
            {
              textColor: 'white',
              url: require('../../../assets/images/maps/cluster-lg.svg'),
              height: 50,
              width: 50,
            },
          ],
        },
      );
    }

    return {};
  }

  render() {
    { /* FIXME Height should be dynamic - 100% isn't working tho */ }
    return (
      <div
        className="map"
        style={{ height: 350 }}
        ref={(e) => {
          this.mapRef = e;
        }}
      />
    );
  }
}

GoogleMap.defaultProps = {
  icon: {
    url: require('../../../assets/images/maps/tower.png'), // 198 x 256
    scaledSize: new window.google.maps.Size(25, 32), // scaled size
    // origin: new google.maps.Point(0,0), // origin
    // anchor: new google.maps.Point(0, 0) // anchor
  },
};

export default GoogleMap;
