import axios from 'axios';
import ls from 'local-storage';
import axiosRetry, { exponentialDelay } from 'axios-retry';
import { UserStore } from '../stores';

export const updateFirebaseToken = (user) => new Promise((resolve, reject) => {
  console.debug('Getting new auth token in axios retry condition...');
  user.getIdToken().then((tokenId) => {
    console.debug('Got new auth token in axios retry condition!!');
    axios.defaults.headers.common.Authorization = `Bearer ${tokenId}`;
    ls('boxpressd_admin_token_id', tokenId);
    resolve(tokenId);
  }).catch((err) => {
    reject(err);
  });
});

const updateAxiosHeaders = (requestConfig, tokenId) => {
  requestConfig.headers.common.Authorization = `Bearer ${tokenId}`;
  requestConfig.headers.Authorization = `Bearer ${tokenId}`;
};

export const initAxiosInterceptor = () => {
  axios.interceptors.request.use(async (requestConfig) => {
    console.debug('Linking auth token in interceptor...');
    console.debug('Getting new token...');
    const user = UserStore.getRawState().firebaseUser;
    console.debug('Firebase User:');
    console.debug(user);
    if (user) {
      console.debug('Got user!');
      const updatedToken = await updateFirebaseToken(user);
      if (updatedToken) {
        console.debug('Got new firebase token:');
        console.debug(updatedToken);
        updateAxiosHeaders(requestConfig, updatedToken);
      }
    }
    return requestConfig;
  }, (error) => Promise.reject(error));
};

const retryStatuses = [403];
export const initAxiosRetry = () => {
  axiosRetry(axios, {
    retries: 5,
    retryDelay: exponentialDelay,
    retryCondition: (e) => {
      // FIXME May want to restrict this to check to see if a user is logged in first
      console.debug('=== Checking Retry Request ===', e && e.response && e.response.status);
      return (e && e.response && retryStatuses.indexOf(e.response.status) !== -1);
    },
  });
};
