import {
  GET_ALL_BRANDS, GET_CLAIMS, SEARCH_BRANDS,
} from '../actions/actionTypes';

const initialState = {
  data: [],
  brandSearch: [],
  pendingClaims: [],
  totalPendingClaims: 0,
  loading: true,
  totalSize: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_BRANDS:
      return { ...state, loading: false, data: action.payload.data, totalSize: action.payload.totalSize };
    case SEARCH_BRANDS:
      return { ...state, loadingBrands: false, brandSearch: action.payload.data };
    case GET_CLAIMS:
      return { ...state, loading: false, pendingClaims: action.payload.data, totalPendingClaims: action.payload.totalSize, error: action.payload.error };
    default:
      return initialState;
  }
};
