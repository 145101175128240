import {
  GET_ABANDONED_CARTS,
  GET_ALL_ORDERS,
  GET_ALL_PRODUCTS,
  GET_DISCOUNTS,
  GET_SHIPPING_RATES,
  GET_TAX_RATES,
  POST_PRODUCT,
  PUT_PRODUCT,
} from '../actions/actionTypes';

const initialState = {
  error: false,
  data: [],
  carts: [],
  orders: [],
  discounts: [],
  shippingRates: [],
  taxRates: [],
  totalSize: 0,
  loadingProducts: true,
  loadingOrders: true,
  loadingCarts: true,
  loadingDiscounts: true,
  loadingShippingRates: true,
  loadingTaxRates: true,
};

export default (state = initialState, action) => {
  // console.log("Getting state for type: " + action.type);
  switch (action.type) {
    case GET_ALL_PRODUCTS:
      return { ...state, loadingProducts: false, error: action.payload.error, data: action.payload.data, totalSize: action.payload.totalSize };
    case GET_ALL_ORDERS:
      return { ...state, loadingOrders: false, error: action.payload.error, orders: action.payload.data, totalOrderSize: action.payload.totalSize };
    case GET_ABANDONED_CARTS:
      return { ...state, loadingCarts: false, error: action.payload.error, carts: action.payload.data, totalCartSize: action.payload.totalSize };
    case GET_DISCOUNTS:
      return { ...state, loadingDiscounts: false, error: action.payload.error, discounts: action.payload.data, totalDiscounts: action.payload.totalSize };
    case GET_SHIPPING_RATES:
      return { ...state, loadingShippingRates: false, error: action.payload.error, shippingRates: action.payload.data, totalShippingRateSize: action.payload.totalSize };
    case GET_TAX_RATES:
      return { ...state, loadingTaxRates: false, error: action.payload.error, taxRates: action.payload.data, totalTaxRateSize: action.payload.totalSize };
    case POST_PRODUCT:
      return { ...state, loading: false };
    case PUT_PRODUCT:
      return { ...state, loading: false };
    default:
      return initialState;
  }
};
