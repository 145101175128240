import React, { Component } from 'react';
import UserMenu from './user-menu.component';
import Notification from './notification.component';
import './styles.scss';

class Header extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      sidebar: true,
      navMenus: false,
    };
  }

    goFull = () => {
      if ((document.fullScreenElement && document.fullScreenElement !== null)
            || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    };

    // TODO Add to redux so we can call this from the side menu
    openCloseSidebar = () => {
      if (this.state.sidebar) {
        this.setState({ sidebar: false });
        document.querySelector('.page-body-wrapper').classList.add('sidebar-close');
      } else {
        this.setState({ sidebar: true });
        document.querySelector('.page-body-wrapper').classList.remove('sidebar-close');
      }
    };

    toggle() {
      this.setState((prevState) => ({
        navMenus: !prevState.navMenus,
      }));
    }

    componentWillMount() {
      const contentwidth = window.innerWidth;
      if ((contentwidth) <= 991) {
        this.setState({ sidebar: false });
      }
    }

    componentDidMount() {
      const contentwidth = window.innerWidth;
      if ((contentwidth) <= 991) {
        document.querySelector('.page-body-wrapper').classList.add('sidebar-close');
      }
    }

    render() {
      return (
        <div className="page-main-header">
          <div className="main-header-left">
            <i style={{ cursor: 'pointer', fontSize: 20 }} className="icon-menu" onClick={this.openCloseSidebar} />
          </div>
          <div className="main-header-right row">
            <div className="mobile-sidebar">
              <div className="media-body text-right switch-sm">
                <div className="logo-wrapper">
                  <a href="/">
                    <img
                      src={require('../../../assets/images/logo-v2.png')}
                      alt=""
                      style={{ height: 30 }}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="nav-right col">
              <ul className={`nav-menus ${this.state.navMenus ? 'open' : ''}`}>
                <Notification />
                <UserMenu />
              </ul>
              <div className="d-lg-none mobile-toggle" onClick={() => this.toggle()}>
                <i className="icon-more"></i>
              </div>
            </div>
          </div>
        </div>
      );
    }
}

export default Header;
