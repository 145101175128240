import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import React from 'react';
import PropTypes from 'prop-types';

class SimplePagination extends React.Component {
  constructor(props) {
    super(props);
  }

    handlePagePicker = (e) => {
      e.preventDefault();
      this.props.onShowPagePicker();
    };

    render() {
      const { page, sizePerPage, totalSize, handlePageChange } = this.props;

      const pageCount = Math.ceil(totalSize / sizePerPage);
      const indexOfLastItem = page * sizePerPage;
      const indexOfFirstItem = indexOfLastItem - sizePerPage;

      console.log(`First item: ${indexOfFirstItem}`);
      console.log(`Last item: ${indexOfLastItem}`);

      // TODO Need to dynamically calculate the 5 to show (allow prop for number of pages to show?) if desktop, mobile should show number picker to jump to a certain page

      // TODO Include last and first jump buttons?

      return (
        <Pagination style={{ flexDirection: 'row' }}>
          {!this.props.hideNavigation && (
            <PaginationItem disabled={page <= 1} className="pl-1">
              <PaginationLink
                onClick={(e) => handlePageChange(e, page - 1)}
                className="btn btn-outline-secondary"
                previous
                href="#"
              />
            </PaginationItem>
          )}

          {/* {[...Array(pageCount)].map((page, i) => */}
          <PaginationItem className="pl-1" style={{ flexGrow: 1 }}>
            <PaginationLink onClick={(e) => this.handlePagePicker(e)} href="#" className="btn btn-outline-secondary">
              {'Page'}
              {' '}
              {page}
            </PaginationLink>
          </PaginationItem>
          {/* )} */}

          {!this.props.hideNavigation && (
            <PaginationItem disabled={page >= pageCount} className="pl-1">
              <PaginationLink
                onClick={(e) => handlePageChange(e, page + 1)}
                className="btn btn-outline-secondary"
                next
                href="#"
              />
            </PaginationItem>
          )}
        </Pagination>
      );
    }
}

SimplePagination.propTypes = {
  page: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func,
  onShowPagePicker: PropTypes.func,
};

export default SimplePagination;
