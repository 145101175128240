import React from 'react';
import { DropzoneComponent as Dropzone } from 'react-dropzone-component';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import AsyncCreatableSelect from 'react-select/async-creatable';
import Modal from 'react-responsive-modal';
import { connect } from 'react-redux';
import FancyBox from 'react-fancybox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import axios from 'axios';
import Hashids from 'hashids';
import Button from '@material-ui/core/Button';
import { v4 as uuidv4 } from 'uuid';
import { Constants } from '../../constants';
import MediaCropper from '../../components/cropper';
import {
  getAllGroups,
} from '../../redux/actions/group.actions';
import Breadcrumb from '../../components/common/breadcrumb.component';
import { uploadToS3 } from '../../utils/imageUtils';
const hashids = new Hashids('', 12);

const env = process.env.NODE_ENV || 'development';
const config = require('../../config/config.json')[env];

const ReactDOMServer = require('react-dom/server');
const { SearchBar, ClearSearchButton } = Search;

const defaultSorted = [{
  dataField: 'name',
  order: 'asc',
}];

const cellEditProps = {
  mode: 'dbclick',
};

const NoDataIndication = () => (
  <div className="table-loader">
    <div className="loader">
      <div className="line bg-primary"></div>
      <div className="line bg-primary"></div>
      <div className="line bg-primary"></div>
      <div className="line bg-primary"></div>
      <div className="line bg-primary"></div>
    </div>
  </div>
);

const djsPreviewTemplate = ReactDOMServer.renderToStaticMarkup(
  <div className="dz-preview dz-file-preview">
    <div className="dz-details">
      {/* <div className="dz-filename"><span data-dz-name="true"></span></div> */}
      <img
        style={{
          maxWidth: 120,
          maxHeight: 120,
        }}
        data-dz-thumbnail="true"
      />
    </div>
    {/* <div className="dz-progress"><span className="dz-upload" data-dz-uploadprogress="true"></span></div> */}
    {/* <div className="dz-success-mark"><span>✔</span></div> */}
    {/* <div className="dz-error-mark"><span>✘</span></div> */}
    {/* <div className="dz-error-message"><span data-dz-errormessage="true"></span></div> */}
  </div>,
);

// TODO Remote Search https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Remote&selectedStory=Remote%20Search&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel

const defaults = {
  selectedId: null,
  selectedName: null,
  selectedDescription: null,
  selectedFacebookId: null,
  groupProfileImage: undefined,
  groupCoverImage: undefined,
};

class GroupTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      page: 1,
      sizePerPage: 10,
      // data: data.data,
      // totalSize: data.recordsFiltered,
      showGroupModal: false,
      showMergeModal: false,
      ...defaults,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { data } = nextProps.GroupManager;
    this.setState({ groups: data });
  }

  handleInputChange = (key) => (event) => {
    console.log(key);
    console.log(event.target.value);
    this.setState({ [key]: event.target.value });
  };

  keyForType = (type) => {
    switch (type) {
      case 'group':
        return 'showGroupModal';
      case 'merge':
        return 'showMergeModal';
      default:
        return '';
    }
  };

  onOpenModal = (type, row) => {
    if (row) {
      console.log('Row details:');
      console.log(JSON.stringify(row));
      this.setState({
        selectedId: row.id || null,
        selectedName: row.name || null,
        selectedDescription: row.description || null,
        selectedFacebookId: row.fb_id || null,
        selectedPrivacy: row.private ? 'Private' : 'Public',
        groupProfileImage: row.image_url || undefined,
        groupCoverImage: row.cover_image_url || undefined,
      });
    }
    this.setState({ [this.keyForType(type)]: true });
  };

  onCloseModal = (type) => {
    this.setState({
      [this.keyForType(type)]: false,
      ...defaults,
    });
  };

  onSaveModal = (type) => {
    const {
      selectedId,
      selectedName,
      selectedDescription,
      selectedFacebookId,
      selectedPrivacy,
      groupProfileImage,
      groupCoverImage,
    } = this.state;
    console.log('Submitting!');
    const group = {
      name: selectedName,
      description: selectedDescription,
      fb_id: selectedFacebookId,
      private: selectedPrivacy === 'Private',
      image_url: groupProfileImage || undefined,
      cover_image_url: groupCoverImage || undefined,
    };
    console.log(group);
    if (selectedId) {
      axios.put(`${Constants.apiPath}/groups/${selectedId}`, group).then((result) => {
        this.handleRowChange(result.data);
      }).catch((err) => {
        console.error(err);
        // ErrorLogger.captureException(err);
      });
    } else {
      axios.post(`${Constants.apiPath}/groups`, group).then((result) => {
        this.handleRowChange(result.data);
      }).catch((err) => {
        console.error(err);
        // ErrorLogger.captureException(err);
      });
    }
    this.setState({
      [this.keyForType(type)]: false,
      ...defaults,
    });
  };

  dropzoneEventHandlers(holder) {
    const self = this;
    console.log(holder);
    return {
      addRemoveLinks: true,
      accept(file, done) {
        console.log('File:');
        console.log(file);
        console.log('uploaded');
        done();
      },
      init: (dropzone) => {
        // Dropzone.autoDiscover = false;
        console.log('Initialized dropzone');
        console.log(dropzone);
        console.log(this.state);
        const image = this.state[holder];
        if (image) {
          const imageFile = {
            name: image,
            size: 0,
          };
          dropzone.emit('addedfile', imageFile);
          dropzone.emit('thumbnail', imageFile, image);
          dropzone.emit('complete', imageFile);
          dropzone.files.push(imageFile);

          // FIXME This doesn't always hide
          // Hide the message
          setTimeout(() => {
            console.log(dropzone.element);
            const messageDiv = dropzone.element.closest('.dz-message');
            if (messageDiv) {
              messageDiv.style.display = 'none';
            } else {
              dropzone.element.children[0].style.display = 'none';
            }
          }, 500);
        }
      },
      drop: (event) => {
        console.log('Dropped event:');
        console.log(event);
        console.log(event.target);
        event.target.style.display = 'none';
        const messageDiv = this[holder].dropzone.element.closest('.dz-message');
        if (messageDiv) {
          messageDiv.style.display = 'none';
        } else {
          this[holder].dropzone.element.children[0].style.display = 'none';
        }
        this.setState({
          activeDropzone: this[holder],
          activeDropzoneId: holder,
        });
      },
      addedfile(file) {
        console.log('Dropzone files:');
        console.log(this.files);

        console.log('Added file');
        console.log(file);
        if (file && this.files.length === 1) {
          console.log(file);
          const fileReader = new FileReader();
          fileReader.onload = (event) => {
            const dataUrl = event.target.result;
            console.log(dataUrl);
            self.setState({
              selectedImageFile: dataUrl,
              showMediaCropper: true,
            });
          };
          fileReader.readAsDataURL(file);
        }

        if (this.files.length > 0) {
          console.log('Deleting file...');
          this.removeFile(this.files[0]);
        }
      },
      success: (file, response, error) => {
        console.log(response);
      },
    };
  }

  imageFormatter = (cell, row) => {
    if (cell) {
      return (
        <div className="circle-thumbnail">
          <FancyBox
            thumbnail={cell}
            image={cell}
          />
        </div>
      );
    }
    return null;
  };

  checkBoxFormatter = (cell, row) => (
    <div className="checkbox checkbox-secondary">
      <input name="checkbox" checked={cell} type="checkbox" />
      <label></label>
    </div>
  );

  handleChange = (key) => (value) => {
    this.setState({ [key]: value });
  };

  editBtnFormatter = (cell, row) => (
    <button className="btn btn-outline-primary" onClick={() => this.onOpenModal('group', row)}>Edit</button>
  );

  handleRowChange = (updatedGroup) => {
    const { groups } = this.state;
    console.log(`Updating group for ${updatedGroup.id}...`);
    console.log(updatedGroup);
    const updatedGroups = groups.map((group) => {
      if (group.id === updatedGroup.id) {
        return { ...group, ...updatedGroup };
      }
      return group;
    });
    this.setState({ groups: updatedGroups });
  };

  handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit }) => {
    // const currentIndex = (page - 1) * sizePerPage;
    this.props.getAllGroups(page, sizePerPage);

    this.setState({
      page,
      sizePerPage,
    });
  };

  uploadMedia = (blob, extension, type) => {
    const uuid = uuidv4();
    const groupHash = hashids.encode(this.state.selectedId ? this.state.selectedId : new Date().getTime());
    const filename = `${groupHash}-${type}.${extension}`;
    uploadToS3(blob, `groups/${type}`, filename, uuid)
      .then((res) => {
        console.log('Uploaded to media server!');
        console.log(res.data);
        this.setState({
          uploadingMedia: false,
          [type]: res.data.media_url,
        });
      })
      .catch((err) => {
        this.setState({ uploadingMedia: false });
        // ErrorLogger.captureException(err);
        console.error(err);
      });
  };

  renderMediaCropper = () => {
    const {
      selectedImageFile,
      showMediaCropper,
      activeDropzone,
      activeDropzoneId,
    } = this.state;
    return (
      <MediaCropper
        circle={activeDropzoneId === 'groupProfileImage'}
        aspectRatio={activeDropzoneId === 'groupProfileImage' ? 1 : 1000 / 300}
        src={selectedImageFile}
        open={showMediaCropper}
        toggle={() => this.setState({ showMediaCropper: !showMediaCropper })}
        onClose={() => this.setState({ showMediaCropper: false })}
        onSave={(croppedCanvas) => {
          const base64 = croppedCanvas.toDataURL();
          this.setState({
            showMediaCropper: false,
            selectedImage: base64, // FIXME Need to know which image this is for?
          });
          croppedCanvas.toBlob((blob) => {
            this.forceUpdate();

            console.log('Canvas blob');
            console.log(blob);
            console.log(activeDropzone);
            console.log(activeDropzoneId);
            const fileReader = new FileReader();
            fileReader.onload = (event) => {
              const dataUrl = event.target.result;
              console.log(dataUrl);
              const { dropzone } = activeDropzone;
              dropzone.emit('addedfile', blob);
              dropzone.emit('thumbnail', blob, dataUrl);
              dropzone.emit('complete', blob);
              dropzone.files.push(blob);
            };
            fileReader.readAsDataURL(blob);

            this.uploadMedia(blob, base64.split(';')[0].split('/')[1], activeDropzoneId);
          }/* , 'image/png' */);
        }}
      />
    );
  };

  render() {
    const { sizePerPage, page, groups } = this.state;
    const { totalSize } = this.props.GroupManager;

    const columns = [{
      // TODO Fallback to cover_image_url if this is undefined?
      dataField: 'image_url',
      text: 'Image',
      formatter: this.imageFormatter,
      sort: false,
    }, {
      dataField: 'name',
      text: 'Name',
      sort: true,
    }, {
      dataField: 'private',
      text: 'Private',
      formatter: this.checkBoxFormatter,
      sort: true,
    }, {
      dataField: 'edit',
      text: 'Edit',
      formatter: this.editBtnFormatter,
      sort: true,
    }];

    const pageButtonRenderer = ({ page, active, onPageChange }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      let classname = 'btn btn-outline-secondary';
      if (active) {
        classname = 'btn btn-secondary';
      }
      return (
        <li className="page-item pl-1" key={page}>
          <a href="#" onClick={handleClick} className={classname}>{page}</a>
        </li>
      );
    };

    // TODO https://www.npmjs.com/package/react-device-detect change for mobile
    return (
      <div>
        <Breadcrumb title="Groups" label="Groups" />

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body datatable-react">
                  <ToolkitProvider
                    keyField="id"
                    data={groups}
                    columns={columns}
                    search
                  >
                    {
                      (toolkitprops) => (
                        <div>
                          <SearchBar {...toolkitprops.searchProps} />
                          {/* <ClearSearchButton { ...props.searchProps } /> */}
                          <button
                            className="btn btn-primary"
                            onClick={() => this.onOpenModal('group')}
                            style={{ float: 'right' }}
                          >
                            {'Add Group'}
                          </button>
                          <BootstrapTable
                            remote
                            keyField="id"
                            data={groups}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            wrapperClasses="table-responsive"
                            pagination={paginationFactory({ pageButtonRenderer, page, sizePerPage, totalSize })}
                            cellEdit={cellEditFactory(cellEditProps)}
                            onTableChange={this.handleTableChange}
                            noDataIndication={() => <NoDataIndication />}
                            {...toolkitprops.baseProps}
                          />

                          {this.groupModal()}
                          {this.mergeModal()}
                        </div>
                      )
                    }
                  </ToolkitProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderMediaCropper()}
      </div>
    );
  }

  groupModal = () => (
    <Modal open={this.state.showGroupModal} onClose={() => this.onCloseModal('group')}>
      <div className="modal-header">
        <h5 className="modal-title">{this.state.selectedId ? 'Edit Group' : 'Add New Group'}</h5>
      </div>
      <div className="modal-body">
        <div
          className="row"
          style={{
            marginBottom: 10,
            marginLeft: -20,
            marginRight: 0,
          }}
        >
          <div className="col-md-12">
            <Dropzone
              id="groupCoverImage"
              ref={(ref) => this.groupCoverImage = ref}
              config={{
                postUrl: 'no-url',
                iconFiletypes: ['.jpg', '.png'],
              }}
              djsConfig={{
                dictDefaultMessage: 'Drop cover photo here',
                previewTemplate: djsPreviewTemplate,
              }}
              eventHandlers={this.dropzoneEventHandlers('groupCoverImage')}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="dropzone">
                  <div style={{ height: '100%' }} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Drop cover photo here</p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        </div>
        <div
          className="row"
          style={{
            marginBottom: 10,
            marginLeft: -20,
            marginRight: 0,
          }}
        >
          <div className="col-md-3">
            {/* FIXME Coming from FB, there will only be a cover image, no logo / profile pic */}
            <Dropzone
              id="groupProfileImage"
              ref={(ref) => this.groupProfileImage = ref}
              config={{
                postUrl: 'no-url',
                iconFiletypes: ['.jpg', '.png'],
              }}
              djsConfig={{
                dictDefaultMessage: 'Drop profile photo here',
                previewTemplate: djsPreviewTemplate,
              }}
              eventHandlers={this.dropzoneEventHandlers('groupProfileImage')}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="dropzone">
                  <div style={{ height: '100%' }} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Drop profile photo here</p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className="col-md-9">
            <div style={{ marginLeft: 20 }}>
              <span style={{ fontWeight: 700 }}>Name</span>
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <input
                  className="form-control"
                  type="text"
                  name="group-name"
                  value={this.state.selectedName}
                  style={{ textTransform: 'capitalize' }}
                  onChange={this.handleInputChange('selectedName')}
                  id="select-name"
                />
              </div>
              <div>
                <RadioGroup
                  row
                  aria-label="privacy"
                  name="privacy"
                  value={this.state.selectedPrivacy}
                  onChange={(event) => this.setState({ selectedPrivacy: event.target.value })}
                >
                  <FormControlLabel value="Private" control={<Radio />} label="Private" />
                  <FormControlLabel value="Public" control={<Radio />} label="Public" />
                </RadioGroup>
              </div>
            </div>
          </div>
        </div>
        <span style={{ fontWeight: 700 }}>Description</span>
        <div
          style={{
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <textarea
            className="form-control"
            name="group-description"
            value={this.state.selectedDescription}
            onChange={this.handleInputChange('selectedDescription')}
            id="select-description"
          />
        </div>
        <div className="row" style={{ marginTop: 10 }}>
          <div className="col-md-12">
            Facebook ID
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>fb.com/groups/</InputGroupText>
              </InputGroupAddon>
              <Input
                className="form-control"
                type="text"
                name="facebookId"
                value={this.state.selectedFacebookId}
                placeholder="<username>"
                onChange={this.handleInputChange('selectedFacebookId')}
              />
            </InputGroup>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <Button onClick={() => this.onCloseModal('group')}>Cancel</Button>
        <Button onClick={() => this.onSaveModal('group')} variant="contained" color="secondary">Save</Button>
      </div>
    </Modal>
  );

  mergeModal = () => (
    <Modal open={this.state.showMergeModal} onClose={() => this.onCloseModal('merge')}>
      <div className="modal-header">
        <h5 className="modal-title">Merge Group</h5>
      </div>
      <div className="modal-body">
        {'Select group to merge:'}
        {/* TODO Just AsyncSelect - no create */}
        <AsyncCreatableSelect
          value={this.state.mergeGroup}
          onChange={this.handleChange('mergeGroup')}
          getOptionLabel={(option) => `${option.label}`}
          getOptionValue={(option) => `${option}`}
          isOptionSelected={(option) => this.state.mergeGroup.value === option.value}
        />
        {/* TODO Force switch */}
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-outline-secondary" onClick={() => this.onCloseModal('merge')}>
Close
        </button>
        <button type="button" className="btn btn-primary" onClick={() => this.onSaveModal('merge')}>Merge</button>
      </div>
    </Modal>
  );
}

const mapStateToProps = ({ GroupManager }) => ({ GroupManager });

export default connect(
  mapStateToProps, {
    getAllGroups,
  }
)(GroupTable);
