import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { Col, Input, Label, Modal, ModalBody, ModalFooter, Row, Spinner, Tooltip } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import '../../assets/css/linked-cigars.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import { DropzoneComponent as Dropzone } from 'react-dropzone-component';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AsyncCreatableSelect from 'react-select/async-creatable/dist/react-select.esm';
import Autocomplete from 'react-google-autocomplete';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import FancyBox from 'react-fancybox';
import * as axios from 'axios';
import Hashids from 'hashids';
import { v4 as uuidv4 } from 'uuid';
import NoDataIndicator from '../../components/no-data-indicator';
import TableLoader from '../../components/table-loader';
import RowMoreOptions from '../../components/table/row-more-options';
import { getAllEvents } from '../../redux/actions/event.actions';
import Breadcrumb from '../../components/common/breadcrumb.component';
import Icon from '../../components/icon';
import { renderEventTimestamp } from '../../utils/formatting';
import EventTicketEditor from '../../components/events/editor';
import { Constants } from '../../constants';
import MediaCropper from '../../components/cropper';
import Pagination from '../../components/ui/base/pagination';
import { uploadToS3 } from '../../utils/imageUtils';

const ReactDOMServer = require('react-dom/server');
const hashids = new Hashids('', 12);

const env = process.env.NODE_ENV || 'development';
const config = require('../../config/config.json')[env];

const { SearchBar } = Search;

const date = new Date();
let dateMonth = date.getMonth() + 1;
let dateDay = date.getDate();
if (dateMonth < 10) {
  dateMonth = `0${dateMonth}`;
}
if (dateDay < 10) {
  dateDay = `0${dateDay}`;
}
const dateToday = String(`${date.getFullYear()}-${dateMonth}-${dateDay}`);

const defaultSorted = [{
  dataField: 'name',
  order: 'asc',
}];

const cellEditProps = {
  mode: 'dbclick',
};

const defaults = {
  selectedId: null,
  message: {
    type: 'info',
    show: false,
    title: '',
    text: '',
  },
  requirementType: 'none',
  eligibility: 'everyone',
  limitTotal: false,
  limitCustomer: false,
  selectedEvent: {},
  selectedAddressName: null,
  selectedLocationType: 'address',
  selectedStartDate: `${dateToday}T12:00:00`,
  selectedEndDate: `${dateToday}T12:00:00`,
  selectedTickets: [],
};

const djsPreviewTemplate = ReactDOMServer.renderToStaticMarkup(
  <div className="dz-preview dz-file-preview">
    <div className="dz-details" style={{ height: 'auto', width: '100%', display: 'block' }}>
      <img
        style={{
          maxWidth: '100%',
          maxHeight: 246,
        }}
        data-dz-thumbnail="true"
      />
    </div>
  </div>,
);

class EventsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      events: [],
      // totalSize: data.recordsFiltered,
      sizePerPage: 10,
      showCreateEventModal: false,
      showAddTicketModal: false,
      showTicketsModal: false,
      showImportModal: false,
      ...defaults,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  componentDidMount() {
    this.handleTableChange(null, {
      page: 1,
      sizePerPage: 30,
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { events } = nextProps.EventManager;
    this.setState({ events });
  }

  toggleTooltip(key) {
    return () => {
      this.setState({
        [key]: !this.state[key],
      });
    };
  }

  uploadMedia = (blob, extension, type) => {
    const uuid = uuidv4();
    const eventHash = hashids.encode(this.state.selectedId ? this.state.selectedId : new Date().getTime());
    const filename = `${eventHash}-${type}.${extension}`;
    uploadToS3(blob, `events/${type}`, filename, uuid)
      .then((res) => {
        console.log('Uploaded to media server!');
        console.log(res.data);
        this.setState({
          uploadingMedia: false,
          [type]: res.data.media_url,
        });
      })
      .catch((err) => {
        this.setState({ uploadingMedia: false });
        // ErrorLogger.captureException(err);
        console.error(err);
      });
  };

  loadOrganizers = (searchTerm, callback) => {
    const promises = [
      axios.get(`${Constants.apiPath}/users?q=${searchTerm}&active=true`),
      axios.get(`${Constants.apiPath}/venues?q=${searchTerm}&active=true`),
      axios.get(`${Constants.apiPath}/brands?q=${searchTerm}&active=true`),
    ];
    return Promise.all(promises)
      .then((responses) => {
        const types = ['user', 'venue', 'brand'];
        let data = [];
        responses.forEach((res, index) => {
          console.log(res.data);
          data = data.concat(res.data.map((d) => ({
            value: d.id,
            label: d.name || d.full_name, // TODO Include URL or formatted address so we know we're picking the right one
            type: types[index],
          })));
        });
        console.log('Data:');
        console.log(data);
        callback(data); // TODO Sort
      }).catch((err) => {
        console.log(err);
      });
  };

  getSelectedOrganizer = () => {
    const event = this.state.selectedEvent;
    if (event) {
      if (event.brand) {
        return { label: event.brand.name, value: event.brand.id, type: 'brand' };
      }
      if (event.venue) {
        return { label: event.venue.name, value: event.venue.id, type: 'venue' };
      }
      if (event.user) {
        return { label: event.user.name, value: event.user.id, type: 'user' };
      }
    }
    return null;
  }

  handleSelectedOrganizerChange = (option) => {
    console.log('New organizer');
    console.log(option);
    const { type, value: id, label: name } = option;
    const event = this.state.selectedEvent;
    console.log('New state:');
    console.log({ selectedEvent: { ...event, [type]: { id, name } } });
    // INFO Need to reset all organizer types to null before setting the latest - if they are different, there will be
    //  multiple linked and we don't want that
    this.setState({ selectedEvent: { ...event, user: null, brand: null, venue: null, [type]: { id, name } } });
  }

  handleChange = (key) => (e) => {
    this.setState({ [key]: e.target.value });
  };

  handleSelectedTicketChange = (index, type) => (e) => {
    console.debug('Updating option for type: ', type);
    console.debug(e);
    const tickets = this.state.selectedTickets;
    const ticket = tickets[index] || {};
    if (e.target && e.target.value) {
      ticket[type] = e.target.value;
    }
    tickets[index] = ticket;
    this.setState({ selectedTickets: tickets });
  };

  handleEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  handleEventChange = (key) => (event) => {
    console.log(key);
    console.log(event.target.value);
    const prevEvent = this.state.selectedEvent;
    this.setState({ selectedEvent: { ...prevEvent, [key]: event.target.value } });
  };

  handleInputChange = (key) => (event) => {
    console.log(key);
    console.log(event.target.value);
    this.setState({ [key]: event.target.value });
  };

  handleAddressChange = (key) => (event) => {
    console.log(key);
    console.log(event.target.value);
    // FIXME Should update locally - not in the actual Snipcart store
    // if (this.state.editingShippingAddress) {
    //     this.setState({
    //         shippingAddress: {
    //             ...this.state.shippingAddress,
    //             [key]: event.target.value
    //         }
    //     });
    // } else {
    //     this.setState({
    //         billingAddress: {
    //             ...this.state.billingAddress,
    //             [key]: event.target.value
    //         }
    //     });
    // }
  };

  handleSelectChange = (key) => (value) => {
    // TODO If the location is a venue and has an address, link the data to the event
    //  It would need to pull the venue details based on the value (venue id)
    // let address = undefined;
    // if (type === 'venue') {
    //   // TODO Include city and state?
    //   if (row.venue.location) {
    //     if (row.venue.location.formatted_address) {
    //       address = row.location.formatted_address;
    //     }
    //     if (row.venue.location.coordinates) {
    //       row.latitude = row.venue.location.coordinates.latitude;
    //       row.longitude = row.venue.location.coordinates.longitude;
    //     }
    //   }
    // }
    this.setState({ [key]: value });
  };

  handleCheckboxChange = (key) => {
    console.log('Check change:');
    console.log(key);
    return (event) => {
      console.log(event.target.checked ? 'Checked' : 'Not checked');
      this.setState({ [key]: event.target.checked });
    };
  };

  handleRadioChange = (key) => {
    console.log('Check change:');
    console.log(key);
    return (event) => {
      console.log(event.target.checked ? 'Checked' : 'Not checked');
      this.setState({ [key]: event.target.value });
    };
  };

  handleRowChange = (updatedEvent) => {
    const { events } = this.state;
    console.log(`Updating event for ${updatedEvent.id}...`);
    console.log(updatedEvent);
    const updatedEvents = events.map((event) => {
      if (event.id === updatedEvent.id) {
        return { ...event, ...updatedEvent };
      }
      return event;
    });
    this.setState({ events: updatedEvents });
  };

  keyForType = (type) => {
    switch (type) {
      case 'event':
        return 'showCreateEventModal';
      case 'ticket':
        return 'showAddTicketModal';
      case 'tickets':
        return 'showTicketsModal';
      case 'import':
        return 'showImportModal';
      default:
        return '';
    }
  };

  onCloseModal = (type) => {
    if (type === 'event') {
      this.setState({
        selectedTickets: [],
        eventImage: null,
        [this.keyForType(type)]: false,
        ...defaults,
      });
    } else if (type === 'import') {
      this.setState({
        selectedUrl: '',
        [this.keyForType(type)]: false,
      });
    } else {
      this.setState({
        [this.keyForType(type)]: false,
      });
    }
  };

  onOpenModal = (type, row) => {
    if (row) {
      if (!row.latitude && !row.longitude && row.formatted_address) {
        const geocoder = new window.google.maps.Geocoder();
        const address = row.formatted_address;
        geocoder.geocode({ address }, (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            const latitude = results[0].geometry.location.lat();
            const longitude = results[0].geometry.location.lng();
            this.setState({
              selectedEvent: {
                ...row,
                latitude,
                longitude,
              },
            });
          }
        });
      }
      this.setState({
        selectedId: row.id || null,
        selectedEvent: row,
        selectedStartDate: row.start_timestamp,
        selectedEndDate: row.end_timestamp,
        selectedAddressName: row.formatted_address,
        selectedLocationType: row.url ? 'online' : 'address',
        selectedTickets: row.admission_options || [],
        eventImage: row.image_url || undefined,
      });
    }
    this.setState({ [this.keyForType(type)]: true });
  };

  onSaveModal = (type) => {
    switch (type) {
      case 'event': {
        const { selectedEvent, selectedStartDate, selectedEndDate, selectedLocationType } = this.state;
        if (selectedLocationType === 'address') {
          selectedEvent.url = null;
        }
        if (selectedLocationType === 'online') {
          selectedEvent.formatted_address = null;
        }
        const organizer = this.getSelectedOrganizer();
        if (organizer) {
          selectedEvent[`${organizer.type}_id`] = organizer.value;
          selectedEvent.coordinator_type = organizer.type;
        }
        if (selectedEvent.id) {
          axios.put(`${Constants.apiPath}/events/${selectedEvent.id}`, {
            ...selectedEvent,
            start_timestamp: selectedStartDate,
            end_timestamp: selectedEndDate,
            image_url: this.state.eventImage || undefined,
          }).then((res) => {
            this.handleRowChange(res.data);
            if (this.state.selectedTickets && this.state.selectedTickets.length) {
              const tickets = this.state.selectedTickets.map((ticket) => ({
                ...ticket,
                event_id: selectedEvent.id,
              }));
              axios.post(`${Constants.apiPath}/events/${res.data.id}/tickets`, tickets).then(() => {
                // this.handleRowChange(res.data); // TODO Link the tickets to the data?
                this.setState({ selectedTickets: [] });
                this.onCloseModal('event');
              });
            } else {
              this.onCloseModal('event');
            }
          }).catch((err) => {
            console.log(err);
          });
        } else {
          axios.post(`${Constants.apiPath}/events`, {
            ...selectedEvent,
            start_timestamp: selectedStartDate,
            end_timestamp: selectedEndDate,
            image_url: this.state.eventImage || undefined,
          }).then((res) => {
            const {
              page,
              sizePerPage,
              searchText,
              tableChangeType,
            } = this.state;
            this.handleTableChange(tableChangeType, { page, sizePerPage, searchText });
            if (this.state.selectedTickets && this.state.selectedTickets.length) {
              const tickets = this.state.selectedTickets.map((ticket) => ({
                ...ticket,
                event_id: res.data.id,
              }));
              axios.post(`${Constants.apiPath}/events/${res.data.id}/tickets`, tickets).then(() => {
                // this.handleRowChange(res.data); // TODO Link the tickets to the data?
                this.setState({ selectedTickets: [] });
                this.onCloseModal('event');
              });
            } else {
              this.onCloseModal('event');
            }
          }).catch((err) => {
            console.log(err);
          });
        }
      }
        break;
      case 'import': {
        console.log(`Getting cigar details for ${this.state.selectedUrl}`);
        this.setState({ importing: true });
        axios.get(`${Constants.apiPath}/scrape/event`, {
          params: {
            url: this.state.selectedUrl,
          },
        }).then((res) => {
          console.debug(JSON.stringify(res.data));
          this.setState({ importing: false });
          this.onCloseModal('import');
          if (!this.state.importVitolasOnly) {
            this.onOpenModal('event', res.data);
          }
        }).catch((e) => {
          this.setState({ importing: false });
          console.log(e.message);
        });
      }
        break;
      default:
        this.onCloseModal(type);
        break;
    }
  };

  itemFormatter = (items, row) => {
    if (items) {
      return (
        <label>
          {items.map((item) => <p>{`${item.quantity}x ${item.name}`}</p>)}
        </label>
      );
    }
    return null;
  };

  itemQuantityFormatter = (items, row) => {
    if (items) {
      return (
        <label>{items.length}</label>
      );
    }
    return null;
  };

  actionDropdown = (index, product) => (
    <RowMoreOptions
      row={index}
      items={[{
        type: 'MenuItem',
        onClick: () => {
        },
        title: 'TODO',
      }]}
      onShow={() => {
        // Hide the row buttons
        document.querySelector(`#dropdown-actions-${index}`).classList.add('d-none');
      }}
    />
  );

  imageFormatter = (cell, row) => {
    if (cell) {
      const imageUrl = cell.replace('http://', 'https://');
      return (
        <FancyBox
          thumbnail={imageUrl}
          image={imageUrl}
        />
      );
    }
    return null;
  };

  organizerFormatter = (cell, row, index) => {
    console.log(`Index rendered: ${index}`);
    if (row.venue) {
      return (
        <span>{row.venue.name}</span>
      );
    }
    if (row.brand) {
      return (
        <span>{row.brand.name}</span>
      );
    }
    if (row.user) {
      return (
        <span>{row.user.name || row.user.full_name}</span>
      );
    }
    return 'None';
  };

  dateFormatter = (cell, row, index) => renderEventTimestamp(cell);

  editBtnFormatter = (cell, row, index) => {
    console.log(`Index rendered: ${index}`);
    return (
      <div
        style={{
          width: 68,
          float: 'right',
        }}
      >
        <div id={`dropdown-actions-${index}`} className="d-none">
          <i
            className="icon-pencil ml-1 mr-3"
            style={{ cursor: 'pointer' }}
            onClick={() => this.onOpenModal('event', row)}
          />
          {this.actionDropdown(index, row)}
        </div>
      </div>
    );
  };

  handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit, searchText }) => {
    console.log('Updating table...');
    this.props.getAllEvents(page, sizePerPage, searchText);

    this.setState({
      tableChangeType: type,
      page: page || 1,
      sizePerPage,
      searchText,
    });
  };

  closeAlert = () => {
    this.setState({
      message: {
        show: false,
        title: '',
        text: '',
      },
    });
  };

  addressFromComponents = (comp) => {
    const address = {};

    for (let i = 0; i < comp.length; i++) {
      const obj = comp[i];
      // console.log(obj.types);
      if (obj.types.indexOf('street_number') !== -1) {
        address.street_number = obj.long_name;
      } else if (obj.types.indexOf('route') !== -1) {
        address.street = `${address.street_number} ${obj.long_name}`;
      } else if (obj.types.indexOf('locality') !== -1 || obj.types.indexOf('postal_town') !== -1) {
        address.city = obj.long_name;
      } else if (obj.types.indexOf('administrative_area_level_1') !== -1) {
        address.state = obj.short_name;
      } else if (obj.types.indexOf('postal_code') !== -1) {
        address.zip = obj.short_name;
      } else if (obj.types.indexOf('country') !== -1) {
        address.country = obj.long_name;
      }
    }
    return address;
  };

  eventModal = () => {
    const event = this.state.selectedEvent;

    return (
      <Modal
        isOpen={this.state.showCreateEventModal}
        onClosed={() => this.onCloseModal('event')}
        toggle={() => this.onCloseModal('event')}
        // style={{ maxWidth: 600 }}
        className="modal-dialog-sm"
        fade
      >
        <div className="modal-header">
          <h5 className="modal-title">New Event</h5>
          <Button className="close" color="" onClick={() => this.onCloseModal('event')}>
            <Icon name="x" />
          </Button>
        </div>
        <ModalBody>
          <FormGroup>
            <Label for="dropzone">Image</Label>
            <div id="event-dropzone">
              <Dropzone
                id="eventImage"
                ref={(ref) => this.eventImage = ref}
                config={{
                  postUrl: 'no-url',
                  iconFiletypes: ['.jpg', '.png'],
                }}
                djsConfig={{
                  dictDefaultMessage: 'Add images or drop here to upload',
                  previewTemplate: djsPreviewTemplate,
                }}
                eventHandlers={this.dropzoneEventHandlers('eventImage')}
              />
            </div>
            <span style={{ color: '#999999' }}>Image needs to be at least 500 x 500 pixels.</span>
          </FormGroup>
          <FormGroup>
            <div
              style={{
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <Label for="name">Event Name</Label>
              <Input id="name" value={this.state.selectedEvent.name} onChange={this.handleEventChange('name')} />
            </div>
          </FormGroup>
          <FormGroup>
            <div
              style={{
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <Label for="description">Description</Label>
              <textarea
                className="form-control"
                id="description"
                style={{ height: 125 }}
                value={this.state.selectedEvent.description}
                onChange={this.handleEventChange('description')}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="description">Organizer (Brand, Venue, or User)</Label>
            <div
              style={{
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <AsyncCreatableSelect
                value={this.getSelectedOrganizer()}
                onChange={this.handleSelectedOrganizerChange}
                getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option}`}
                isOptionSelected={(option) => this.getSelectedOrganizer() && (this.getSelectedOrganizer().value === option.value)}
                loadOptions={this.loadOrganizers}
              />
            </div>
          </FormGroup>
          <FormControl component="fieldset">
            <Label for="description">Location</Label>
            <RadioGroup
              row
              aria-label="location_type type"
              name="location_type"
              value={this.state.selectedLocationType}
              onChange={this.handleRadioChange('selectedLocationType')}
            >
              {/* TODO Only if selected organizer type is venue & location.formatted_address + location.coordinates[lat/lng] exist */}
              {/* FIXME This option would use the geom from venue or need to lookup/sync lat + lng from our db or Google */}
              {/* <FormControlLabel */}
              {/*  value="venue" */}
              {/*  control={<Radio color="primary" />} */}
              {/*  label="At Venue Address" */}
              {/* /> */}
              <FormControlLabel
                value="address"
                control={<Radio color="primary" />}
                label={false ? 'At Other Address' : 'At Address'}
              />
              <FormControlLabel
                value="online"
                control={<Radio color="primary" />}
                label="Online / Virtual"
              />
            </RadioGroup>
          </FormControl>
          {this.state.selectedLocationType === 'address' && (
            <FormGroup>
              <Label for="description">Event Address</Label>
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <Autocomplete
                  // apiKey={config.google.api_key}
                  style={{ width: '100%' }}
                  className="form-control"
                  placeholder="Start typing..."
                  value={this.state.selectedAddressName}
                  // onChange={this.handleInputChange('selectedAddressName')}
                  onPlaceSelected={(place) => {
                    console.log(place);

                    const lat = place.geometry.location.lat();
                    const lng = place.geometry.location.lng();
                    const comp = place.address_components;

                    const address = this.addressFromComponents(comp);
                    console.log(address);
                    address.latitude = lat;
                    address.longitude = lng;
                    address.formatted_address = place.formatted_address;
                    const prevEvent = this.state.selectedEvent;
                    this.setState({
                      selectedAddressName: place.formatted_address,
                      selectedEvent: {
                        ...prevEvent,
                        formatted_address: place.formatted_address,
                        latitude: lat,
                        longitude: lng,
                        city: address.city,
                        state: address.state,
                      },
                    });
                  }}
                  types={[]}
                  fields={[
                    'name',
                    'address_components',
                    'geometry.location',
                    'place_id',
                    'formatted_address',
                  ]}
                  // componentRestrictions={{country: "us"}}
                />
              </div>
            </FormGroup>
          )}
          {this.state.selectedLocationType === 'online' && (
            <FormGroup>
              <Label for="url">Event Link</Label>
              <Input id="url" value={this.state.selectedEvent.url} onChange={this.handleEventChange('url')} />
            </FormGroup>
          )}
          <Row
            style={{
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <Col>
              <FormGroup>
                <Label for="picker-start-time">Start Time</Label>
                <div id="picker-start-time">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      inputVariant="outlined"
                      className="form-control"
                      value={this.state.selectedStartDate}
                      onChange={this.handleDateChange('selectedStartDate')}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="picker-end-time">End Time</Label>
                <div id="picker-end-time">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      inputVariant="outlined"
                      className="form-control"
                      value={this.state.selectedEndDate}
                      onChange={this.handleDateChange('selectedEndDate')}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => this.onOpenModal('tickets')}>Manage Tickets</Button>
          <Button onClick={() => this.onCloseModal('event')}>Cancel</Button>
          <Button onClick={() => this.onSaveModal('event')} variant="contained" color="secondary">Publish Event</Button>
        </ModalFooter>
      </Modal>
    );
  };

  renderTicketRow = (index) => {
    const ticket = this.state.selectedTickets[index] || {};
    console.debug('Selected ticket:');
    console.debug(ticket);
    return (
      <div
        className="row"
        style={{
          marginLeft: 10,
          marginRight: 10,
        }}
      >
        <div className="col-md-7">
          <span>Ticket Name</span>
          <input
            className="form-control"
            value={ticket.name}
            onChange={this.handleSelectedTicketChange(index, 'name')}
          />
        </div>
        <div className="col-md-4">
          <span>Ticket Price (Enter 0 for Free)</span>
          <input
            className="form-control"
            value={ticket.price}
            onChange={this.handleSelectedTicketChange(index, 'price')}
          />
        </div>
        {ticket.id && (
          <div
            style={{
              lineHeight: '60px',
              marginTop: 8,
            }}
          >
            <Icon
              name="trash"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                const tickets = [...this.state.selectedTickets];
                for (let i = tickets.length - 1; i > -1; i--) {
                  if (tickets[i].id === ticket.id) {
                    tickets.splice(i, 1);
                  }
                }
                this.setState({ selectedTickets: tickets });
              }}
            />
          </div>
        )}
        <div className="col-md-11" style={{ marginTop: 10 }}>
          <span>Purchase URL (Optional)</span>
          <input
            className="form-control"
            value={ticket.url}
            onChange={this.handleSelectedTicketChange(index, 'url')}
          />
        </div>
      </div>
    );
  };

  renderTickets = () => {
    const tickets = [...this.state.selectedTickets];
    if (!isMobile) {
      tickets.push({}); // Adds an extra empty object to the end
    }
    return (
      <div className="linked-cigar-block">
        {
          tickets.map((ticket, index) => this.renderTicketRow(index))
        }
        {/* <button type="button" className="btn btn-outline-primary" onClick={() => {}}>Add Another</button> */}
      </div>
    );
  };

  addTicketModal = () => (
    <EventTicketEditor
      open={this.state.showAddTicketModal}
      vitola={{}}
      onClose={() => this.onCloseModal('ticket')}
      onSave={(ticket) => {
        this.setState((prevState) => ({ selectedTickets: [...prevState.selectedTickets, ticket] }));
        this.onSaveModal('ticket');
      }}
    />
  );

  ticketsModal = () => (
    <Modal
      isOpen={this.state.showTicketsModal}
      onClosed={() => this.onCloseModal('tickets')}
      toggle={() => this.onCloseModal('tickets')}
    >
      <div className="modal-header">
        <h5 className="modal-title">Edit Tickets / Admission Options</h5>
      </div>
      <div className="modal-body md">
        {this.renderTickets()}
        {isMobile && (
          <Button onClick={() => this.onOpenModal('ticket')}>Add Ticket</Button>
        )}
      </div>
      <div className="modal-footer">
        <Button onClick={() => this.onCloseModal('tickets')}>Cancel</Button>
        <Button onClick={() => this.onSaveModal('tickets')} variant="contained" color="secondary">Save</Button>
      </div>
    </Modal>
  );

  handleDateChange = (field) => (newDate) => {
    console.log(newDate);
    this.setState({
      [field]: newDate,
    });
  };

  dropzoneEventHandlers(holder) {
    const self = this;
    console.log(holder);
    return {
      addRemoveLinks: true,
      accept(file, done) {
        console.log('File:');
        console.log(file);
        console.log('uploaded');
        done();
      },
      init: (dropzone) => {
        // Dropzone.autoDiscover = false;
        console.log('Initialized dropzone');
        console.log(dropzone);
        console.log(this.state);
        const image = this.state[holder];
        if (image) {
          const imageFile = {
            name: image,
            size: 0,
          };
          dropzone.emit('addedfile', imageFile);
          dropzone.emit('thumbnail', imageFile, image);
          dropzone.emit('complete', imageFile);
          dropzone.files.push(imageFile);

          // FIXME This doesn't always hide
          // Hide the message
          setTimeout(() => {
            console.log(dropzone.element);
            const messageDiv = dropzone.element.closest('.dz-message');
            if (messageDiv) {
              messageDiv.style.display = 'none';
            } else {
              dropzone.element.children[0].style.display = 'none';
            }
          }, 500);
        }
      },
      drop: (event) => {
        console.log('Dropped event:');
        console.log(event);
        console.log(event.target);
        event.target.style.display = 'none';
        const messageDiv = this[holder].dropzone.element.closest('.dz-message');
        if (messageDiv) {
          messageDiv.style.display = 'none';
        } else {
          this[holder].dropzone.element.children[0].style.display = 'none';
        }
        this.setState({
          activeDropzone: this[holder],
          activeDropzoneId: holder,
        });
      },
      addedfile(file) {
        console.log('Dropzone files:');
        console.log(this.files);

        console.log('Added file');
        console.log(file);

        if (this.files.length > 0) {
          if (this.files[0]) {
            const { status } = JSON.parse(JSON.stringify(this.files[0]));
            console.log('Status: ', status);
            if (status !== 'queued' && status !== 'added') {
              console.log('Deleting file...');
              this.removeFile(this.files[0]);
            }
          }
        }

        if (file && this.files.length === 1) {
          console.log(file);
          console.log('Loading file...');
          const fileReader = new FileReader();
          fileReader.onload = (event) => {
            console.log('Loaded file.');
            const dataUrl = event.target.result;
            console.log(dataUrl);
            self.setState({
              selectedImageFile: dataUrl,
              showMediaCropper: true,
            });
          };
          fileReader.readAsDataURL(file);
        }
      },
      success: (file, response, error) => {
        console.log(response);
      },
    };
  }

  renderMediaCropper = () => {
    const {
      selectedImageFile,
      showMediaCropper,
      activeDropzone,
      activeDropzoneId,
    } = this.state;
    return (
      <MediaCropper
        src={selectedImageFile}
        open={showMediaCropper}
        toggle={() => this.setState({ showMediaCropper: !showMediaCropper })}
        onClose={() => this.setState({ showMediaCropper: false })}
        onSave={(croppedCanvas) => {
          const base64 = croppedCanvas.toDataURL();
          this.setState({
            showMediaCropper: false,
            selectedImage: base64, // FIXME Need to know which image this is for?
          });
          croppedCanvas.toBlob((blob) => {
            this.forceUpdate();

            console.log('Canvas blob');
            console.log(blob);
            console.log(activeDropzone);
            console.log(activeDropzoneId);
            const fileReader = new FileReader();
            fileReader.onload = (event) => {
              const dataUrl = event.target.result;
              console.log(dataUrl);
              const { dropzone } = activeDropzone;
              dropzone.emit('addedfile', blob);
              dropzone.emit('thumbnail', blob, dataUrl);
              dropzone.emit('complete', blob);
              dropzone.files.push(blob);
            };
            fileReader.readAsDataURL(blob);

            this.uploadMedia(blob, base64.split(';')[0].split('/')[1], activeDropzoneId);
          }/* , 'image/png' */);
        }}
      />
    );
  };

  importModal = () => (
    <Modal
      isOpen={this.state.showImportModal}
      onClosed={() => this.onCloseModal('import')}
      toggle={() => this.onCloseModal('import')}
    >
      <div className="modal-header">
        <h5 className="modal-title">Import Cigars</h5>
      </div>
      <div className="modal-body">
        {'URL'}
        {' '}
        <i className="icon-info-alt" id="urlTooltip" />
        <Tooltip
          placement="top"
          isOpen={this.state.showUrlTooltip}
          target="urlTooltip"
          toggle={this.toggleTooltip('showUrlTooltip')}
          style={{ width: 300 }}
        >
            Importable URLs include:
          {' '}
          <br />
          <br />
          <ul>
            <li>https://allevents.in/[*city*]/[*]</li>
          </ul>
        </Tooltip>
        <input
          className="form-control"
          type="text"
          name="import-url"
          value={this.state.selectedUrl}
          onChange={this.handleInputChange('selectedUrl')}
        />
      </div>
      <div className="modal-footer">
        <Button onClick={() => this.onCloseModal('import')}>Cancel</Button>
        <Button
          onClick={() => this.onSaveModal('import')}
          variant="contained"
          color="secondary"
          disabled={this.state.importing}
        >
          {this.state.importing && <Spinner color="light" style={{ marginRight: 8 }} />}
          {this.state.importing ? 'Importing' : 'Import'}
        </Button>
      </div>
    </Modal>
  );

  render() {
    const { sizePerPage, page, events } = this.state;
    const { totalEvents, loading } = this.props.EventManager;

    const columns = [{
      dataField: 'image_url',
      text: 'Image',
      formatter: this.imageFormatter,
      sort: false,
    }, {
      dataField: 'name',
      text: 'Name',
      sort: true,
      editable: false,
    }, {
      dataField: 'organizer',
      text: 'Organizer',
      sort: true,
      editable: false,
      formatter: this.organizerFormatter,
    }, {
      dataField: 'start_timestamp',
      text: 'Start',
      sort: true,
      editable: false,
      formatter: this.dateFormatter,
    }, {
      dataField: 'end_timestamp',
      text: 'End',
      sort: true,
      editable: false,
      formatter: this.dateFormatter,
    }, {
      dataField: 'edit',
      text: '',
      formatter: this.editBtnFormatter,
      sort: true,
    }];

    const rowEvents = {
      onMouseEnter: (e, row, index) => {
        // console.log("Mouse entered: " + index);
        document.querySelector(`#dropdown-actions-${index}`).classList.remove('d-none');
      },
      onMouseLeave: (e, row, index) => {
        document.querySelector(`#dropdown-actions-${index}`).classList.add('d-none');
      },
      onDoubleClick: (e, row, index) => {
        // INFO If we don't include this event, the double click to edit doesn't work
        // console.log(e);
      },
    };

    const pageButtonRenderer = ({ page, active, onPageChange }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      let classname = 'btn btn-outline-secondary';
      if (active) {
        classname = 'btn btn-secondary';
      }
      return (
        <li className="page-item pl-1" key={page}>
          <a href="#" onClick={handleClick} className={classname}>{page}</a>
        </li>
      );
    };

    // TODO https://www.npmjs.com/package/react-device-detect use a different layout for mobile
    return (
      <div>
        <SweetAlert
          show={this.state.message.show}
          type={this.state.message.type}
          title={this.state.message.title}
          onConfirm={this.closeAlert}
        >
          {this.state.message.text}
        </SweetAlert>

        <Breadcrumb title="Events" label="Events" parent="Home" />

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body datatable-react">
                  <ToolkitProvider
                    keyField="id"
                    data={events}
                    columns={columns}
                    search
                  >
                    {
                      (toolkitprops) => (
                        <div>
                          {/* Known issue https://github.com/react-bootstrap-table/react-bootstrap-table2/issues/787 */}
                          <SearchBar {...toolkitprops.searchProps} delay={800} />
                          {/* <ClearSearchButton { ...props.searchProps } /> */}
                          <button
                            className="btn btn-primary mr-2"
                            onClick={() => {
                              this.onOpenModal('event');
                            }}
                            style={isMobile ? {
                              padding: '6px 14px',
                              float: 'right',
                            } : { float: 'right' }}
                          >
                            {isMobile ? (<i className="icon icon-plus" />) : 'Create New Event'}
                          </button>
                          <button
                            className="btn btn-primary mr-2"
                            onClick={() => this.onOpenModal('import')}
                            style={isMobile ? {
                              padding: '6px 14px',
                              float: 'right',
                            } : { float: 'right' }}
                          >
                            {isMobile ? (<i className="icon icon-import" />) : 'Import Event'}
                          </button>
                          <PaginationProvider
                            pagination={paginationFactory({
                              pageButtonRenderer,
                              page,
                              sizePerPage,
                              // withFirstAndLast: false,
                              custom: true,
                              totalEvents,
                              showTotal: true,
                            })}
                          >
                            {
                              ({ paginationProps, paginationTableProps }) => (
                                <div>
                                  <div style={{ marginLeft: 10, float: 'left' }}>
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <BootstrapTable
                                    remote
                                    keyField="id"
                                    data={events}
                                    columns={columns}
                                    rowEvents={rowEvents}
                                    defaultSorted={defaultSorted}
                                    wrapperClasses="table-responsive"
                                    cellEdit={cellEditFactory(cellEditProps)}
                                    // filter={ filterFactory() }
                                    {...paginationTableProps}
                                    onTableChange={this.handleTableChange}
                                    noDataIndication={() => (!loading
                                      ? <NoDataIndicator message="No events found matching search query." />
                                      : <TableLoader />)}
                                    {...toolkitprops.baseProps}
                                  />
                                  <PaginationTotalStandalone
                                    {...paginationProps}
                                  />
                                  <div style={{ width: 200, display: 'inline-block', float: 'right' }}>
                                    <Pagination
                                      hideNavigation
                                      page={page}
                                      sizePerPage={sizePerPage}
                                      totalSize={totalEvents}
                                      handlePageChange={this.handlePageChange}
                                      onShowPagePicker={() => this.onOpenModal('page')}
                                    />
                                  </div>
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              )
                            }
                          </PaginationProvider>
                        </div>
                      )
                    }
                  </ToolkitProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.eventModal()}
        {this.addTicketModal()}
        {this.ticketsModal()}
        {this.importModal()}
        {this.renderMediaCropper()}
      </div>
    );
  }
}

const mapStateToProps = ({ EventManager }) => ({ EventManager });

export default connect(
  mapStateToProps, {
    getAllEvents,
  },
)(EventsTable);
