import React from 'react';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Toast from 'cogo-toast';
import { connect } from 'react-redux';
import { Button as BSButton, Card, Collapse, Input, InputGroup, InputGroupAddon, Modal } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { EditorState } from 'draft-js';
import '../../assets/css/linked-cigars.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Button from '@material-ui/core/Button';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import AsyncCreatableSelect from 'react-select/async-creatable/dist/react-select.esm';
import Select from 'react-select';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Hashids from 'hashids';
import { Link } from 'react-router-dom';
import cartActions from '../../utils/cart';
import { usStates } from '../../utils/geographyUtils';
import { Constants } from '../../constants';
import Placeholder from '../../config/placeholder.config';
import PaymentInputs from '../../components/payment-input';
import Icon from '../../components/icon';
import NoDataIndicator from '../../components/no-data-indicator';
import TableLoader from '../../components/table-loader';
import RowMoreOptions from '../../components/table/row-more-options';
import { getAllOrders } from '../../redux/actions/shop.actions';
import Breadcrumb from '../../components/common/breadcrumb.component';

const hashids = new Hashids('', 12);

const env = process.env.NODE_ENV || 'development';
const config = require('../../config/config.json')[env];

const { SearchBar } = Search;

const defaultSorted = [{
  dataField: 'name',
  order: 'asc',
}];

const cellEditProps = {
  mode: 'dbclick',
};

const defaults = {
  editorState: EditorState.createEmpty(),
  selectedId: null,
  selectedUrl: null,
  selectedProduct: {},
  selectedCategories: null,
  selectedPackageType: null,
  selectedPackageQuantity: 1,
  message: {
    type: 'info',
    show: false,
    title: '',
    text: '',
  },
};

let unsubscribe;

const Fulfillment = {
  unknown: 'unknown',
  processed: 'processed',
  pre_transit: 'pre_transit',
  in_transit: 'in_transit',
  out_for_delivery: 'out_for_delivery',
  delivered: 'delivered',
  available_for_pickup: 'available_for_pickup',
  return_to_sender: 'return_to_sender',
  failure: 'failure',
  cancelled: 'cancelled',
  error: 'error',
};

/**
 * TODO Maybe make this an enum to use in test suite
 * Sample Tracking No.s for testing:
 * EZ1000000001     pre_transit
 * EZ2000000002     in_transit
 * EZ3000000003     out_for_delivery
 * EZ4000000004     delivered
 * EZ5000000005     return_to_sender
 * EZ6000000006     failure
 * EZ7000000007     unknown
 */

class ShopTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      // data: data.data,
      // totalSize: data.recordsFiltered,
      sizePerPage: 10,
      showCreateOrderModal: false,
      showFilterDrawer: false,
      selectedStatusFilter: {},
      showStatusFilter: true,
      showDateFilter: true,
      linkedCigars: [],
      vitolaOptions: [],
      showAvailability: 'all',
      showMissingLinkedCigars: false, // These 3 options are set to ONLY show the missing options. Setting to false shows all
      showMissingImages: false,
      showMissingPrice: false,
      showDiscountModal: false,
      showShippingModal: false,
      showFulfillmentModal: false,
      showTrackingInfoModal: false,
      showCreditCardModal: false,
      showHumidorPickerModal: false,
      selectedShop: null,
      selectedOrder: {
        cart: {
          billingAddress: {},
          shippingAddress: {},
        },
      },
      customer: null,
      ...defaults,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  componentDidMount() {
    this.handleTableChange(null, {
      page: 1,
      sizePerPage: 30,
    });
  }

  componentWillUnmount() {
    this.clearCart();
    if (typeof unsubscribe === 'function') {
      unsubscribe();
    }
  }

  toggleTooltip(key) {
    return () => {
      this.setState({
        [key]: !this.state[key],
      });
    };
  }

  dropzoneEventHandlers(holder) {
    const self = this;

    return {
      init(dropzone) {
        // Dropzone.autoDiscover = false;
        console.log('Initialized dropzone');
        console.log(dropzone);
        console.log(self.state);
        const image = self.state[holder];
        if (image) {
          const imageFile = {
            name: image,
            size: 0,
          };
          dropzone.emit('addedfile', imageFile);
          dropzone.emit('thumbnail', imageFile, image);
          dropzone.emit('complete', imageFile);
          dropzone.files.push(imageFile);

          // Hide the message
          dropzone.element.children[0].style.display = 'none';
        }
      },
      drop() {

      },
      // removedfile: self.handleRemoveUpload,
      // error: self.handleErrorUpload,
      // success: self.handleSuccessUpload
    };
  }

  imageFormatter = (cell, row, index) => {
    // console.log("Image:");
    // console.log(cell);
    if (cell) {
      if (cell.indexOf('http') === -1) {
        cell = `https://${cell}`;
      }
      return (
        <img
          style={{ maxWidth: 75 }}
          src={cell}
          onClick={() => {
          // console.log(cigar.images);
          // this.setState({photoImages: cigar.images, photoIndex: 0, openLightbox: true}) // TODO How to get correct image position? See above
          }}
        />
      );
    }
    return (<div />);
  };

  getProductImage = (product) => {
    let imageUrl = null; // defaultImageUrl;

    // console.log('Getting image for product');
    // console.log(product);

    if (product.image_url) {
      if (product.image_url.indexOf('http') === -1) {
        imageUrl = `https://${product.image_url}`;
      } else {
        imageUrl = product.image_url;
      }
    } else if (product.images && product.images.length) {
      product.images.forEach((image) => {
        if (image.url.indexOf('http') === -1) {
          imageUrl = `https://${image.url}`;
        } else {
          imageUrl = image.url;
        }
      });
    }

    return imageUrl;
  };

  formatProductName = (product) => {
    let { name } = product;
    const { packaging, contents } = product;
    if (contents && contents.length === 1) {
      const { vitola } = contents[0];
      if (vitola) {
        name += ` ${vitola.formatted_name}`;
      }
    }
    if (packaging) {
      if (packaging.type === 'Single') {
        name += ` - ${packaging.type}`;
      } else {
        name += ` - ${packaging.type} of ${packaging.quantity}`;
      }
    }
    return name;
  };

  formatProductPrice = (product) => {
    console.log('Formatting product price:');
    console.log(product);
    let price = product.msrp || 0;
    if (product.sale_price) {
      price = product.sale_price;
    }
    return `$${this.formatDecimal(price)}`;
  };

  formatDecimal = (price) => {
    if (typeof price === 'string') {
      price = parseFloat(price);
    }
    return price.toFixed(2);
  };

  createCart = () => {
    const { customer, billingAddress, shippingAddress } = this.state;
    const { cart } = this.props.CartManager;

    // cartActions.update({
    //   ...cart,
    //   email: customer.email,
    //   user: customer,
    //   ip_address: '0.0.0.0', // FIXME Is this required? We don't have their IP address unless it was passed in chat/support, so just 0.0.0.0?
    //   billing_address: billingAddress,
    //   shipping_address: shippingAddress,
    // });
    alert('Coming soon');
  };

  addProduct = (product) => {
    console.log('Adding product:');
    console.log(product);
    cartActions.addProduct({
      // id: hashids.encode(product.id),
      product_id: product.id,
      metadata: {
        reference: product.reference,
      },
      name: this.formatProductName(product),
      price: product.sale_price || product.msrp || 0,
      image: this.getProductImage(product),
      url: `${config.shopEndPoint}/products/${hashids.encode(product.id)}/verify`,
      quantity: product.qty || 1,
    });
  };

  createDiscount = () => {
    // TODO Should be able to dynamically create a discount here?
  };

  applyDiscount = (code) => {
    cartActions.applyDiscount(code);
  };

  removeDiscount = (code) => {
    cartActions.removeDiscount(code);
  };

  processCreditCard = () => {
    // FIXME What here? For taking a card over the phone or something? Right now, not possible
    //  Should use NMI now
  };

  removeProduct = (lineItem) => {
    cartActions.removeProduct(lineItem.unique_id);
  };

  clearCart = () => {
    // TODO Should this remove all line items or create a new cart?
  };

  loadProducts = (inputValue, callback) => {
    axios.get(`${Constants.apiPath}/shop/products/search`, {
      params: {
        q: inputValue,
        limit: 50,
      },
    }).then((response) => {
      callback(response.data.map((product) => ({
        ...product,
        value: product.id,
        label: this.formatProductName(product),
      })));
    });
  };

  loadCustomers = (inputValue, callback) => {
    axios.get(`${Constants.apiPath}/shop/customers`, {
      params: {
        q: inputValue,
        limit: 10,
      },
    }).then((response) => {
      console.log(response);
      callback(response.data.map((user) => ({
        ...user,
        value: user.id,
        label: user.full_name,
      })));
    }).catch((err) => {
      console.log(err);
    });
  };

  handleChange = (key) => (value) => {
    this.setState({ [key]: value });
  };

  handleProductChange = (key) => (event) => {
    const product = this.state.selectedProduct;
    product[key] = event.target.value;
    this.setState({ selectedProduct: product });
  };

  handleEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };

  handleInputChange = (key) => (event) => {
    console.log(key);
    console.log(event.target.value);
    this.setState({ [key]: event.target.value });
  };

  handleAddressChange = (key) => (event) => {
    console.log(key);
    console.log(event.target.value);
    // FIXME Should update locally - not in the actual Snipcart store
    // if (this.state.editingShippingAddress) {
    //     this.setState({
    //         shippingAddress: {
    //             ...this.state.shippingAddress,
    //             [key]: event.target.value
    //         }
    //     });
    // } else {
    //     this.setState({
    //         billingAddress: {
    //             ...this.state.billingAddress,
    //             [key]: event.target.value
    //         }
    //     });
    // }
  };

  handleSelectChange = (key) => (value) => {
    this.setState({ [key]: value });
  };

  handleCheckboxChange = (key) => {
    console.log('Check change');
    return (event) => {
      this.setState({ [key]: event.target.checked });
    };
  };

  handleRadioChange = (key) => (event) => {
    this.setState({ [key]: event.target.value });
  };

  handleLinkedCigarChange = (index, type) => {
    const { linkedCigars } = this.state;
    console.log(linkedCigars);
    const linkedCigar = linkedCigars[index] || {};
    return (value) => {
      linkedCigar[type] = value;
      linkedCigars[index] = linkedCigar;
      this.setState({ linkedCigars }, () => {
        console.log('State updated!');
        if (type === 'cigar') {
          console.log('Running this.loadVitolas(index)...');
          this.loadVitolas(index);
        }
      });
    };
  };

  keyForType = (type) => {
    switch (type) {
      case 'order':
        return 'showCreateOrderModal';
      case 'details':
        return 'showDetailsModal';
      case 'discount':
        return 'showDiscountModal';
      case 'shipping':
        return 'showShippingModal';
      case 'email-cart':
        return 'showEmailCartModal';
      case 'email-cart-preview':
        return 'showCartEmailPreviewModal';
      case 'credit-card':
        return 'showCreditCardModal';
      case 'billing-address':
        return 'showAddressModal';
      case 'shipping-address':
        return 'showAddressModal';
      case 'filter':
        return 'showFilterDrawer';
      case 'tracking-info':
        return 'showTrackingInfoModal';
      case 'fulfillment':
        return 'showFulfillmentModal';
      case 'refund':
        return 'showRefundModal';
      case 'humidors':
        return 'showHumidorPickerModal';
      default:
        return '';
    }
  };

  onCloseModal = (type) => {
    if (type === 'order') {
      this.clearCart();
      // FIXME Also include the following if necessary - it would be nice to just wipe the entire cart with a new one (and new token ID)
      // this.clearShipping();
      // this.clearDiscounts();
      // this.clearAddresses();
    } else if (type === 'tracking-info') {
      this.setState({
        selectedFulfillment: null,
      });
    }
    this.setState({
      [this.keyForType(type)]: false,
    });
  };

  onOpenModal = (type, row) => {
    this.setState({ [this.keyForType(type)]: true });
  };

  onSaveModal = (type) => {
    switch (type) {
      case 'order': {
        // TODO Save with draft state
      }
        break;
      case 'shipping': {
        const { selectedShippingRate } = this.state;
        console.log('Setting shipping inforation:');
        console.log(selectedShippingRate);
        let rate = {};
        this.state.cart.shippingRates.items.forEach((shippingRate) => {
          if (shippingRate.id === selectedShippingRate.id) {
            rate = shippingRate;
          }
        });
        // if (window.Snipcart) {
        //   window.Snipcart.api.cart.setShippingInformation({
        //     method: rate.slug,
        //     cost: rate.cost,
        //   });
        // }
        // FIXME If we have the shipping address info, include it here otherwise leave blank
        cartActions.setShippingInformation(rate);
        this.onCloseModal(type);
      }
        break;
      case 'discount': {
        const { selectedDiscountCode } = this.state;
        if (selectedDiscountCode) {
          this.applyDiscount(selectedDiscountCode);
        }
        this.onCloseModal(type);
      }
        break;
      case 'email-cart': {
        // FIXME This should use something like https://www.npmjs.com/package/handlebars-react or https://github.com/danakt/handlebars-to-jsx
        //  to compile an example of the email to send? Should it be in an iframe so styles don't conflict?
        this.setState({
          showCartEmailPreviewModal: true,
        });
        this.onCloseModal(type);
      }
        break;
      case 'email-cart-preview': {
        const { cart } = this.props.CartManager;
        // TODO Send the email or trigger the server to send it - maybe the PUT/POST for a draft order (admin server only) should have a flag with the email {}?
        cartActions.confirm({
          ...cart,
          status: 'draft', // FIXME Will this always be the case? Since this is sending the cart for payment, I would assume so
        });
        this.onCloseModal(type);
      }
        break;
      case 'refund': {
        const { selectedOrder, totalRefund } = this.state;
        this.setState({ submitting: true });
        const refund = {
          amount: totalRefund,
        };
        axios.post(`${Constants.apiPath}/shop/orders/${selectedOrder.id}/refunds`, refund).then(() => {
          this.setState({ submitting: false });
          this.onCloseModal(type);
        }).catch((err) => {
          this.setState({ submitting: false });
          console.log(err);
        });
      }
        break;
      case 'fulfillment': {
        this.setState({ submitting: true });
        const { selectedOrder, trackingNumber, fulfillmentStatus, fulfillmentContents } = this.state;
        const fulfillment = {
          // order_id: selectedOrder.id, // INFO This isn't really necessary, it gets set server-side
          contents: fulfillmentContents.map((content) => ({
            quantity: parseInt(content.fulfill_count),
            product_id: content.product.id,
          })),
          status: fulfillmentStatus,
        };
        if (trackingNumber) {
          fulfillment.tracking_number = trackingNumber;
        }
        console.log('Creating fulfillment:');
        console.log(fulfillment);
        axios.post(`${Constants.apiPath}/shop/orders/${selectedOrder.id}/fulfillments`, fulfillment).then((res) => {
          selectedOrder.fulfillments.push(res.data);
          this.setState({ submitting: false });
          this.onCloseModal(type);
        }).catch((err) => {
          this.setState({ submitting: false });
          console.log(err);
        });
      }
        break;
      case 'tracking-info': {
        const { selectedOrder, selectedFulfillment, trackingNumber } = this.state;
        selectedFulfillment.tracking_number = trackingNumber;
        axios.put(`${Constants.apiPath}/shop/orders/${selectedOrder.id}/fulfillments/${selectedFulfillment.id}`, selectedFulfillment).then((res) => {
          // FIXME Will need to update instead of push
          // selectedOrder.fulfillments.push(res.data);
          this.setState({ submitting: false });
          this.onCloseModal(type);
        }).catch((err) => {
          this.setState({ submitting: false });
          console.log(err);
        });
      }
        break;
      case 'humidors': {
        const { selectedOrder, selectedHumidor } = this.state;
        axios.post(`${Constants.apiPath}/shop/orders/${selectedOrder.id}/humidors`, {}, {
          params: { humidorId: selectedHumidor.value },
        }).then(() => {
          Toast.success('Successfully added', { heading: 'Humidor Entries Added', position: 'top-right' });
          this.setState({ showHumidorPickerModal: false });
        }).catch((err) => {
          console.error(err);
          this.setState({
            message: {
              show: true,
              title: 'There Was A Problem',
              text: 'Unable to add order entries to user\'s humidor',
            },
          });
        });
      }
        break;
      default:
        break;
    }
  };

  soldByFormatter = (cell, row) => {
    console.log('Sold by row:');
    console.log(row);
    const venues = {};
    if (row.cart && row.cart.items) {
      row.cart.items.forEach((item) => {
        if (item.product.venue) {
          venues[item.product.venue.name] = item.product.venue;
        }
      });
      return (
        <span>
          {Object.keys(venues).map((key) => {
            const venue = venues[key];
            return (
              <p>
                <Link to={`/venues/manager?id=${venue.id}`}>{(venue.name) || 'Sigaro Smoke Shop'}</Link>
              </p>
            );
          })}
        </span>
      );
    }
    return null;
  };

  statusFormatter = (status, row) => {
    if (status) {
      return (
        <span>
          {status.replace('_', ' ').split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')}
        </span>
      );
    }
    return null;
  };

  itemFormatter = (items, row) => {
    if (items) {
      return (
        <span>
          {items.map((item) => (
            <p>
              {`${item.quantity}x `}
              <Link
                to={`/shop/manager?id=${item.product.id}`}
              >
                {item.product.name}
              </Link>
            </p>
          ))}
        </span>
      );
    }
    return null;
  };

  itemQuantityFormatter = (items, row) => {
    if (items) {
      return (
        <span>{items.length}</span>
      );
    }
    return null;
  };

  priceFormatter = (cell, row) => {
    if (cell) {
      let price = cell;
      if (typeof price === 'number' || (typeof price === 'string' && price.indexOf('$') === -1)) {
        // FIXME This should be fixed on the server, but since the dev db and prod db aren't configured the same, we need this. Once configured correctly, remove this
        price = `$${parseFloat(cell).toFixed(2)}`;
      }
      return (
        <span>{price}</span>
      );
    }
    return null;
  };

  checkBoxFormatter = (cell, row) => (
    <div className="checkbox checkbox-secondary">
      <input name="checkbox" checked={cell} type="checkbox" />
      <label></label>
    </div>
  );

  actionDropdown = (index, order) => (
    <RowMoreOptions
      row={index}
      items={[{
        type: 'MenuItem',
        onClick: () => this.setState({
          selectedOrder: order,
          showDetailsModal: true,
        }),
        title: 'View Details',
      }, {
        type: 'MenuItem',
        onClick: () => {
          // TODO Loading indicator?
          const userId = order.cart.user_id;
          return axios.get(`${Constants.apiPath}/users/${userId}/humidors`).then((res) => {
            console.log(res.data);
            this.setState({
              userHumidors: res.data.map((humidor) => ({
                value: humidor.id,
                label: humidor.name,
              })),
              selectedOrder: order,
              showHumidorPickerModal: true,
            });
          }).catch((err) => {
            console.error(err);
            this.setState({
              message: {
                show: true,
                title: 'There Was A Problem',
                text: 'Unable to get humidors for user at this time',
              },
            });
          });
        },
        title: 'Add To User\'s Humidor',
      }, {
        type: 'MenuItem',
        onClick: () => {
          alert('Coming soon!');
        },
        title: 'Download Invoice',
      }, {
        type: 'MenuItem',
        onClick: () => {
          alert('Coming soon!');
        },
        title: 'Resend Invoice',
      }, {
        type: 'MenuItem',
        onClick: () => {
          this.setState({
            selectedOrder: order,
            showRefundModal: true,
          });
        },
        title: 'Issue Refund',
      }]}
      onShow={() => {
      // Hide the row buttons
        document.querySelector(`#dropdown-actions-${index}`).classList.add('d-none');
      }}
    />
  );

  editBtnFormatter = (cell, row, index) => {
    console.log(`Index rendered: ${index}`);
    return (
      <div style={{ width: 68 }}>
        <div id={`dropdown-actions-${index}`} className="d-none" style={{ float: 'right' }}>
          {this.actionDropdown(index, row)}
        </div>
      </div>
    );
  };

  handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit, searchText }) => {
    console.log('Updating table...');
    this.props.getAllOrders(page, sizePerPage, {
      // TODO Filter options
    });

    this.setState({
      page,
      sizePerPage,
    });
  };

  closeAlert = () => {
    this.setState({
      message: {
        show: false,
        title: '',
        text: '',
      },
    });
  };

  hasFulfillments = (order) => order.fulfillments && order.fulfillments.length > 0;

  renderDetailRow = (item) => (
    <div style={{ display: 'flex', marginTop: 16, paddingBottom: 8, borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
      <Avatar
        alt={item.product.name}
        src={this.getProductImage(item.product)}
        imgProps={{ style: { objectFit: 'contain' } }}
        style={{
          height: 45,
          width: 45,
          marginTop: 3,
          marginBottom: 3,
          marginRight: 10,
        }}
      >
        <img
          src={Placeholder.cigar}
          style={{
            height: 45,
            width: 45,
          }}
        />
      </Avatar>
      <span
        style={{
          flex: 2,
          lineHeight: '24px',
        }}
      >
        <Link to={`/shop/manager?id=${item.product.id}`}>{item.product.name}</Link>
        <br />
        {`SKU: ${item.product.reference}`}
      </span>
      <span
        style={{
          flex: 1,
          textAlign: 'right',
          lineHeight: '40px',
        }}
      >
        {`$${item.price.toFixed(2)}   x   ${item.quantity}`}
      </span>
      <span
        style={{
          flex: 1,
          textAlign: 'right',
          lineHeight: '40px',
        }}
      >
        {`$${(item.price * item.quantity).toFixed(2)}`}
      </span>
    </div>
  );

  fulfilledItems = (order) => {
    const { cart } = order;
    const fulfilledItems = [];
    const unfulfilledItems = [];
    if (cart.items) {
      for (const item of cart.items) {
        let found = false;
        if (order.fulfillments) {
          for (const fulfillment of order.fulfillments) {
            if (fulfillment.contents) {
              for (const productFulfillment of fulfillment.contents) {
                console.log('Fulfilled product:');
                console.log(productFulfillment);
                // FIXME Need to factor in the count - there might be 3/4 fulfilled but 1 that should still get shown in unfulfilled
                if (productFulfillment.product.id === item.product.id) {
                  found = true;
                  item.fulfillment_id = productFulfillment.id;
                  fulfilledItems.push(item);
                }
              }
            }
          }
        }
        if (!found) {
          unfulfilledItems.push(item);
        }
      }
    }
    return { fulfilledItems, unfulfilledItems };
  };

  getCustomerAddresses = (customer) => {
    // TODO Pull from server
  };

  detailsModal = () => {
    const { selectedOrder } = this.state;
    const { cart, billing_address, shipping_address } = selectedOrder;
    const { user } = cart;
    console.log('Selected order:');
    console.log(selectedOrder);
    const customer = {
      email: user ? user.email : cart.email,
      full_name: user ? user.full_name : 'Guest',
      image_url: user ? user.image_url : Placeholder.avatar,
    };
    const { fulfilledItems, unfulfilledItems } = this.fulfilledItems(selectedOrder);
    return (
      <Modal
        isOpen={this.state.showDetailsModal}
        onClosed={() => this.onCloseModal('details')}
        className="modal-dialog-lg"
      >
        <div className="modal-header">
          <h5 className="modal-title">{`Order #${selectedOrder.order_number} Details`}</h5>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-8">
              {/* FIXME These need grouped by shop when we have multiple merchants selling */}
              {selectedOrder.fulfillments && selectedOrder.fulfillments.map((fulfillment) => (
                (fulfillment.contents && fulfillment.contents.length > 0) && (
                  <Card style={{ padding: 10 }}>
                    <span
                      style={{
                        fontWeight: 700,
                        marginBottom: 6,
                        fontSize: 16,
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: '#ffffff',
                          borderRadius: '50%',
                          border: '5px solid #BBE5B3',
                          height: 28,
                          width: 28,
                          textAlign: 'center',
                        }}
                      >
                        <i
                          style={{
                            fontSize: '20px',
                            color: '#108043',
                            margin: 0,
                            padding: 0,
                            lineHeight: '25px',
                            verticalAlign: 'middle',
                          }}
                          className="fa fa-check-circle-o"
                        />
                      </span>
                      <span style={{ marginLeft: 10 }}>Fulfilled</span>
                    </span>
                    {fulfilledItems.map((item) => this.renderDetailRow(item))}
                    <div
                      style={{
                        marginTop: 20,
                        marginBottom: 10,
                      }}
                    >
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{
                          marginRight: 10,
                          float: 'right',
                        }}
                        onClick={() => this.setState({ /* TODO Confirmation */ })}
                      >
                        {'Cancel Fulfillment'}
                      </button>
                      {/* FIXME Only if has fulfillment for line item and also ! tracking number already */}
                      {!fulfillment.tracking_number && (
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{
                            marginRight: 10,
                            float: 'right',
                          }}
                          onClick={() => this.setState({
                            selectedFulfillment: fulfillment,
                            showTrackingInfoModal: true,
                          })}
                        >
                          {'Add Tracking Info'}
                        </button>
                      )}
                      {fulfillment.tracking_number && (
                        <span>
                          {/* FIXME Both the tracking service (UPS) and tracking URL (from EasyPost) should be grabbed dynamically - for right now, it's always UPS */}
                          <span>UPS tracking</span>
                          <div><a href={`https://wwwapps.ups.com/tracking/tracking.cgi?tracknum=${fulfillment.tracking_number}`} target="_blank" style={{ color: 'rgb(214, 194, 144)' }}>{fulfillment.tracking_number}</a></div>
                        </span>
                      )}
                    </div>
                  </Card>
                )
              ))}
              {(unfulfilledItems && unfulfilledItems.length > 0) && (
                <Card style={{ padding: 10 }}>
                  <span
                    style={{
                      fontWeight: 700,
                      marginBottom: 6,
                      fontSize: 16,
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: '#ffffff',
                        borderRadius: '50%',
                        border: '5px solid #FFEA8A',
                        height: 28,
                        width: 28,
                        textAlign: 'center',
                      }}
                    >
                      <i
                        style={{
                          fontSize: '20px',
                          color: '#9C6F19',
                          margin: 0,
                          padding: 0,
                          lineHeight: '25px',
                          verticalAlign: 'middle',
                        }}
                        className="fa fa-circle-o"
                      />
                    </span>
                    <span style={{ marginLeft: 10 }}>Unfulfilled</span>
                  </span>
                  {unfulfilledItems.map((item) => this.renderDetailRow(item))}
                  <div
                    style={{
                      marginTop: 20,
                      marginBottom: 10,
                    }}
                  >
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{
                        marginRight: 10,
                        float: 'right',
                      }}
                      onClick={() => this.setState({ showFulfillmentModal: true })}
                    >
                      {'Mark As Fulfilled'}
                    </button>
                  </div>
                </Card>
              )}
              <Card style={{ padding: 10 }}>
                <span
                  style={{
                    fontWeight: 700,
                    marginBottom: 6,
                    fontSize: 16,
                  }}
                >
                  {'Paid'}
                </span>
                {selectedOrder.subtotal_price ? (
                  <div style={{ display: 'flex' }}>
                    <span>Subtotal</span>
                    <span
                      style={{
                        flex: 1,
                        textAlign: 'right',
                      }}
                    >
$
                      {selectedOrder.subtotal_price.toFixed(2)}
                    </span>
                  </div>
                ) : null}
                {selectedOrder.tax_price ? (
                  <div style={{ display: 'flex' }}>
                    <span>Tax</span>
                    <span
                      style={{
                        flex: 1,
                        textAlign: 'right',
                      }}
                    >
$
                      {selectedOrder.tax_price.toFixed(2)}
                    </span>
                  </div>
                ) : null}
                {selectedOrder.shipping_price ? (
                  <div style={{ display: 'flex' }}>
                    <span>Shipping</span>
                    <span
                      style={{
                        flex: 1,
                        textAlign: 'right',
                      }}
                    >
                      {selectedOrder.shipping_price.toFixed(2)}
                    </span>
                  </div>
                ) : null}
                {selectedOrder.total_price ? (
                  <div style={{ display: 'flex' }}>
                    <span>Total</span>
                    <span
                      style={{
                        flex: 1,
                        textAlign: 'right',
                      }}
                    >
$
                      {selectedOrder.total_price.toFixed(2)}
                    </span>
                  </div>
                ) : null}
                <div
                  style={{
                    paddingTop: 20,
                    marginTop: 12,
                    marginBottom: 10,
                    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      marginRight: 10,
                      float: 'right',
                    }}
                    onClick={() => {
                      this.setState({
                        showRefundModal: true,
                      });
                    }}
                  >
                    {'Issue refund'}
                  </button>
                  {/* TODO Allow the order to be cancelled if not paid? Will we ever have that case? */}
                  {/* <button */}
                  {/*  type="button" */}
                  {/*  className="btn btn-primary" */}
                  {/*  style={{ */}
                  {/*    marginRight: 10, */}
                  {/*    float: 'right', */}
                  {/*  }} */}
                  {/*  onClick={() => { */}
                  {/*  }} */}
                  {/* > */}
                  {/*  {'Cancel Order'} */}
                  {/* </button> */}
                </div>
              </Card>
              {/* TODO https://github.com/Proskynete/vertical-timeline-component-react? */}
            </div>
            <div className="col-md-4">
              <Card style={{ padding: 10 }}>
                <span
                  style={{
                    fontWeight: 700,
                    marginBottom: 6,
                    fontSize: 16,
                  }}
                >
                  {'Customer'}
                </span>
                {this.renderCustomerDetails(customer, billing_address, shipping_address)}
              </Card>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button onClick={() => this.onCloseModal('details')}>Dismiss</Button>
        </div>
      </Modal>
    );
  };

  emailCartModal = () => {
    const { customer, showEmailCartModal, selectedFromEmail, selectedEmailCustomMessage } = this.state;
    const { cart } = this.props.CartManager;
    return (
      <Modal isOpen={showEmailCartModal} onClosed={() => this.onCloseModal('email-cart')}>
        <div className="modal-header">
          <h5 className="modal-title">Email Cart</h5>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-6">
              <span style={{ fontWeight: 700 }}>To</span>
              <input
                className="form-control"
                name="to-email-address"
                type="email"
                value={(customer && customer.email) || (cart.user && cart.user.email) || null}
                // onChange={this.handleInputChange('selectedName')}
              />
            </div>
            <div className="col-md-6">
              <span style={{ fontWeight: 700 }}>From</span>
              <Select
                value={selectedFromEmail}
                onChange={this.handleSelectChange('selectedFromEmail')}
                options={[{
                  value: 'orders@sigaro-shop.com',
                  label: '"Sigaro Smoke Shop" <orders@sigaro-shop.com>',
                }, {
                  value: 'orders@boxpressd.com',
                  label: '"Boxpressd Orders" <orders@boxpressd.com>',
                }]}
                // getOptionLabel={option =>
                //   `${option.label}`
                // }
                // getOptionValue={option => `${option}`}
                isOptionSelected={(option) => selectedFromEmail && (selectedFromEmail.value === option.value)}
              />
            </div>
            <div
              style={{
                padding: 15,
                width: '100%',
              }}
            >
              <span style={{ fontWeight: 700 }}>Subject</span>
              {/* TODO Allow custom subject here? */}
              <input
                className="form-control"
                name="subject"
                disabled
                value="Complete your checkout on Boxpressd"
                // onChange={this.handleInputChange('selectedName')}
              />
            </div>
            <div
              style={{
                padding: 15,
                width: '100%',
              }}
            >
              <span style={{ fontWeight: 700 }}>Message to the customer</span>
              <div>
                <textarea
                  className="form-control"
                  value={selectedEmailCustomMessage}
                  onChange={this.handleInputChange('selectedEmailCustomMessage')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button onClick={() => this.onCloseModal('email-cart')}>Cancel</Button>
          {/* FIXME Can we pull and populate the email template from SendGrid here? Since it's handlebars, we should be able to render on the Express server */}
          <Button onClick={() => this.onSaveModal('email-cart')} variant="contained" color="secondary">
            Review Email
          </Button>
        </div>
      </Modal>
    );
  };

  emailCartPreviewModal = () => {
    const { customer, showCartEmailPreviewModal, selectedFromEmail, selectedEmailCustomMessage } = this.state;
    const { cart } = this.props.CartManager;
    return (
      <Modal isOpen={showCartEmailPreviewModal} onClosed={() => this.onCloseModal('email-cart-preview')}>
        <div className="modal-header">
          <h5 className="modal-title">Email Cart Preview</h5>
        </div>
        <div className="modal-body">
          Preview coming soon! See
          {' '}
          <a
            href="https://mc.sendgrid.com/dynamic-templates/d-5a9e8879166b4153b441727c76c73ad1/version/3d934b65-5243-43a8-8ba9-a30dba0b3171/editor"
            target="_blank"
          >
            {'SendGrid'}
          </a>
          {' '}
          {'for template example.'}
        </div>
        <div className="modal-footer">
          <Button onClick={() => this.onCloseModal('email-cart-preview')}>Cancel</Button>
          <Button onClick={() => this.onSaveModal('email-cart-preview')} variant="contained" color="secondary">
            Send Email
          </Button>
        </div>
      </Modal>
    );
  };

  discountModal = () => (
    <Modal
      isOpen={this.state.showDiscountModal}
      onClosed={() => this.onCloseModal('discount')}
      className="modal-dialog-xs"
    >
      <div className="modal-header">
        <h5 className="modal-title">Add Discount</h5>
      </div>
      <div className="modal-body">
        <input
          className="form-control"
          name="discount-code"
          placeholder="Existing discount code"
          value={this.state.selectedDiscountCode}
          onChange={(e) => {
            this.setState({
              selectedDiscountCode: e.target.value.toUpperCase(),
            });
          }}
        />
        <hr />
        <span style={{ fontWeight: 700 }}>Custom Discount</span>
        <div
          style={{
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <BSButton outline color="primary" style={{ width: 40 }}>$</BSButton>
              <BSButton outline color="primary" style={{ width: 40 }}>%</BSButton>
            </InputGroupAddon>
            <Input placeholder="$" />
          </InputGroup>
        </div>

        <span style={{ fontWeight: 700 }}>Reason</span>
        <div
          style={{
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <input
            className="form-control"
            name="discount-reason"
            placeholder="Loyalty, special, etc"
            value={this.state.selectedDiscountReason}
            style={{ marginTop: 10 }}
            onChange={(e) => {
              this.setState({
                selectedDiscountReason: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className="modal-footer">
        <Button onClick={() => this.onCloseModal('discount')}>Cancel</Button>
        <Button onClick={() => this.onSaveModal('discount')} variant="contained" color="secondary">Apply</Button>
      </div>
    </Modal>
  );

  shippingModal = () => {
    const { selectedShippingRate } = this.state;
    const { cart } = this.props.CartManager;
    // if (!cart.shippingRates) {
    //   axios.get(`${config.shopEndPoint}/shipping/methods`).then((rates) => {
    //     this.setState({
    //       cart: {
    //         ...cart,
    //         shippingRates: rates,
    //       },
    //     });
    //   });
    // }
    return (
      <Modal
        isOpen={this.state.showShippingModal}
        onClosed={() => this.onCloseModal('shipping')}
        className="modal-dialog-xs"
      >
        <div className="modal-header">
          <h5 className="modal-title">Add Shipping</h5>
        </div>
        <div className="modal-body">
          <FormControl component="fieldset">
            <FormLabel component="legend">Rates</FormLabel>
            {cart.shippingRates && (
              <RadioGroup
                aria-label="rates"
                name="rates"
                value={selectedShippingRate}
                onChange={(event) => this.setState({ selectedShippingRate: event.target.value })}
              >
                {cart.shippingRates && cart.shippingRates.items && cart.shippingRates.items.map((rate) => (
                  <FormControlLabel value={rate.slug} control={<Radio />} label={`${rate.description} - $${rate.cost}`} />
                ))}
              </RadioGroup>
            )}
            {(!cart.shippingRates || !cart.shippingRates.items || cart.shippingRates.items.length === 0) && (
              <div>
                {"Please enter the customer's shipping address to show available shipping rates or leave this blank to let the customer choose."}
              </div>
            )}
          </FormControl>
        </div>
        <div className="modal-footer">
          <Button onClick={() => this.onCloseModal('shipping')}>Cancel</Button>
          <Button onClick={() => this.onSaveModal('shipping')} variant="contained" color="secondary">Apply</Button>
        </div>
      </Modal>
    );
  };

  addressModal = () => {
    const { showAddressModal, editingShippingAddress } = this.state;
    const { cart } = this.props.CartManager;
    const { shipping_address, billing_address } = cart;
    let address = billing_address;
    if (editingShippingAddress) {
      address = shipping_address;
    }
    if (!address) {
      address = {};
    }
    if (!address.first_name && address.full_name) {
      address.first_name = address.full_name.split(' ')[0];
      address.last_name = address.full_name.split(' ')[1];
    }
    console.log(address);
    return (
      <Modal
        isOpen={showAddressModal}
        onClosed={() => this.onCloseModal(editingShippingAddress ? 'shipping-address' : 'billing-address')}
      >
        <div className="modal-header">
          <h5 className="modal-title">{`Edit ${editingShippingAddress ? 'Shipping' : 'Billing'} Address`}</h5>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-6">
              <span style={{ fontWeight: 700 }}>First Name</span>
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <input
                  className="form-control"
                  value={address.first_name}
                  style={{ textTransform: 'capitalize' }}
                  onChange={this.handleAddressChange('firstName')}
                />
              </div>
            </div>
            <div className="col-md-6">
              <span style={{ fontWeight: 700 }}>Last Name</span>
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <input
                  className="form-control"
                  value={address.last_name}
                  style={{ textTransform: 'capitalize' }}
                  onChange={this.handleAddressChange('firstName')}
                />
              </div>
            </div>
            <div className="col-md-6">
              <span style={{ fontWeight: 700 }}>Address Line 1</span>
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <input
                  className="form-control"
                  value={address.address1}
                  style={{ textTransform: 'capitalize' }}
                  onChange={this.handleAddressChange('firstName')}
                />
              </div>
            </div>
            <div className="col-md-6">
              <span style={{ fontWeight: 700 }}>Address Line 2</span>
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <input
                  className="form-control"
                  value={address.address2}
                  style={{ textTransform: 'capitalize' }}
                  onChange={this.handleAddressChange('address2')}
                />
              </div>
            </div>
            <div className="col-md-6">
              <span style={{ fontWeight: 700 }}>City</span>
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <input
                  className="form-control"
                  value={address.city}
                  style={{ textTransform: 'capitalize' }}
                  onChange={this.handleAddressChange('city')}
                />
              </div>
            </div>
            <div className="col-md-6">
              <span style={{ fontWeight: 700 }}>Country</span>
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <Select
                  value={{
                    value: address.country,
                    label: address.country || '--',
                  }}
                  onChange={this.handleSelectChange('country')}
                  options={[{
                    value: 'US',
                    label: 'US',
                  }, {
                    value: 'CA',
                    label: 'CA',
                  }]}
                  getOptionLabel={(option) => `${option.label}`}
                  getOptionValue={(option) => `${option}`}
                  isOptionSelected={(option) => address.country && (address.country === option.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <span style={{ fontWeight: 700 }}>State</span>
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <Select
                  value={{
                    value: address.state,
                    label: address.state || '--',
                  }}
                  onChange={this.handleSelectChange('selectedState')}
                  options={usStates.map((usState) => ({
                    value: usState.abbreviation,
                    label: usState.name,
                  }))}
                  getOptionLabel={(option) => `${option.label}`}
                  getOptionValue={(option) => `${option}`}
                  isOptionSelected={(option) => address.state && (address.state === option.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <span style={{ fontWeight: 700 }}>ZIP</span>
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <input
                  className="form-control"
                  value={address.zip}
                  style={{ textTransform: 'capitalize' }}
                  onChange={this.handleAddressChange('firstName')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            onClick={() => this.onCloseModal(editingShippingAddress ? 'shipping-address' : 'billing-address')}
          >
            {'Cancel'}
          </Button>
          <Button
            onClick={() => this.onSaveModal(editingShippingAddress ? 'shipping-address' : 'billing-address')}
            variant="contained"
            color="secondary"
          >
            {'Save'}
          </Button>
        </div>
      </Modal>
    );
  };

  fulfillmentModal = () => {
    const { selectedOrder, submitting, trackingNumber } = this.state;
    const lineItems = selectedOrder.cart.items || [];
    const { unfulfilledItems } = this.fulfilledItems(selectedOrder);
    let totalCount = 0;
    for (const item of unfulfilledItems) {
      if (item.fulfill_count > 0) {
        totalCount += item.fulfill_count;
      }
    }
    return (
      <Modal
        isOpen={this.state.showFulfillmentModal}
        onClosed={() => this.onCloseModal('fulfillment')}
        className="modal-dialog"
      >
        <div className="modal-header">
          <h5 className="modal-title">Create Fulfillment</h5>
        </div>
        <div className="modal-body">
          {/* FIXME They need grouped by shop when we have multiple merchants selling */}
          {/* FIXME Also, need to only display items that have not been previously fulfilled (i.e, if 3/4 of one item have been fulfilled, only allow 1/1 the second time) */}
          {unfulfilledItems.map((item) => (
            <div style={{ display: 'flex', marginTop: 12 }}>
              <Avatar
                src={this.getProductImage(item.product)}
                style={{
                  height: 45,
                  width: 45,
                  margin: 3,
                }}
              >
                <img
                  src={Placeholder.cigar}
                  style={{
                    height: 45,
                    width: 45,
                  }}
                />
              </Avatar>
              <span
                style={{
                  flex: 1,
                  marginLeft: 10,
                }}
              >
                <span style={{ fontWeight: 600 }}>{item.product.name}</span>
                <br />
                <span>
                  {'SKU:'}
                  {item.product.reference}
                </span>
              </span>
              <span>
                <input
                  type="number"
                  value={item.fulfill_count || 0}
                  onChange={(e) => {
                    if (e.target.value <= item.quantity && e.target.value >= 0) {
                      item.fulfill_count = e.target.value;
                      this.setState({
                        update: true,
                        fulfillmentStatus: Fulfillment.processed,
                        fulfillmentContents: lineItems.filter((item) => item.fulfill_count > 0),
                      });
                    }
                  }}
                  style={{
                    marginLeft: 20,
                    maxWidth: 120,
                    display: 'inline-block',
                  }}
                  className="form-control"
                />
                <span
                  style={{
                    position: 'relative',
                    right: 56,
                  }}
                >
                  {' of '}
                  {item.quantity}
                </span>
              </span>
            </div>
          ))}
          <hr />
          <label>Tracking Number</label>
          <input
            className="form-control"
            value={trackingNumber}
            onChange={(e) => {
              this.setState({
                trackingNumber: e.target.value,
                fulfillmentStatus: Fulfillment.pre_transit, // FIXME Should check with EasyPost to get the current status - maybe do it server side, when this posts
              });
            }}
          />
        </div>
        <div className="modal-footer">
          <Button onClick={() => this.onCloseModal('fulfillment')}>Cancel</Button>
          <Button
            onClick={() => this.onSaveModal('fulfillment')}
            disabled={totalCount === 0 || submitting}
            variant="contained"
            color="secondary"
          >
            {submitting ? 'Submitting...' : 'Fulfill Items'}
          </Button>
        </div>
      </Modal>
    );
  };

  trackingInfoModal = () => {
    const { trackingNumber, submitting } = this.state;
    console.log('Selected fulfillment:');
    console.log(this.state.selectedFulfillment);
    return (
      <Modal
        isOpen={this.state.showTrackingInfoModal}
        onClosed={() => this.onCloseModal('tracking-info')}
        className="modal-dialog-xs"
      >
        <div className="modal-header">
          <h5 className="modal-title">Enter Tracking Info</h5>
        </div>
        <div className="modal-body">
          <label>Tracking Number</label>
          <input
            className="form-control"
            value={trackingNumber}
            onChange={(e) => {
              this.setState({
                trackingNumber: e.target.value,
                fulfillmentStatus: Fulfillment.pre_transit, // FIXME Should check with EasyPost to get the current status - maybe do it server side, when this posts
              });
            }}
          />
        </div>
        <div className="modal-footer">
          <Button onClick={() => this.onCloseModal('tracking-info')}>Cancel</Button>
          <Button
            onClick={() => this.onSaveModal('tracking-info')}
            disabled={submitting}
            variant="contained"
            color="secondary"
          >
            {submitting ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </Modal>
    );
  };

  refundModal = () => {
    const { selectedOrder, submitting } = this.state;
    const lineItems = selectedOrder.cart.items || [];
    const totalRefund = this.state.totalRefund || 0;
    console.log(selectedOrder);
    return (
      <Modal
        isOpen={this.state.showRefundModal}
        onClosed={() => this.onCloseModal('refund')}
        className="modal-dialog"
      >
        <div className="modal-header">
          <h5 className="modal-title">Refund</h5>
        </div>
        <div className="modal-body">
          {/* FIXME They need grouped by shop when we have multiple merchants selling */}
          {lineItems.map((item) => (
            <div style={{ display: 'flex', marginTop: 12 }}>
              <Avatar
                alt={item.product.name}
                src={this.getProductImage(item.product)}
                imgProps={{ style: { objectFit: 'contain' } }}
                style={{
                  height: 45,
                  width: 45,
                  marginTop: 3,
                  marginBottom: 3,
                  marginRight: 10,
                }}
              >
                <img
                  src={Placeholder.cigar}
                  style={{
                    height: 45,
                    width: 45,
                  }}
                />
              </Avatar>
              <span
                style={{
                  flex: 1,
                  marginLeft: 10,
                }}
              >
                <span style={{ fontWeight: 600 }}>{item.product.name}</span>
                <br />
                <span>
                  {'SKU:'}
                  {item.product.reference}
                </span>
              </span>
              <span>
                <input
                  type="number"
                  value={item.refund_count || 0}
                  onChange={(e) => {
                    if (e.target.value <= item.quantity && e.target.value >= 0) {
                      item.refund_count = e.target.value;
                      let total = 0;
                      for (const lineItem of lineItems) {
                        if (lineItem.refund_count) {
                          // TODO Include tax & shipping in refund
                          total += lineItem.refund_count * lineItem.price;
                        }
                      }
                      this.setState({
                        totalRefund: total,
                      });
                    }
                  }}
                  style={{
                    marginLeft: 20,
                    maxWidth: 120,
                    display: 'inline-block',
                  }}
                  className="form-control"
                />
                <span
                  style={{
                    position: 'relative',
                    right: 50,
                  }}
                >
                  {' '}
/
                  {' '}
                  {item.quantity}
                </span>
              </span>
              <span
                style={{
                  width: 80,
                  textAlign: 'right',
                  marginTop: 10,
                }}
              >
$
                {((item.refund_count || 0) * item.price).toFixed(2)}
              </span>
            </div>
          ))}
          <hr />
          <label>Manual</label>
          <input
            className="form-control"
            value={totalRefund || ''}
            onChange={(e) => {
              // TODO Need to force it to a number
              if (e.target.value && typeof parseFloat(e.target.value) === 'number') {
                this.setState({
                  totalRefund: e.target.value,
                });
              }
            }}
          />
          <span style={{ color: '#637381' }}>
            {`$${selectedOrder.total_price} available for refund`}
          </span>
          <hr />
          <label>Reason for refund</label>
          <input className="form-control" />
          <span style={{ color: '#637381' }}>Only you and your staff can see this</span>
        </div>
        <div className="modal-footer">
          {totalRefund > 0 && (
            <span
              style={{
                position: 'absolute',
                left: 16,
              }}
            >
              {/* TODO Calculate these two and tack on to totalRefund */}
              <span>Tax: $0.00</span>
              <span style={{ marginLeft: 10 }}>Shipping: $0.00</span>
            </span>
          )}
          <Button onClick={() => this.onCloseModal('refund')}>Cancel</Button>
          <Button
            disabled={totalRefund === 0 || submitting}
            onClick={() => this.onSaveModal('refund')}
            variant="contained"
            color="secondary"
          >
            {submitting ? 'Processing...' : `Refund $${parseFloat(totalRefund).toFixed(2)}`}
          </Button>
        </div>
      </Modal>
    );
  };

  creditCardModal = () => (
    <Modal
      isOpen={this.state.showCreditCardModal}
      onClosed={() => this.onCloseModal('credit-card')}
      className="modal-dialog-xs"
    >
      <div className="modal-header">
        <h5 className="modal-title">Enter Credit Card</h5>
      </div>
      <div className="modal-body">
        <PaymentInputs />
      </div>
      <div className="modal-footer">
        <Button onClick={() => this.onCloseModal('credit-card')}>Cancel</Button>
        <Button onClick={() => this.onSaveModal('credit-card')} variant="contained" color="secondary">Process</Button>
      </div>
    </Modal>
  );

  newOrderModal = () => {
    const { customer } = this.state;
    const { cart } = this.props.CartManager;
    const { shipping_address, billing_address } = cart;
    const lineItems = cart.items || [];
    return (
      <Modal
        isOpen={this.state.showCreateOrderModal}
        onClosed={() => this.onCloseModal('order')}
        className="modal-dialog-lg"
      >
        <div className="modal-header">
          <h5 className="modal-title">Create Order</h5>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-8">
              <Card style={{ padding: 10 }}>
                <span
                  style={{
                    fontWeight: 700,
                    marginBottom: 6,
                  }}
                >
                  {'Order Details'}
                </span>
                <div>
                  <AsyncCreatableSelect
                    value=""
                    placeholder="Search products..."
                    onChange={(product) => {
                      this.addProduct(product);
                    }}
                    getOptionLabel={(option) => (
                      <ListItem key={`product-option-${option.value}`}>
                        <Avatar
                          variant="square"
                          src={this.getProductImage(option)}
                          style={{ marginRight: 10 }}
                          alt={option.label}
                          imgProps={{ style: { objectFit: 'contain' } }}
                        >
                          <img
                            src={Placeholder.cigar}
                            style={{
                              height: 40,
                              width: 40,
                            }}
                          />
                        </Avatar>
                        <ListItemText
                          primary={(
                            <span className="padded-multiline" style={{ marginLeft: 10 }}>{option.label}</span>
                          )}
                          secondary={`SKU: ${option.reference || 'N/A'}`}
                        />
                        <div
                          style={{
                            display: 'inline-block',
                            float: 'right',
                          }}
                        >
                          <span>{this.formatProductPrice(option)}</span>
                        </div>
                      </ListItem>
                    )}
                    loadOptions={this.loadProducts}
                  />
                  <div>
                    <table
                      style={{
                        width: '100%',
                        marginTop: 16,
                      }}
                    >
                      <tbody>
                        {lineItems.map((item, index) => (
                          <tr>
                            <td width={85}>
                              <img
                                src={this.getProductImage(item.product)}
                                style={{
                                  height: 65,
                                  width: 65,
                                }}
                              />
                            </td>
                            <td>
                              <span>{item.product.name}</span>
                              <br />
                              <span>
                                {'SKU:'}
                                {item.product.reference}
                              </span>
                            </td>
                            <td>
                            $
                              {this.formatDecimal(item.price)}
                            </td>
                            <td>
                              {'x'}
                              <input
                                type="number"
                                value={item.quantity || 1}
                                onChange={(e) => {
                                // axios.put(`${Constants.apiPath}/shop/carts/${cart.id}`, {
                                //   ...item,
                                //   quantity: e.target.value,
                                // }).then(() => {
                                //
                                // });
                                  cartActions.updateProduct({
                                    ...item,
                                    quantity: e.target.value,
                                  }).then().catch((error) => {
                                    console.log(error);
                                  });
                                }}
                                style={{
                                  marginLeft: 20,
                                  maxWidth: 80,
                                  display: 'inline-block',
                                }}
                                className="form-control"
                              />
                            </td>
                            <td>
                            $
                              {((item.quantity || 1) * item.price).toFixed(2)}
                            </td>
                            <td width={30}>
                              <Icon name="trash" onClick={() => this.removeProduct(item)} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <hr />
                  </div>
                  {/* END If products added */}
                  <div className="row" style={{ marginTop: 20 }}>
                    <div className="col-md-6">
                      <span
                        style={{
                          fontWeight: 700,
                          marginBottom: 6,
                        }}
                      >
                        {'Notes'}
                      </span>
                      <div><textarea className="form-control" /></div>
                    </div>
                    <div
                      className="col-md-6"
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                      }}
                    >
                      <table style={{ width: '100%' }}>
                        <tbody>
                          <tr>
                            <td style={{ textAlign: 'right' }}>
                              <a
                                href="#"
                                style={{ color: 'rgb(214, 194, 144)' }}
                                onClick={() => this.onOpenModal('discount')}
                              >
                                {'Add Discount'}
                              </a>
                            </td>
                            <td style={{ textAlign: 'right' }}>
                            —
                            </td>
                          </tr>
                          {cart.discounts && cart.discounts.map((discount) => {
                            return (
                              <tr>
                                <td style={{ textAlign: 'right' }}>
                                  <Icon
                                    name="tag"
                                    style={{
                                      height: 16,
                                      width: 16,
                                    }}
                                  />
                                  {/* INFO Not always a code if custom */}
                                  {/* {discount.name} */}
                                  {discount.code}
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.removeDiscount(discount.code);
                                    }}
                                  >
                                    <Icon
                                      name="x"
                                      style={{
                                        height: 16,
                                        width: 16,
                                      }}
                                    />
                                  </a>
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  {discount.type === 'percentage' && `-$${(cart.subtotal_price * (discount.amount / 100)).toFixed(2)}`}
                                  {discount.type === 'fixed' && `-$${discount.amount.toFixed(2)}`}
                                </td>
                              </tr>
                            );
                            return null;
                          })}
                          <tr>
                            <td style={{ textAlign: 'right' }}>
                            Subtotal
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              {`$${(cart.subtotal_price || 0).toFixed(2)}`}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: 'right' }}>
                              <a
                                href="#"
                                style={{ color: 'rgb(214, 194, 144)' }}
                                onClick={() => this.onOpenModal('shipping')}
                              >
                                {'Shipping'}
                              </a>
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              {cart.shippingDetails && cart.shippingDetails.cost ? `$${cart.shippingDetails.cost.toFixed(2)}` : '—'}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: 'right' }}>
                            Tax
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              {cart.taxes && cart.taxes.items.length > 0 ? `$${cart.taxes.items[0].amount.toFixed(2)}` : '—'}
                            </td>
                          </tr>
                          <tr style={{ fontWeight: 700 }}>
                            <td style={{ textAlign: 'right' }}>
                            Total
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              {`$${(cart.total_price || 0).toFixed(2)}`}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <hr />
                <span
                  style={{
                    fontWeight: 700,
                    marginBottom: 6,
                  }}
                >
                  {'Send Cart'}
                </span>
                <div>
                  <Button
                    onClick={() => {
                      this.createCart();
                      this.onOpenModal('email-cart');
                    }}
                    disabled={!lineItems || lineItems.length === 0 || !customer}
                    variant="contained"
                    color="secondary"
                  >
                    {'Email Cart'}
                  </Button>
                </div>
                <hr />
                <span
                  style={{
                    fontWeight: 700,
                    marginBottom: 6,
                  }}
                >
                  {'Accept Payment'}
                </span>
                <div>
                  {/* FIXME These should only be available when the order has an ID (ie, it's been saved) */}
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    style={{ marginRight: 10 }}
                    disabled={!lineItems || lineItems.length === 0 || !customer}
                  >
                    {'Mark as paid'}
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    style={{ marginRight: 10 }}
                    disabled={!lineItems || lineItems.length === 0 || !customer}
                  >
                    {'Mark as pending'}
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    style={{ marginRight: 10 }}
                    onClick={() => this.onOpenModal('credit-card')}
                    disabled={!lineItems || lineItems.length === 0 || !customer}
                  >
                    {'Pay with credit card'}
                  </button>
                </div>
              </Card>
            </div>
            <div className="col-md-4">
              <Card style={{ padding: 10 }}>
                <div>
                  <span
                    style={{
                      fontWeight: 700,
                      marginBottom: 6,
                    }}
                  >
                    {customer ? 'Customer' : 'Find or create a customer'}
                  </span>
                  {customer && (
                    <span
                      style={{
                        color: 'rgb(211, 169, 102)',
                        cursor: 'pointer',
                        float: 'right',
                      }}
                      onClick={() => {
                        this.setState({
                          customer: null,
                        });
                      }}
                    >
                      <Icon name="x" />
                    </span>
                  )}
                </div>
                {!customer && (
                  <AsyncCreatableSelect
                    value=""
                    onChange={(customer) => {
                      this.setState({
                        customer,
                      });
                      this.getCustomerAddresses(customer);
                    }}
                    getOptionLabel={(option) => (
                      <ListItem key={`customer-option-${option.value}`}>
                        <Avatar
                          src={option.image_url}
                          style={{ marginRight: 10 }}
                          alt={option.label}
                        >
                          <img
                            src={Placeholder.avatar}
                            style={{
                              height: 40,
                              width: 40,
                            }}
                          />
                        </Avatar>
                        <ListItemText
                          primary={(
                            <span className="padded-multiline" style={{ marginLeft: 10 }}>{option.label}</span>
                          )}
                          secondary={option.email}
                        />
                      </ListItem>
                    )}
                    loadOptions={this.loadCustomers}
                  />
                )}
                {customer && this.renderCustomerDetails(customer, billing_address, shipping_address)}
              </Card>

              <Card style={{ padding: 10 }}>
                <span
                  style={{
                    fontWeight: 700,
                    marginBottom: 6,
                  }}
                >
                  {'Tags'}
                </span>
                Coming soon
              </Card>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button onClick={() => this.onCloseModal('order')}>Cancel</Button>
          <Button
            onClick={() => {
              this.createCart(); // FIXME .then(() => createOrder()) ?
              this.onSaveModal('order');
            }}
            disabled={!lineItems || lineItems.length === 0 || !customer}
            variant="contained"
            color="secondary"
          >
            {'Create'}
          </Button>
        </div>
      </Modal>
    );
  };

  humidorSelectionModal = () => {
    const { showHumidorPickerModal, selectedHumidor, userHumidors } = this.state;
    return (
      <Modal isOpen={showHumidorPickerModal} onClosed={() => this.onCloseModal('humidors')}>
        <div className="modal-header">
          <h5 className="modal-title">{'Select User\'s Humidor'}</h5>
        </div>
        <div className="modal-body">
          <Select
            value={selectedHumidor}
            onChange={this.handleChange('selectedHumidor')}
            options={userHumidors}
          />
        </div>
        <div className="modal-footer">
          <Button onClick={() => this.onCloseModal('humidors')}>Cancel</Button>
          <Button disabled={!selectedHumidor} onClick={() => this.onSaveModal('humidors')} variant="contained" color="secondary">
            {'Add Entries'}
          </Button>
        </div>
      </Modal>
    );
  };

  renderFilterOptions = () => (
    <SwipeableDrawer
      anchor="right"
      open={this.state.showFilterDrawer}
      // onClick={() => this.setState({ showFilterDrawer: false })}
      onKeyDown={() => this.setState({ showFilterDrawer: false })}
      onClose={() => this.setState({ showFilterDrawer: false })}
    >
      {/* TODO Add app bar on mobile with back arrow */}
      {/* {isMobile && ( */}
      {/*    <AppBar position="fixed"> */}
      {/*        <Toolbar> */}
      {/*            <IconButton edge="start" color="inherit" aria-label="back"> */}
      {/*                <Icon name="arrow-left" /> */}
      {/*            </IconButton> */}
      {/*        </Toolbar> */}
      {/*    </AppBar> */}
      {/* )} */}
      <div
        style={{
          width: isMobile ? 'calc(100vw - 40px)' : 300,
          marginLeft: 20,
          marginRight: 20,
          marginBottom: 30,
        }}
      >
        <div
          style={{
            display: 'flex',
            marginTop: 35,
            cursor: 'pointer',
            borderBottom: '1px solid #efefef',
          }}
          onClick={() => this.setState({ showStatusFilter: !this.state.showStatusFilter })}
        >
          <span style={{ flex: 1 }}>Status</span>
          <Icon name="chevron-down" />
        </div>
        <Collapse isOpen={this.state.showStatusFilter}>
          {/* InProgress, Processed, Disputed, Shipped, Delivered, Pending, Cancelled */}
          <FormGroup>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={this.state.selectedStatusFilter.Draft}
                  onChange={this.handleCheckChange}
                  name="Draft"
                />
              )}
              label="Draft"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={this.state.selectedStatusFilter.InProgress}
                  onChange={this.handleCheckChange}
                  name="InProgress"
                />
              )}
              label="Processing"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={this.state.selectedStatusFilter.Processed}
                  onChange={this.handleCheckChange}
                  name="Processed"
                />
              )}
              label="Processed"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={this.state.selectedStatusFilter.Shipped}
                  onChange={this.handleCheckChange}
                  name="Shipped"
                />
              )}
              label="Shipped"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={this.state.selectedStatusFilter.Delivered}
                  onChange={this.handleCheckChange}
                  name="Delivered"
                />
              )}
              label="Delivered"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={this.state.selectedStatusFilter.Cancelled}
                  onChange={this.handleCheckChange}
                  name="Cancelled"
                />
              )}
              label="Cancelled"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={this.state.selectedStatusFilter.Disputed}
                  onChange={this.handleCheckChange}
                  name="Disputed"
                />
              )}
              label="Disputed"
            />
          </FormGroup>
        </Collapse>
        <div
          style={{
            display: 'flex',
            marginTop: 35,
            cursor: 'pointer',
            borderBottom: '1px solid #efefef',
          }}
          onClick={() => this.setState({ showDateFilter: !this.state.showDateFilter })}
        >
          <span style={{ flex: 1 }}>Date</span>
          <Icon name="chevron-down" />
        </div>
        <Collapse isOpen={this.state.showDateFilter}>
          <RadioGroup
            aria-label="status"
            name="status"
            value={this.state.selectedDateFilter}
            onChange={(event) => this.setState({ selectedDateFilter: event.target.value })}
          >
            <FormControlLabel value="today" control={<Radio />} label="Today" />
            <FormControlLabel value="yesterday" control={<Radio />} label="Yesterday" />
            <FormControlLabel value="7-days" control={<Radio />} label="7 Days" />
            <FormControlLabel value="30-days" control={<Radio />} label="30 Days" />
            <FormControlLabel value="90-days" control={<Radio />} label="90 Days" />
          </RadioGroup>
        </Collapse>

        <div
          style={{
            display: 'flex',
            position: 'fixed',
            bottom: 0,
            backgroundColor: 'white',
            width: 300,
            paddingTop: 10,
            paddingBottom: 10,
            margin: 'auto',
          }}
        >
          <Button
            onClick={() => {
              this.setState({
                showFilterDrawer: false,
              });
              // this.resetFilters();
            }}
            style={{
              display: 'block',
              flex: 1,
              marginRight: 10,
              textAlign: 'center',
            }}
          >
            {'Reset'}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{
              display: 'block',
              flex: 1,
              textAlign: 'center',
            }}
            onClick={() => {
              this.setState({
                showFilterDrawer: false,
              });
              // this.filterProducts(1);
            }}
          >
            {'Apply'}
          </Button>
        </div>
      </div>
    </SwipeableDrawer>
  );

  renderCustomerDetails = (customer, billingAddress, shippingAddress) => (
    <div>
      <Avatar
        src={customer.image_url}
        style={{
          marginRight: 10,
          height: 65,
          width: 65,
        }}
        alt={customer.full_name}
      >
        <img
          src={Placeholder.avatar}
          style={{
            height: 65,
            width: 65,
          }}
        />
      </Avatar>
      <div>
        <div
          style={{
            marginTop: 10,
            marginBottom: 0,
          }}
        >
          {customer.full_name}
        </div>
        <div><a href={`mailto:${customer.email}`}>{customer.email}</a></div>
      </div>
      <hr />
      <div>
        <span
          style={{
            fontWeight: 700,
            marginBottom: 6,
          }}
        >
          {'Shipping address'}
        </span>
        <span
          style={{
            color: 'rgb(211, 169, 102)',
            cursor: 'pointer',
            float: 'right',
          }}
          onClick={() => {
            // FIXME If the user has addresses on file, add an option to pick one
            this.setState({
              editingShippingAddress: true,
              showAddressModal: true,
            });
          }}
        >
          {'Edit'}
        </span>
      </div>
      {shippingAddress && shippingAddress.address1 && (
        <div>
          {`${shippingAddress.address1}${(shippingAddress.address2 && shippingAddress.address2.length) ? ` ${shippingAddress.address2}` : ''}, ${shippingAddress.city}, ${shippingAddress.state}, ${shippingAddress.country} ${shippingAddress.zip}`}
        </div>
      )}
      {(!shippingAddress || !shippingAddress.address1) && (
        <div style={{ marginTop: 5 }}>No shipping address available</div>
      )}
      <hr />
      <div>
        <span
          style={{
            fontWeight: 700,
            marginBottom: 6,
          }}
        >
          {'Billing address'}
        </span>
        <span
          style={{
            color: 'rgb(211, 169, 102)',
            cursor: 'pointer',
            float: 'right',
          }}
          onClick={() => {
            // FIXME If the user has addresses on file, add an option to pick one
            this.setState({
              editingShippingAddress: false,
              showAddressModal: true,
            });
          }}
        >
          {'Edit'}
        </span>
      </div>
      {billingAddress && billingAddress.address1 && (
        <div>
          {`${billingAddress.address1}${(billingAddress.address2 && billingAddress.address2.length) ? ` ${billingAddress.address2}` : ''}, ${billingAddress.city}, ${billingAddress.state}, ${billingAddress.country} ${billingAddress.zip}`}
        </div>
      )}
      {(!billingAddress || !billingAddress.address1) && (
        <div style={{ marginTop: 5 }}>No billing address available</div>
      )}
    </div>
  );

  render() {
    const { sizePerPage, page } = this.state;
    const { orders, totalOrderSize, loadingOrders } = this.props.ShopManager;

    console.log('Orders:');
    console.log(orders);

    const columns = [{
      dataField: 'order_number',
      text: 'Order #',
      sort: true,
      editable: false,
      formatter: (cell, order) => (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            this.setState({
              selectedOrder: order,
              showDetailsModal: true,
            });
          }}
        >
          {cell}
        </span>
      ),
    }, {
      dataField: 'purchase_timestamp',
      text: 'Date',
      sort: true,
      editable: false,
    }, {
      dataField: '',
      text: 'Sold By',
      sort: true,
      editable: false,
      formatter: this.soldByFormatter,
    }, {
      dataField: 'cart.user.email',
      text: 'Customer',
      sort: true,
      editable: false,
    }, {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: this.statusFormatter,
      // filter: textFilter()
    }, {
      dataField: 'cart.items',
      text: 'Products',
      formatter: this.itemFormatter,
      sort: true,
      // filter: textFilter()
    }, {
      dataField: 'total_price',
      text: 'Price',
      formatter: this.priceFormatter,
      sort: true,
    }, {
      dataField: 'edit',
      text: '',
      formatter: this.editBtnFormatter,
      sort: true,
    }];

    const rowEvents = {
      onMouseEnter: (e, row, index) => {
        // console.log("Mouse entered: " + index);
        document.querySelector(`#dropdown-actions-${index}`).classList.remove('d-none');
      },
      onMouseLeave: (e, row, index) => {
        document.querySelector(`#dropdown-actions-${index}`).classList.add('d-none');
      },
      onDoubleClick: (e, row, index) => {
        // INFO If we don't include this event, the double click to edit doesn't work
        // console.log(e);
      },
    };

    const pageButtonRenderer = ({ page, active, onPageChange }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      let classname = 'btn btn-outline-secondary';
      if (active) {
        classname = 'btn btn-secondary';
      }
      return (
        <li className="page-item pl-1" key={page}>
          <a href="#" onClick={handleClick} className={classname}>{page}</a>
        </li>
      );
    };

    // TODO https://www.npmjs.com/package/react-device-detect use a different layout for mobile
    return (
      <div>
        <SweetAlert
          show={this.state.message.show}
          type={this.state.message.type}
          title={this.state.message.title}
          onConfirm={this.closeAlert}
        >
          {this.state.message.text}
        </SweetAlert>

        <Breadcrumb title="Orders" label="Orders" parent="Shop" />

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body datatable-react">
                  <ToolkitProvider
                    keyField="id"
                    data={orders}
                    columns={columns}
                    search
                  >
                    {
                      (toolkitprops) => (
                        <div>
                          {/* Known issue https://github.com/react-bootstrap-table/react-bootstrap-table2/issues/787 */}
                          <SearchBar {...toolkitprops.searchProps} delay={800} />
                          {/* <ClearSearchButton { ...props.searchProps } /> */}
                          <button
                            className="btn btn-primary mr-2"
                            onClick={() => {
                              this.clearCart();
                              this.onOpenModal('order');
                            }}
                            style={isMobile ? {
                              padding: '6px 14px',
                              float: 'right',
                            } : { float: 'right' }}
                          >
                            {isMobile ? (<i className="icon icon-plus" />) : 'Create Order'}
                          </button>
                          <button
                            className="btn btn-outline-primary mr-2"
                            onClick={() => this.onOpenModal('filter')}
                            style={isMobile ? {
                              padding: '6px 14px',
                              float: 'right',
                            } : { float: 'right' }}
                          >
                            {isMobile ? (<i className="icon icon-filter" />) : 'Filter'}
                          </button>
                          <BootstrapTable
                            remote
                            keyField="id"
                            data={orders}
                            columns={columns}
                            rowEvents={rowEvents}
                            defaultSorted={defaultSorted}
                            wrapperClasses="table-responsive"
                            pagination={paginationFactory({
                              pageButtonRenderer,
                              page,
                              sizePerPage,
                              totalOrderSize,
                            })}
                            cellEdit={cellEditFactory(cellEditProps)}
                            // filter={ filterFactory() }
                            onTableChange={this.handleTableChange}
                            noDataIndication={() => (!loadingOrders ? (
                              <NoDataIndicator
                                message={'Either you don\'t have orders yet or none of them are matching the selected filters.'}
                              />
                            )
                              : <TableLoader />)}
                            {...toolkitprops.baseProps}
                          />
                        </div>
                      )
                    }
                  </ToolkitProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.newOrderModal()}
        {this.detailsModal()}
        {this.discountModal()}
        {this.shippingModal()}
        {this.emailCartModal()}
        {this.emailCartPreviewModal()}
        {this.fulfillmentModal()}
        {this.trackingInfoModal()}
        {this.humidorSelectionModal()}
        {this.refundModal()}
        {this.creditCardModal()}
        {this.addressModal()}
        {this.renderFilterOptions()}
      </div>
    );
  }
}

const mapStateToProps = ({ ShopManager, CartManager }) => ({
  ShopManager,
  CartManager,
});

export default connect(
  mapStateToProps, {
    getAllOrders,
  },
)(ShopTable);
