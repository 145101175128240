import React from 'react';
import PropTypes from 'prop-types';
import {
  FiCompass,
  FiBookmark,
  FiAward,
  FiUsers,
  FiTag,
  FiShoppingCart,
  FiMapPin,
  FiLifeBuoy,
  FiAlertTriangle,
  FiRss,
  FiHeadphones,
  FiMessageCircle,
  FiMessageSquare,
  FiInbox,
} from 'react-icons/fi';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// import Icon from 'react-icon-base';
export class IconSVG extends React.PureComponent {
  static propTypes = {
    icon: PropTypes.string.isRequired,
    color: PropTypes.string,
    box: PropTypes.string,
    size: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    style: PropTypes.any,
  };

  static defaultProps = {
    size: '1em',
  };

  mergeStyles(...args) {
    // This is the m function from "CSS in JS" and can be extracted to a mixin
    return Object.assign({}, ...args);
  }

  renderGraphic() {
    switch (this.props.icon) {
      case 'logo':
        return (
          <g fill={this.props.color}>
            <path d="M28.125,0 L1.875,0 C0.838125,0 0,0.838125 0,1.875 L0,28.125 C0,29.161875 0.838125,30 1.875,30 L18.75,30 C19.2675,30 19.73625,29.79 20.075625,29.450625 L29.450625,20.075625 C29.79,19.73625 30,19.2675 30,18.75 L30,1.875 C30,0.838125 29.161875,0 28.125,0 Z M18.75,25.47375 L18.75,20.625 C18.75,19.588125 19.588125,18.75 20.625,18.75 L25.47375,18.75 L18.75,25.47375 Z M26.25,15 L20.625,15 C17.518125,15 15,17.518125 15,20.625 L15,26.25 L3.75,26.25 L3.75,3.75 L26.25,3.75 L26.25,15 Z" id="Shape"></path>
          </g>
        );
      case 'card':
        return (
          <g fill={this.props.color}>
            <path
              d="M4 11h5v-6h-5v6zm0 7h5v-6h-5v6zm6 0h5v-6h-5v6zm6 0h5v-6h-5v6zm-6-7h5v-6h-5v6zm6-6v6h5v-6h-5z"
            ></path>
          </g>
        );
      case 'vault':
        // FIXME Use an outlined version of Hashicorp Vault logo here
        return (
          <g fill={this.props.color}>
            <path
              d="M0 0l16.9 34L34 0H0zm15 15h-2v-2h2v2zm0-3h-2v-2h2v2zm0-3h-2V7h2v2zm3 9h-2v-2h2v2zm0-3h-2v-2h2v2zm0-3h-2v-2h2v2zm0-3h-2V7h2v2zm3 6h-2v-2h2v2zm0-3h-2v-2h2v2zm-2-3V7h2v2h-2z"
            ></path>
          </g>
        );
      case 'list':
        return (
          <g fill={this.props.color}>
            <path
              d="M4 14h4v-4h-4v4zm0 5h4v-4h-4v4zm0-10h4v-4h-4v4zm5 5h12v-4h-12v4zm0 5h12v-4h-12v4zm0-14v4h12v-4h-12z"
            ></path>
          </g>
        );
      case 'another-icon':
        return (
          <g>
            <path d="M7.41 15.41l4.59-4.58 4.59 4.58 1.41-1.41-6-6-6 6z" />
          </g>
        );
      case 'close':
        return (
          <g id="Shape">
            <path
              d="M6.586,8 L0.293,1.707 C-0.097,1.317 -0.097,0.683 0.293,0.293 C0.683,-0.097 1.317,-0.097 1.707,0.293 L8,6.586 L14.293,0.293 C14.683,-0.097 15.317,-0.097 15.707,0.293 C16.097,0.683 16.097,1.317 15.707,1.707 L9.414,8 L15.707,14.293 C16.097,14.683 16.097,15.317 15.707,15.707 C15.317,16.097 14.683,16.097 14.293,15.707 L8,9.414 L1.707,15.707 C1.317,16.097 0.683,16.097 0.293,15.707 C-0.097,15.317 -0.097,14.683 0.293,14.293 L6.586,8 Z"
            ></path>
          </g>
        );
      case 'menu-article':
        return (
          <g id="Icons/blog">
            <path
              d="M11.0000142,16 L2.00001416,16 C1.46801416,16 0.960014156,15.788 0.583014156,15.412 C0.208014156,15.035 -0.00198584412,14.525 1.415588e-05,13.992 L1.415588e-05,2.008 C1.415588e-05,0.9 0.894014156,0.003 2.00001416,0 L8.50001416,0 C9.08201416,0.017 9.63701416,0.248 10.0600142,0.65 L12.3500142,2.937 C12.7500142,3.362 12.9800142,3.917 13.0000142,4.501 L13.0000142,14 C13.0020142,14.53 12.7900142,15.04 12.4160142,15.416 C12.0410142,15.791 11.5310142,16.002 11.0000142,16 L11.0000142,16 Z M9.00001416,2.414 L9.00001416,4 L10.5900142,4 L9.00001416,2.414 Z M11.0000142,6 L9.00001416,6 C7.89501416,6 7.00001416,5.105 7.00001416,4 L7.00001416,2 C5.08001416,2 2.00001416,2 2.00001416,2.006 L2.00001416,14 L11.0000142,14 L11.0000142,6 Z M4.00001416,4 L5.00001416,4 C5.55201416,4 6.00001416,4.448 6.00001416,5 C6.00001416,5.552 5.55201416,6 5.00001416,6 L4.00001416,6 C3.44801416,6 3.00001416,5.552 3.00001416,5 C3.00001416,4.448 3.44801416,4 4.00001416,4 Z M4.00001416,7 L9.00001416,7 C9.55201416,7 10.0000142,7.448 10.0000142,8 C10.0000142,8.552 9.55201416,9 9.00001416,9 L4.00001416,9 C3.44801416,9 3.00001416,8.552 3.00001416,8 C3.00001416,7.448 3.44801416,7 4.00001416,7 Z M4.00001416,10 L9.00001416,10 C9.55201416,10 10.0000142,10.448 10.0000142,11 C10.0000142,11.552 9.55201416,12 9.00001416,12 L4.00001416,12 C3.44801416,12 3.00001416,11.552 3.00001416,11 C3.00001416,10.448 3.44801416,10 4.00001416,10 Z"
              id="Shape"
            ></path>
          </g>
        );
      case 'menu-chat':
        return (
          <g id="Shape">
            <path
              d="M7.993,13.995 C5.269,13.993 2.888,12.157 2.193,9.525 C1.497,6.89 2.661,4.118 5.028,2.771 C7.395,1.425 10.373,1.841 12.282,3.783 C14.192,5.726 14.552,8.71 13.162,11.053 C13.022,11.291 12.987,11.575 13.062,11.84 L13.564,13.542 L11.846,13.052 C11.581,12.975 11.296,13.012 11.059,13.152 C10.131,13.704 9.071,13.996 7.992,13.995 L7.993,13.995 Z M14.723,15.955 C15.073,16.055 15.449,15.959 15.707,15.701 C15.964,15.445 16.061,15.069 15.962,14.719 C15.862,14.369 15.095,11.693 15.096,11.684 C16.914,8.182 15.906,3.877 12.723,1.546 C9.54,-0.785 5.13,-0.447 2.343,2.343 C-0.447,5.133 -0.787,9.543 1.546,12.725 C3.878,15.907 8.183,16.915 11.686,15.095 L14.726,15.957 L14.723,15.957 L14.723,15.955 Z"
            ></path>
          </g>
        );
      case 'menu-copy':
        return (
          <g id="Icons/View" transform="translate(87.000000, 122.000000)">
            <path
              d="M16,6 C11.578,-2 4.422,-2 0,6 C4.422,14 11.578,14 16,6 Z M8.001,8 C6.896,8 6,7.104 6,6 C6,4.895 6.896,4 8.001,4 C9.105,4 10,4.895 10,6 C10,7.104 9.105,8 8.001,8 L8.001,8 Z"
            ></path>
          </g>
        );
      case 'menu-home':
        return (
          <g id="Shape">
            <path
              d="M13.005,13.997 C13.005,13.989 5.005,13.997 5.005,13.997 C5.012,13.997 5.005,8.997 5.005,8.997 C5.005,8.445 4.557,7.997 4.005,7.997 C3.453,7.997 3.005,8.445 3.005,8.997 L3.005,13.997 C3.005,15.102 3.9,15.997 5.005,15.997 L13.005,15.997 C14.107,15.994 14.999,15.1 14.999,13.997 L14.999,8.997 C14.999,8.445 14.549,7.997 13.999,7.997 C13.446,7.997 12.999,8.445 12.999,8.997 L13.004,13.997 L13.005,13.997 Z M15.377,7.767 C15.579,7.94 15.844,8.022 16.107,7.995 C16.373,7.967 16.615,7.832 16.777,7.621 C17.125,7.176 17.064,6.536 16.637,6.164 L9.692,0.263 C9.282,-0.087 8.68,-0.087 8.272,0.263 L1.365,6.135 C0.938,6.507 0.875,7.147 1.225,7.593 C1.385,7.803 1.628,7.939 1.893,7.966 C2.157,7.994 2.421,7.912 2.623,7.739 L8.993,2.349 L15.369,7.771 L15.376,7.766 L15.377,7.767 Z M9.023,10.987 C10.123,10.984 11.013,10.09 11.011,8.989 C11.011,7.889 10.116,6.997 9.016,6.999 C7.916,6.999 7.024,7.892 7.024,8.992 C7.027,10.095 7.922,10.986 9.024,10.986 L9.023,10.987 Z"
            ></path>
          </g>
        );
      case 'icon-home':
        return (
          <g id="Shape">
            <path
              d="M1.25,15 L18.75,15 C19.44,15 20,14.44 20,13.75 C20,13.06 19.44,12.5 18.75,12.5 L1.25,12.5 C0.56,12.5 0,13.06 0,13.75 C0,14.44 0.56,15 1.25,15 Z M1.25,8.75 L18.75,8.75 C19.44,8.75 20,8.19 20,7.5 C20,6.81 19.44,6.25 18.75,6.25 L1.25,6.25 C0.56,6.25 0,6.81 0,7.5 C0,8.19 0.56,8.75 1.25,8.75 Z M1.25,2.5 L18.75,2.5 C19.44,2.5 20,1.94 20,1.25 C20,0.56 19.44,0 18.75,0 L1.25,0 C0.56,0 0,0.56 0,1.25 C0,1.94 0.56,2.5 1.25,2.5 Z"
            ></path>
          </g>
        );
      case 'menu-homepage':
        return (
          <g id="inspire-UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="homepage---list-view" transform="translate(-38.000000, -19.000000)">
              <g id="Logo" transform="translate(38.000000, 19.000000)">
                <rect id="Rectangle-2" fill="#22A8AB" x="0" y="0" width="42" height="37" rx="4"></rect>
                <g id="Group-4" transform="translate(5.000000, 25.000000)">
                  <rect id="Rectangle-7" fillOpacity="0.5" fill="#FFFFFF" x="0" y="1" width="12" height="5" rx="2.5"></rect>
                  <circle id="Oval-2" fill="#E9F6F6" cx="12.5" cy="3.5" r="3.5"></circle>
                </g>
                <g id="Group-3" transform="translate(5.000000, 5.000000)">
                  <rect id="Rectangle-7" fillOpacity="0.5" fill="#FFFFFF" x="0" y="1" width="21" height="5" rx="2.5"></rect>
                  <circle id="Oval-2" fill="#E9F6F6" cx="18.5" cy="3.5" r="3.5"></circle>
                </g>
                <g id="Group-2" transform="translate(5.000000, 15.000000)">
                  <rect id="Rectangle-7" fillOpacity="0.5" fill="#FFFFFF" x="0" y="1" width="32" height="5" rx="2.5"></rect>
                  <circle id="Oval-2" fill="#E9F6F6" cx="28.5" cy="3.5" r="3.5"></circle>
                </g>
              </g>
            </g>
          </g>
        );
      case 'menu-love':
        return (
          <g id="Icons/Like" transform="translate(88.000000, 172.000000)">
            <path
              d="M7.81235,1.07242748 L6.99965,1.89416222 L6.18695,1.07242748 C4.77155,-0.357475826 2.47695,-0.357475826 1.06155,1.07242748 C-0.35385,2.50233078 -0.35385,4.82115024 1.06155,6.25105354 L6.99965,12.25 L12.93845,6.25105354 C14.35385,4.82115024 14.35385,2.50233078 12.93845,1.07242748 C11.52305,-0.357475826 9.22775,-0.357475826 7.81235,1.07242748 Z"
            ></path>
          </g>
        );
      case 'menu-star':
        return (
          <g id="Icons/Popular" transform="translate(87.000000, 69.000000)">
            <polygon
              points="8 12 3.29771798 14.472136 4.19577393 9.23606798 0.39154787 5.52786405 5.64885899 4.76393202 8 0 10.351141 4.76393202 15.6084521 5.52786405 11.8042261 9.23606798 12.702282 14.472136"
            ></polygon>
          </g>
        );
      case 'menu-time':
        return (
          <g id="nav">
            <path
              d="M94.8947368,34.0759402 C98.7607301,34.0759402 101.894737,30.9249337 101.894737,27.0379701 C101.894737,23.1510065 98.7607301,20 94.8947368,20 C91.0287436,20 87.8947368,23.1510065 87.8947368,27.0379701 C87.8947368,30.9249337 91.0287436,34.0759402 94.8947368,34.0759402 Z M97.3698202,30.3552 L93.6697368,27.5658179 L94.3114035,21.7594925 L95.4780702,21.7594925 L96.0135702,26.601616 L98.1946535,29.5258925 L97.3698202,30.3552 L97.3698202,30.3552 Z"
              id="Icons/Time"
            ></path>
          </g>
        );
      case 'menu-upload':
        return (
          <g id="Icons/submit">
            <path
              d="M2,14.004 C2,14.002 14,14 14,14 C13.996,14 14,8.996 14,8.996 C14,8.444 14.448,7.996 15,7.996 C15.552,7.996 16,8.444 16,8.996 L16,14.004 C16,15.1 15.1,16 14,16 L2,16 C0.894,16 0,15.108 0,14.004 L0,8.996 C0,8.444 0.448,7.996 1,7.996 C1.552,7.996 2,8.444 2,8.996 L2,14.004 L2,14.004 Z M4.706,5.732 C4.316,6.128 3.683,6.128 3.292,5.732 C2.902,5.336 2.902,4.694 3.292,4.299 L6.94,0.444 C7.222,0.158 7.597,0 8,0 C8.4,0 8.778,0.158 9.06,0.445 L12.707,4.3 C13.097,4.694 13.097,5.336 12.707,5.732 C12.317,6.128 11.684,6.128 11.293,5.732 L9,3.252 L9,9.004 C9,9.556 8.553,10.004 8,10.004 C7.45,10.004 7,9.556 7,9.004 L7,3.25 L4.707,5.73 L4.706,5.732 Z"
              id="Shape"
            ></path>
          </g>
        );
      case 'menu-video':
        return (
          <g id="Icons/video">
            <path
              d="M2,13.9982447 L12,8.00024466 L2,2.00224466 L2,13.9982447 L2,13.9982447 Z M0,13.9982447 L0,2.00224466 C0,0.448244661 1.696,-0.511755339 3.03,0.288244661 L13.03,6.28624466 C14.324,7.06224466 14.324,8.93824466 13.03,9.71424466 L3.03,15.7122447 C1.696,16.5122447 1.33226763e-15,15.5522447 1.33226763e-15,13.9982447 L0,13.9982447 Z"
              id="Shape"
            ></path>
          </g>
        );
      case 'menu':
        return (
          <g>
            <path d="M3,15h18v-2H3V15z M3,19h18v-2H3V19z M3,11h18V9H3V11z M3,5v2h18V5H3z"></path>
          </g>
        );
      case 'more':
        return (
          <g>
            <path
              d="M21 3.01h-18c-1.1 0-2 .9-2 2v3.99h2v-4.01h18v14.03h-18v-4.02h-2v4.01c0 1.1.9 1.98 2 1.98h18c1.1 0 2-.88 2-1.98v-14c0-1.11-.9-2-2-2zm-10 12.99l4-4-4-4v3h-10v2h10v3z"
            ></path>
          </g>
        );
      case 'next':
        return (
          <g id="Icons/right">
            <path
              d="M12.753,5.9995 L10.273,8.2935 C9.877,8.6835 9.877,9.3165 10.273,9.7075 C10.668,10.0975 11.31,10.0975 11.705,9.7075 L15.555,6.0605 C15.843,5.7775 16,5.4025 16,4.9995 C16,4.5995 15.842,4.2215 15.556,3.9395 L11.706,0.2925 C11.31,-0.0975 10.668,-0.0975 10.272,0.2925 C9.877,0.6825 9.877,1.3155 10.272,1.7065 L12.752,3.9995 L1,3.9995 C0.447,3.9995 0,4.4455 0,4.9995 C0,5.5495 0.448,5.9995 1,5.9995 L12.753,5.9995 Z"
              id="Shape"
            ></path>
          </g>
        );
      case 'play':
        return (
          <g id="play-icon">
            <circle id="Oval" fillOpacity="0.449999988" cx="45" cy="45" r="45"></circle>
            <path
              d="M32.6666667,64.6608155 L66,44.6674822 L32.6666667,24.6741489 L32.6666667,64.6608155 L32.6666667,64.6608155 Z M26,64.6608155 L26,24.6741489 C26,19.4941489 31.6533333,16.2941489 36.1,18.9608155 L69.4333333,38.9541489 C73.7466667,41.5408155 73.7466667,47.7941489 69.4333333,50.3808155 L36.1,70.3741489 C31.6533333,73.0408155 26,69.8408155 26,64.6608155 L26,64.6608155 Z"
              id="Shape" fillOpacity="0.85" fill="#FFFFFF"
            ></path>
          </g>
        );
      case 'pre':
        return (
          <g id="Icons/left">
            <path
              d="M3.247,6 L5.727,8.294 C6.123,8.684 6.123,9.317 5.727,9.708 C5.332,10.098 4.69,10.098 4.295,9.708 L0.445,6.06 C0.157,5.778 0,5.403 0,5 C0,4.6 0.158,4.222 0.444,3.94 L4.294,0.293 C4.69,-0.097 5.332,-0.097 5.728,0.293 C6.123,0.683 6.123,1.316 5.728,1.707 L3.248,4 L15,4 C15.553,4 16,4.446 16,5 C16,5.55 15.552,6 15,6 L3.246,6 L3.247,6 Z"
              id="Shape"
            ></path>
          </g>
        );
      case 'search':
        return (
          <g id="Shape" fill={this.props.color}>
            <path
              d="M14.295,15.703 C14.685,16.099 15.317,16.099 15.707,15.703 C16.097,15.308 16.097,14.669 15.707,14.273 L12.627,11.193 C13.497,10.025 14.013,8.577 14.013,7.007 C14.013,3.137 10.876,0 7.007,0 C3.137,0 0,3.137 0,7.007 C0,10.877 3.137,14.014 7.007,14.014 C8.582,14.014 10.037,13.494 11.207,12.617 L14.295,15.703 L14.295,15.703 Z M2.002,7.007 C2.002,4.243 4.242,2.002 7.007,2.002 C9.771,2.002 12.011,4.242 12.011,7.007 C12.011,9.771 9.771,12.012 7.008,12.012 C4.244,12.012 2.003,9.772 2.003,7.007 L2.002,7.007 Z"
            ></path>
          </g>
        );
      case 'contact':
        return (
          <g fill={this.props.color}>
            <path d="M8,0 C3.582,0 0,3.582 0,8 L0,13 C0,13.553 0.447,14 1,14 L2,14 L2,15 C2,15.553 2.447,16 3,16 C3.553,16 4,15.553 4,15 L4,11 C4,10.447 3.553,10 3,10 C2.447,10 2,10.447 2,11 L2,8 C2,4.687 4.687,2 8,2 C11.313,2 14,4.687 14,8 L14,11 C14,10.447 13.553,10 13,10 C12.447,10 12,10.447 12,11 L12,15 C12,15.553 12.447,16 13,16 C13.553,16 14,15.553 14,15 L14,14 L15,14 C15.553,14 16,13.553 16,13 L16,8 C16,3.582 12.418,0 8,0 Z" id="Shape"></path>
          </g>
        );

      case 'share-email':
        return (
          <g id="email">
            <circle id="Stroke" strokeOpacity="0.4" stroke="#9297A3" cx="16" cy="16" r="16"></circle>
            <path
              d="M9.51451235,15.9384799 L13.7505124,17.4887299 L15.3442624,21.7269799 C15.6022624,22.4117299 16.5780124,22.4012299 16.8202624,21.7104799 L20.2080124,12.0587299 C20.4442624,11.4294799 19.8285124,10.8159799 19.1970124,11.0514799 L9.52276235,14.4662299 C8.82976235,14.7107299 8.82451235,15.6857299 9.51451235,15.9384799"
              id="Imported-Layers" fill="#9297A3"
            ></path>
          </g>
        );

      case 'detail-view':
        return (
          <g fill={this.props.color}><path d="M4 18h17v-6h-17v6zm0-13v6h17v-6h-17z"></path></g>
        );
      case 'chat':
        return (
          <g><path d="M20 2h-16c-1.1 0-1.99.9-1.99 2l-.01 18 4-4h14c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm-14 7h12v2h-12v-2zm8 5h-8v-2h8v2zm4-6h-12v-2h12v2z"></path></g>
        );
      case 'icon-theme':
        return (
          <g fill={this.props.color} ><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z"></path></g>
        );
      case 'share':
        return (
          <g fill={this.props.color}><path d="M18 16.08c-.76 0-1.44.3-1.96.77l-7.13-4.15c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7l-7.05 4.11c-.54-.5-1.25-.81-2.04-.81-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"></path></g>
        );
      case 'comment':
        return (
          <g fill={this.props.color}><path d="M20 2h-16c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4v-18c0-1.1-.9-2-2-2zm-2 12h-12v-2h12v2zm0-3h-12v-2h12v2zm0-3h-12v-2h12v2z"></path></g>
        );
      case 'bookmark':
        return (
          <g fill={this.props.color}><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2z" /></g>
        );
      case 'bookmark-o':
        return (
          <g fill={this.props.color}><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z" /></g>
        );
      case 'open':
        return (
          <g fill={this.props.color}><path d="M19 19h-14v-14h7v-2h-7c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zm-5-16v2h3.59l-9.83 9.83 1.41 1.41 9.83-9.83v3.59h2v-7h-7z"></path></g>
        );
      case 'facebook':
        return (
          <g><path d="M20 2h-16c-1.1 0-1.99.9-1.99 2l-.01 16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm-1 2v3h-2c-.55 0-1 .45-1 1v2h3v3h-3v7h-3v-7h-2v-3h2v-2.5c0-1.93 1.57-3.5 3.5-3.5h2.5z"></path></g>
        );
      case 'google':
        return (
          <g><path d="M11.2 8.87c0-1.02-.63-3.02-2.08-3.02-.64 0-1.32.44-1.32 1.67 0 1.18.63 2.93 1.97 2.93.06.01 1.43-.01 1.43-1.58zm-.63 4.94l-.31-.01h-.02c-.26 0-1.15.05-1.82.27-.65.24-1.42.72-1.42 1.7 0 1.08 1.04 2.23 2.96 2.23 1.52 0 2.44-1 2.44-1.97 0-.77-.46-1.24-1.83-2.22zm9.43-11.81h-16c-1.1 0-1.99.9-1.99 2l-.01 16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm-10.93 17.2c-2.8 0-4.07-1.56-4.07-3.01 0-.45.14-1.59 1.48-2.39.77-.47 1.85-.78 3.14-.91-.19-.25-.34-.55-.34-.99 0-.16.02-.31.05-.46h-.38c-1.97 0-3.15-1.55-3.15-3.04 0-1.73 1.29-3.6 4.11-3.6h4.21l-.34.34-.71.71-.06.06h-.71c.41.42.9 1.12.9 2.16 0 1.4-.74 2.09-1.56 2.73-.16.12-.42.38-.42.72 0 .3.24.5.39.62.13.11.3.22.47.34.81.57 1.92 1.34 1.92 2.88 0 1.77-1.29 3.84-4.93 3.84zm9.93-7.2h-2v2h-1v-2h-2v-1h2v-2h1v2h2v1z"></path></g>
        );
      case 'instagram':
        return (
          <g><path d="M1152 896q0-106-75-181t-181-75-181 75-75 181 75 181 181 75 181-75 75-181zm138 0q0 164-115 279t-279 115-279-115-115-279 115-279 279-115 279 115 115 279zm108-410q0 38-27 65t-65 27-65-27-27-65 27-65 65-27 65 27 27 65zm-502-220q-7 0-76.5-.5t-105.5 0-96.5 3-103 10-71.5 18.5q-50 20-88 58t-58 88q-11 29-18.5 71.5t-10 103-3 96.5 0 105.5.5 76.5-.5 76.5 0 105.5 3 96.5 10 103 18.5 71.5q20 50 58 88t88 58q29 11 71.5 18.5t103 10 96.5 3 105.5 0 76.5-.5 76.5.5 105.5 0 96.5-3 103-10 71.5-18.5q50-20 88-58t58-88q11-29 18.5-71.5t10-103 3-96.5 0-105.5-.5-76.5.5-76.5 0-105.5-3-96.5-10-103-18.5-71.5q-20-50-58-88t-88-58q-29-11-71.5-18.5t-103-10-96.5-3-105.5 0-76.5.5zm768 630q0 229-5 317-10 208-124 322t-322 124q-88 5-317 5t-317-5q-208-10-322-124t-124-322q-5-88-5-317t5-317q10-208 124-322t322-124q88-5 317-5t317 5q208 10 322 124t124 322q5 88 5 317z"/></g>
        );
      case 'twitter':
        return (
          <g><path d="M20 2h-16c-1.1 0-1.99.9-1.99 2l-.01 16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm-2.29 7.33c-.06 4.62-3.02 7.78-7.42 7.98-1.82.08-3.14-.5-4.28-1.23 1.34.21 3.01-.32 3.9-1.09-1.32-.13-2.1-.8-2.46-1.88.38.07.78.05 1.14-.03-1.19-.4-2.04-1.13-2.08-2.67.33.15.68.29 1.14.32-.9-.5-1.55-2.35-.8-3.57 1.32 1.45 2.91 2.63 5.52 2.79-.65-2.8 3.06-4.32 4.61-2.44.66-.13 1.19-.38 1.7-.65-.21.65-.62 1.1-1.12 1.47.54-.07 1.03-.21 1.44-.41-.25.53-.81 1.01-1.29 1.41z"></path></g>
        );
      case 'person':
        return (
          <g><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path></g>
        );
      case 'phone-iphone':
        return (
          <g><path d="M15.5 1h-8c-1.38 0-2.5 1.12-2.5 2.5v17c0 1.38 1.12 2.5 2.5 2.5h8c1.38 0 2.5-1.12 2.5-2.5v-17c0-1.38-1.12-2.5-2.5-2.5zm-4 21c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm4.5-4h-9v-14h9v14z"></path></g>
        );
      case 'phone-android':
        return (
          <g><path d="M16 1h-8c-1.66 0-3 1.34-3 3v16c0 1.66 1.34 3 3 3h8c1.66 0 3-1.34 3-3v-16c0-1.66-1.34-3-3-3zm-2 20h-4v-1h4v1zm3.25-3h-10.5v-14h10.5v14z"></path></g>
        );
      case 'laptop-mac':
        return (
          <g><path d="M20 18c1.1 0 1.99-.9 1.99-2l.01-11c0-1.1-.9-2-2-2h-16c-1.1 0-2 .9-2 2v11c0 1.1.9 2 2 2h-4c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2h-4zm-16-13h16v11h-16v-11zm8 14c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"></path></g>
        );
      case 'laptop-windows':
        return (
          <g><path d="M20 18v-1c1.1 0 1.99-.9 1.99-2l.01-10c0-1.1-.9-2-2-2h-16c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2v1h-4v2h24v-2h-4zm-16-13h16v10h-16v-10z"></path></g>
        );
      case 'menu-marker':
        return (
          <g><path d="M20 18v-1c1.1 0 1.99-.9 1.99-2l.01-10c0-1.1-.9-2-2-2h-16c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2v1h-4v2h24v-2h-4zm-16-13h16v10h-16v-10z"></path></g>
        );
      case 'fa-instagram':
        return (
          <FontAwesomeIcon size={22} style={{ fill: 'none' }} icon="faInstagramSquare" />
        );
      case 'fi-compass':
        return (
          <FiCompass size={22} style={{ fill: 'none' }} />
        );
      case 'fi-bookmark':
        return (
          <FiBookmark size={22} style={{ fill: 'none' }} />
        );
      case 'fi-award':
        return (
          <FiAward size={22} style={{ fill: 'none' }} />
        );
      case 'fi-users':
        return (
          <FiUsers size={22} style={{ fill: 'none' }} />
        );
      case 'fi-tag':
        return (
          <FiTag size={22} style={{ fill: 'none' }} />
        );
      case 'fi-shopping-cart':
        return (
          <FiShoppingCart size={22} style={{ fill: 'none' }} />
        );
      case 'fi-map-pin':
        return (
          <FiMapPin size={22} style={{ fill: 'none' }} />
        );
      case 'fi-life-buoy':
        return (
          <FiLifeBuoy size={22} style={{ fill: 'none' }} />
        );
      case 'fi-alert-triangle':
        return (
          <FiAlertTriangle size={22} style={{ fill: 'none' }} />
        );
      case 'fi-rss':
        return (
          <FiRss size={22} style={{ fill: 'none' }} />
        );
      case 'fi-headphones':
        return (
          <FiHeadphones size={22} style={{ fill: 'none' }} />
        );
      case 'fi-message-circle':
        return (
          <FiMessageCircle size={22} style={{ fill: 'none' }} />
        );
      case 'fi-message-square':
        return (
          <FiMessageSquare size={22} style={{ fill: 'none' }} />
        );
      case 'fi-inbox':
        return (
          <FiInbox size={22} style={{ fill: 'none' }} />
        );
      default:
        return (<div />);
    }
  }

  render() {
    const styles = {
      fill: 'currentcolor',
      verticalAlign: 'middle',
      width: this.props.size, // CSS instead of the width attr to support non-pixel units
      height: this.props.size, // Prevents scaling issue in IE,
    };
    const iconTheme = { boxShadow: '0 2px 8px rgba(0, 39, 36, 0.1)',
      borderRadius: '50%' };
    const viewBox = this.props.box ? this.props.box : '0 0 24 24';
    return (
      <svg
        viewBox={viewBox}
        preserveAspectRatio="xMidYMid meet"
        style={this.mergeStyles(
          styles,
          this.props.style, // This lets the parent pass custom styles
          this.props.icon === 'icon-theme' ? iconTheme : ''
        )}
      >
        {this.renderGraphic()}
      </svg>
    );
  }
}
IconSVG.defaultProps = {
  size: 22,
};
export default IconSVG;
