import { combineReducers } from 'redux';
import BrandManager from './brand.reducer';
import CigarManager from './cigar.reducer';
import GroupManager from './group.reducer';
import VenueManager from './venue.reducer';
import DrinkManager from './drink.reducer';
import ShopManager from './shop.reducer';
import CartManager from './cart.reducer';
import EventManager from './event.reducer';

const reducers = combineReducers({
  BrandManager,
  CigarManager,
  GroupManager,
  VenueManager,
  DrinkManager,
  ShopManager,
  CartManager,
  EventManager,
});

export default reducers;
